import { useEffect, useState } from "react";
import { useStoreon } from "storeon/react";
import { END_HOLIDAY_INSTANTLY } from "../store/settings";

export default function useAuth() {
  const {
    auth: { profile },
    dispatch,
  } = useStoreon("auth");
  const [holidayModalOpen, setHolidayModalOpen] = useState(false);

  const handleContinue = () => {
    setHolidayModalOpen(false);
  };

  const handleEndInstantly = async () => {
    setHolidayModalOpen(false);
    dispatch(END_HOLIDAY_INSTANTLY);
  };

  const endHolidayModalProps = {
    open: holidayModalOpen,
    onClose: handleContinue,
    onContinueHoliday: handleContinue,
    onEndInstantly: handleEndInstantly,
  };

  useEffect(() => {
    // check criteria for loaded profile since it'll be {} (truthy) by default
    if (profile?.firstName) {
      const isLoginSession = sessionStorage.getItem("isLoginSession");
      if (isLoginSession === "true") {
        if (profile?.onHoliday) setHolidayModalOpen(true);
        sessionStorage.removeItem("isLoginSession");
      }
    }
  }, [profile, profile?.onHoliday]);

  return {
    endHolidayModalProps,
    showEndHolidayModal: () => setHolidayModalOpen(true),
  };
}
