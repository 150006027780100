import axios from "axios";
import { HOME_ENDPOINT } from "../../config/endpoints";

export const GET_HOME = "GET_HOME";
export const SET_HOME = "SET_HOME";
export const HOME_SET_IS_LOADING = "HOME_SET_IS_LOADING";

const INITIAL_STATE = {
  isLoading: true,
  banner: {},
  companiesBanner: {},
  howItWorks: {},
  FAQs: {},
  newspapers: {},
};

export function home(store) {
  store.on("@init", () => ({
    home: INITIAL_STATE,
  }));

  store.on(HOME_SET_IS_LOADING, ({ home }, isLoading) => {
    return {
      home: {
        ...home,
        isLoading,
      },
    };
  });

  store.on(GET_HOME, async function () {
    try {
      store.dispatch(HOME_SET_IS_LOADING, true);

      const { data } = await axios(`${HOME_ENDPOINT}`);

      store.dispatch(SET_HOME, data);
    } catch (err) {
      // firefox aborting requests while navigating causes this navigation error which is to be handled silently
      if (err.code === "ECONNABORTED") {
        console.log("silent navigation error", err);
      }
    } finally {
      store.dispatch(HOME_SET_IS_LOADING, false);
    }
  });

  store.on(
    SET_HOME,
    (
      { home },
      {
        banner,
        companies_banner: companiesBanner,
        how_it_works_section: howItWorks,
        FAQs_section: FAQs,
        newspapers_section: newspapers,
      }
    ) => {
      return {
        home: {
          ...home,
          banner,
          companiesBanner,
          howItWorks,
          FAQs,
          newspapers,
        },
      };
    }
  );
}
