import "firebase/messaging";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useStoreon } from "storeon/react";
import NewUpdateMessage from "../../components/new-update-message/new-update-message";
import { HOME_ROUTE } from "../../config/routes";

const NewUpdate = () => {
  const history = useHistory();
  const [newVersionAvailable, setNewVersionAvailable] = useState(false);
  const {
    firebase: { isInitialized },
  } = useStoreon("firebase");

  useEffect(() => {
    if (isInitialized) {
      // listener when new messages arrives
      navigator.serviceWorker.addEventListener("message", async (message) => {
        if (
          message.data.data &&
          message.data.data.type === `new_update-${process.env.STAGE}`
        ) {
          setTimeout(() => {
            setNewVersionAvailable(true);
            localStorage.clear();
            history.push(HOME_ROUTE);
          }, 120000);
        }
      });
    }
  }, [isInitialized]);

  const updateServiceWorker = (reload) => {
    setNewVersionAvailable(reload);
    if (reload) {
      window.location.reload(true);
    }
  };

  return (
    <>
      {newVersionAvailable && (
        <NewUpdateMessage onUpdate={updateServiceWorker} />
      )}
    </>
  );
};

export default NewUpdate;
