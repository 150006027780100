import {
  CASE_STUDIES_ENDPOINT,
  CASE_STUDY_ENDPOINT,
  PROJECT_ENDPOINT,
} from "../../config/endpoints";
import { TOAST, TOAST_ERROR } from "../../../core/store/ui";
import { stringToSlug } from "../utils/functions";
import {
  GetRequestUnauthenticated,
  GetRequest,
} from "../../../core/api/api-request";

export const GET_CASE_STUDIES = "GET_CASE_STUDIES";
export const SET_CASE_STUDIES_LOADING = "SET_CASE_STUDIES_LOADING";
export const SET_CASE_STUDIES = "SET_CASE_STUDIES";
export const SET_IS_LIST_COMPLETED = "SET_IS_LIST_COMPLETED";
export const SET_SELECTED_CASE = "SET_SELECTED_CASE";
export const SET_CASE_STUDIES_CONFIG = "SET_CASE_STUDIES_CONFIG";
export const SET_CASE_STUDIES_CAROUSEL = "SET_CASE_STUDIES_CAROUSEL";
export const RESET_CASE_STUDIES = "RESET_CASE_STUDIES";

const INITIAL_STATE = {
  config: null,
  loading: true,
  list: [],
  isListCompleted: false,
  selected: null,
  perPage: 12,
  carousel: [],
};

export function caseStudies(store) {
  store.on("@init", () => ({
    caseStudies: INITIAL_STATE,
  }));

  /* eslint-disable */
  store.on(GET_CASE_STUDIES, async function ({ caseStudies, auth }) {
    const mapCaseStudies = (caseStudies) => {
      return caseStudies.map(
        ({ case_study_body, case_study_company, ...rest }) => ({
          ...rest,
          ...case_study_body,
          ...case_study_company,
          slug: stringToSlug(case_study_body.title),
        })
      );
    };
    const groupByCollection = (response) => {
      const { case_study_section, carousel, ...mainConfig } = response;
      const { case_studies, ...caseStudiesConfig } = case_study_section;
      const newCaseStudies = mapCaseStudies(case_studies);
      return {
        config: { ...mainConfig, ...caseStudiesConfig },
        caseStudies: newCaseStudies,
        carousel,
      };
    };

    store.dispatch(SET_CASE_STUDIES_LOADING, true);
    try {
      let newData = [];
      const { list, perPage } = caseStudies;

      const page = Math.ceil(list.length / perPage) + 1;

      const { data, headers } = await GetRequestUnauthenticated(
        `${CASE_STUDIES_ENDPOINT}/?_caseStudyLimit=${perPage}&_caseStudyStart=${page}`
      );

      const count = headers["x-total-count"];
      if (Array.isArray(data)) {
        newData = list.concat(mapCaseStudies(data));
      } else {
        const { config, caseStudies, carousel } = groupByCollection(data);
        newData = list.concat([...caseStudies]);
        store.dispatch(SET_CASE_STUDIES_CONFIG, config);

        const panels = [];
        const forLoop = async () => {
          for (let index = 0; index < carousel.length; index++) {
            const element = carousel[index];

            if (
              element.__component === "case-study-section.case-study-carousel"
            ) {
              try {
                const project = await GetRequestUnauthenticated(
                  `${CASE_STUDY_ENDPOINT}/${element.case_study.id}`
                );
                panels.push({ ...element, ...project.data });
              } catch (error) {
                continue;
              }
            } else if (element.__component === "discover-section.profile") {
              try {
                if (!auth.accessToken) continue;
                const project = await GetRequest(
                  `${PROJECT_ENDPOINT}/${element.project_id}`,
                  auth.accessToken
                );

                panels.push({ ...element, ...project.data });
              } catch (error) {
                continue;
              }
            } else {
              panels.push(element);
            }
          }
        };
        await forLoop();

        store.dispatch(SET_CASE_STUDIES_CAROUSEL, panels);
      }

      store.dispatch(SET_IS_LIST_COMPLETED, newData.length === +count);
      store.dispatch(SET_CASE_STUDIES, newData);
    } catch (err) {
      store.dispatch(SET_IS_LIST_COMPLETED, true);
      store.dispatch(TOAST, {
        type: TOAST_ERROR,
        message: "Failed to load case studies",
      });
    } finally {
      store.dispatch(SET_CASE_STUDIES_LOADING, false);
    }
  });

  store.on(SET_CASE_STUDIES_CONFIG, ({ caseStudies }, config) => {
    return {
      caseStudies: {
        ...caseStudies,
        config,
      },
    };
  });

  store.on(SET_CASE_STUDIES_CAROUSEL, ({ caseStudies }, carousel) => {
    return {
      caseStudies: {
        ...caseStudies,
        carousel,
      },
    };
  });

  store.on(SET_CASE_STUDIES, ({ caseStudies }, list) => {
    return {
      caseStudies: {
        ...caseStudies,
        list,
      },
    };
  });

  store.on(SET_CASE_STUDIES_LOADING, ({ caseStudies }, loading) => {
    return {
      caseStudies: {
        ...caseStudies,
        loading,
      },
    };
  });

  store.on(SET_IS_LIST_COMPLETED, ({ caseStudies }, isListCompleted) => {
    return {
      caseStudies: {
        ...caseStudies,
        isListCompleted,
      },
    };
  });

  store.on(RESET_CASE_STUDIES, ({ caseStudies }) => {
    return {
      caseStudies: {
        ...caseStudies,
        list: [],
        isListCompleted: false,
        loading: false,
      }
    };
  });
}
