import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";

export const TOAST = "TOAST";
export const TOAST_ERROR = "error";
export const TOAST_SUCCESS = "success";
export const TOAST_WARN = "warn";
export const TOAST_INFO = "info";

export function ui(store) {
  store.on("@init", () => ({}));

  store.on(TOAST, ({ auth }, { type, message, error }) => {
    if (type === TOAST_ERROR) {
      const captureException = !(message && message.response);

      if (captureException) {
        Sentry.setContext("profile", { email: auth.profile.email });
        Sentry.captureException(error);
      }

      if (
        message &&
        message.response &&
        message.response.data &&
        message.response.data.message
      ) {
        message = message.response.data.message.toString();
      } else {
        message = message.toString();
      }
    }
    toast[type](message);
  });
}
