import { GetRequest } from "../api/api-request";
import { DASHBOARD_ENDPOINT } from "../../config/endpoints";
import { TOAST, TOAST_ERROR } from "./ui";

export const DASHBOARD_LOAD_DATA = "DASHBOARD_LOAD_DATA";
export const DASHBOARD_SET_DATA = "DASHBOARD_SET_DATA";

export function dashboard(store) {
  store.on("@init", () => ({
    dashboard: {
      data: {
        acceptedPercent: 0,
        acceptedPercentOnPast: 0,
        callsAccepted: 0,
        callsAcceptedOnPast: 0,
        callsPending: 0,
        callsRejected: 0,
        callsRejectedOnPast: 0,
        partnerships: 0,
        rejectedPercent: 0,
        rejectedPercentOnPast: 0,
        requestsCompleted: 0,
        requestsReceived: 0,
        requestsSent: 0,
        responseAve: 0,
        userData: [],
      },
    },
  }));

  store.on(DASHBOARD_LOAD_DATA, async ({ auth }, monthActive) => {
    try {
      const result = await GetRequest(
        `${DASHBOARD_ENDPOINT}/data?weekly=${!monthActive}`,
        auth.accessToken
      );

      store.dispatch(DASHBOARD_SET_DATA, result.data);
    } catch (e) {
      store.dispatch(TOAST, {
        type: TOAST_ERROR,
        message: e,
        error: e,
      });
    }
  });

  store.on(DASHBOARD_SET_DATA, ({ dashboard }, data) => {
    return {
      dashboard: {
        ...dashboard,
        data: { ...data, userData: data.userData },
      },
    };
  });
}
