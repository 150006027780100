import heart from "../../assets/img/heart.svg";
import heartFilled from "../../assets/img/heart-filled.svg";
import React, { useEffect, useState } from "react";
import { useStoreon } from "storeon/react";
import {
  FAVORITE_ADD_LIKE,
  FAVORITE_REMOVE_LIKE,
} from "../../core/store/favorite";
import "./like-button.scss";
import cx from "classnames";

const TITLE = "Like";

export const LikeButton = ({ itemID, label, isShowTitle, elementType }) => {
  const {
    auth: { isAuthenticated },
    favorite: { likes, loading, inProcessId },
    dispatch,
  } = useStoreon("auth", "favorite");
  const [likeID, setLikeID] = useState(0);
  const [tempLiked, setTempLiked] = useState(false);

  useEffect(() => {
    if (likes && itemID) {
      let favorite = likes.find((like) => +like.elementId === itemID);
      if (favorite) {
        setLikeState(favorite.id);
      } else {
        resetLikeState();
      }
    }
  }, [likes.length, itemID, tempLiked]);

  function getIcon() {
    return !!likeID || tempLiked ? heartFilled : heart;
  }

  function resetLikeState() {
    setLikeID(0);
    setTempLiked(false);
  }

  function setLikeState(ID) {
    setLikeID(ID || likeID);
    setTempLiked(false);
  }

  function handleClick() {
    if (!loading) toggleLike();
  }

  function isSavingState() {
    return !!(
      itemID !== null &&
      inProcessId !== null &&
      +itemID === +inProcessId
    );
  }

  async function saveLike() {
    if (itemID && elementType) {
      setTempLiked(true);
      dispatch(FAVORITE_ADD_LIKE, {
        itemID: itemID.toString(),
        elementType,
      });
    }
  }

  async function removeLike() {
    if (likeID) {
      resetLikeState();
      dispatch(FAVORITE_REMOVE_LIKE, likeID);
    }
  }

  function toggleLike() {
    if (!likeID) saveLike();
    else removeLike();
  }

  return (
    <>
      {isAuthenticated && (
        <button
          className={`share-button-icon ${isShowTitle ? "has-title" : ""}`}
          onClick={handleClick}
        >
          {/* ICON */}
          <img
            className={cx("share-button-icon_icon", {
              "saving-status": isSavingState(),
            })}
            src={getIcon()}
            alt="Share this item"
            title={label || TITLE}
          />
          <p>{label || TITLE}</p>
        </button>
      )}
    </>
  );
};
