import React from "react";
import "./new-update-message.scss";
import findrIcon from "../../assets/img/Findr tip.png";

export default function NewUpdateMessage({ onUpdate }) {
  return (
    <div className="MessageContainer">
      <img className="logo" src={findrIcon} alt="Findr icon" />
      <span className="MessageBody">
        A new version of
        <span className="orange-span"> Findr </span>
        is available!
      </span>
      <span className="MessageBodyMobile">
        A new version of
        <span className="orange-span"> Findr </span>
        is available! Reload your web app to see the latest juicy changes.
      </span>
      <button className="Refresh" onClick={() => onUpdate(true)}>
        REFRESH
      </button>
      <div className="MobileActions">
        <button className="RefreshMobile" onClick={() => onUpdate(true)}>
          UPDATE
        </button>
      </div>
    </div>
  );
}
