import {
  GetRequest,
  PatchRequest,
  PostRequest,
  PutRequest,
} from "../api/api-request";
import { PROJECT_ENDPOINT, BUCKET_DOC_ENDPOINT } from "../../config/endpoints";
import { TOAST, TOAST_ERROR } from "./ui";
import { generateProjectFormFields } from "../utils/requestFixers";

export const ONBOARDING_NEXT_STEP = "ONBOARDING_NEXT_STEP";
export const ONBOARDING_PREV_STEP = "ONBOARDING_PREV_STEP";
export const SET_COMPANY_CREATE_FORM_STATE = "SET_COMPANY_CREATE_FORM_STATE";
export const SET_ONBOARDING_INITIAL_STATE = "SET_ONBOARDING_INITIAL_STATE";
export const ONBOARDING_LOAD = "ONBOARDING_LOAD";
export const ONBOARDING_SET_STEP = "ONBOARDING_SET_STEP";
export const ONBOARDING_UPLOAD_FILE = "ONBOARDING_UPLOAD_FILE";
export const ONBOARDING_SET_DOCSSTATE = "ONBOARDING_SET_DOCSSTATE";
export const ONBOARDING_SET_DOC = "ONBOARDING_SET_DOC";
export const ONBOARDING_SET_DOCSPROGRESS = "ONBOARDING_SET_DOCSPROGRESS";

export const FIELDS = [
  ["objective"],
  ["businessIndustry"],
  ["partnerType"],
  ["solutionType", "stage", "revenue", "size", "markets", "countries"],
  [
    "partnerStage",
    "solution",
    "partnerSize",
    "partnerMarkets",
    "partnerCountries",
    "timing",
    "exclusion",
    "partnerRevenue",
    "budget",
  ],
  ["projectOutcome", "description"],
  [],
];

export const REQUIRED_FIELDS = [
  ["objective"],
  ["businessIndustry"],
  ["partnerType"],
  ["solutionType", "stage", "revenue", "size", "markets"],
  [
    "solution",
    "partnerStage",
    "partnerSize",
    "partnerMarkets",
    "timing",
    "partnerRevenue",
    "budget",
  ],
  ["projectOutcome", "description"],
  [],
];

const calculateStep = (form) => {
  let step = 1;

  REQUIRED_FIELDS.forEach((arr, i) => {
    if (arr.some((field) => form[field])) {
      step = i + 1;
    }
  });

  return step;
};

const INITIAL_STATE = {
  step: 1,
  companyCreate: {
    objective: "",
    businessIndustry: "",
    partnerType: "",
    solutionType: "",
    stage: "",
    revenue: "",
    size: "",
    markets: "",
    countries: "",
    partnerStage: "",
    solution: "",
    partnerSize: "",
    partnerMarkets: "",
    partnerCountries: "",
    timing: "",
    exclusion: "",
    partnerRevenue: "",
    budget: "",
    projectOutcome: "",
    description: "",
    docs: [],
  },
  docsState: [
    { state: "empty", message: "" },
    { state: "empty", message: "" },
    { state: "empty", message: "" },
  ],
  docs: ["", "", ""],
  docsProgress: [0, 0, 0],
  docsStateMessage: ["", "", ""],
};

export function onboarding(store) {
  store.on("@init", () => {
    return {
      onboarding: INITIAL_STATE,
    };
  });

  store.on(ONBOARDING_LOAD, async ({ auth }) => {
    let result = {};
    let step = 1;

    try {
      const response = await GetRequest(
        `${PROJECT_ENDPOINT}/${auth.profile.onBoarding.projectId}`,
        auth.accessToken
      );
      result = response.data;
      step = (result && calculateStep(result)) || 1;
    } catch (e) {
      store.dispatch(TOAST, { type: TOAST_ERROR, message: e, error: e });
    }

    store.dispatch(SET_COMPANY_CREATE_FORM_STATE, result);
    store.dispatch(ONBOARDING_SET_STEP, step);
  });

  store.on(ONBOARDING_SET_STEP, ({ onboarding }, step) => {
    return {
      onboarding: {
        ...onboarding,
        step,
      },
    };
  });

  store.on(ONBOARDING_NEXT_STEP, async ({ auth, onboarding }) => {
    try {
      const project = generateProjectFormFields(onboarding.companyCreate);
      await PatchRequest(
        `${PROJECT_ENDPOINT}/${auth.profile.onBoarding.projectId}`,
        project,
        auth.accessToken
      );
      store.dispatch(ONBOARDING_SET_STEP, onboarding.step + 1);
    } catch (e) {
      store.dispatch(TOAST, { type: TOAST_ERROR, message: e, error: e });
    }
  });

  store.on(ONBOARDING_PREV_STEP, ({ onboarding }) => {
    return {
      onboarding: {
        ...onboarding,
        step: onboarding.step - 1,
      },
    };
  });

  store.on(SET_COMPANY_CREATE_FORM_STATE, ({ onboarding }, companyCreate) => {
    return {
      onboarding: {
        ...onboarding,
        companyCreate,
      },
    };
  });

  store.on(SET_ONBOARDING_INITIAL_STATE, () => {
    return {
      onboarding: INITIAL_STATE,
    };
  });

  store.on(
    ONBOARDING_SET_DOCSSTATE,
    ({ onboarding }, { state, indexDocument }) => {
      let docsState = [...onboarding.docsState];
      docsState[indexDocument] = { ...state };

      return {
        onboarding: {
          ...onboarding,
          docsState: [...docsState],
        },
      };
    }
  );

  store.on(ONBOARDING_SET_DOC, ({ onboarding }, { doc, indexDocument }) => {
    let docs = [...onboarding.docs];
    docs[indexDocument] = doc;

    return {
      onboarding: {
        ...onboarding,
        docs: [...docs],
      },
    };
  });

  store.on(
    ONBOARDING_SET_DOCSPROGRESS,
    ({ onboarding }, { progress, indexDocument }) => {
      let docsProgress = [...onboarding.docsProgress];
      docsProgress[indexDocument] = progress;

      return {
        onboarding: {
          ...onboarding,
          docsProgress: [...docsProgress],
        },
      };
    }
  );

  store.on(
    ONBOARDING_UPLOAD_FILE,
    async ({ auth: { accessToken } }, { document: { fileData, ...data } }) => {
      // to be send to the server
      let getFileUrl;

      // upload files
      try {
        if (fileData) {
          const { file, ...requestBody } = fileData;
          console.log({ fileData });
          const response = await PostRequest(
            `${BUCKET_DOC_ENDPOINT}`,
            requestBody,
            accessToken
          );

          store.dispatch(ONBOARDING_SET_DOCSSTATE, {
            state: { state: "uploading", message: "Uploading" },
            indexDocument: data.indexDocument,
          });

          const headers = {
            "Content-Type": fileData.contentType,
          };

          await PutRequest(response.data.put, file, {
            headers,
            onUploadProgress: function (progressEvent) {
              let progress = (progressEvent.loaded / progressEvent.total) * 100;
              store.dispatch(ONBOARDING_SET_DOCSPROGRESS, {
                progress: progress,
                indexDocument: data.indexDocument,
              });
            },
          });

          // update variables
          getFileUrl = response.data.get;

          store.dispatch(ONBOARDING_SET_DOCSSTATE, {
            state: { state: "success", message: "Success" },
            indexDocument: data.indexDocument,
          });

          store.dispatch(ONBOARDING_SET_DOC, {
            doc: getFileUrl,
            indexDocument: data.indexDocument,
          });
        }
      } catch (e) {
        console.log("Error uploading file. Please try again", e);
        if (e.response) {
          store.dispatch(ONBOARDING_SET_DOCSSTATE, {
            state: { state: "error", message: "Error uploading file." },
            indexDocument: data.indexDocument,
          });
        } else {
          store.dispatch(ONBOARDING_SET_DOCSSTATE, {
            state: { state: "error", message: "Connection Error" },
            indexDocument: data.indexDocument,
          });
        }
      }
    }
  );
}
