import { GetRequest, PatchRequest, PostRequest } from "../api/api-request";
import {
  BOOKED_CALLS_ENDPOINT,
  NOTIFICATIONS_ENDPOINT,
  NOTIFICATIONS_TOKEN_ENDPOINT,
  NOTIFICATIONS_TOKEN_UNAUTHENTICATED_ENDPOINT,
  REQUEST_ENDPOINT,
  REQUESTS_ENDPOINT,
  SHARE_CONTACT_ACCEPT_ENDPOINT,
  SHARE_CONTACT_DECLINE_ENDPOINT,
  UNREAD_NOTIFICATIONS_ENDPOINT,
} from "../../config/endpoints";
import { TOAST, TOAST_ERROR, TOAST_INFO } from "./ui";
import textData from "../../content/my-findr.yml";
import { NOTIFICATION_ENUM, REQUEST_ENUM } from "../../config/enums";

export const REQUESTS_TYPES = {
  SENT: "SENT",
  RECEIVED: "RECEIVED",
  COMPLETED: "COMPLETED",
};
export const GET_REQUESTS = "GET_REQUESTS";
export const LOAD_REQUESTS = "LOAD_REQUESTS";
export const SET_REQUESTS = "SET_REQUESTS";
export const REJECT_REQUEST = "REJECT_REQUEST";
export const SAVE_NOTIFICATIONS_TOKEN = "SAVE_NOTIFICATIONS_TOKEN";
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_UNREAD_NOTIFICATIONS = "GET_UNREAD_NOTIFICATIONS";
export const SET_UNREAD_NOTIFICATIONS = "SET_UNREAD_NOTIFICATIONS";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const ADD_NOTIFICATIONS = "ADD_NOTIFICATIONS";
export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";
export const SET_NOTIFICATION_READ = "SET_NOTIFICATION_READ";
export const GET_BOOKED_CALLS = "GET_BOOKED_CALLS";
export const LOADING_BOOKED_CALLS = "LOADING_BOOKED_CALLS";
export const SET_BOOKED_CALLS = "SET_BOOKED_CALLS";
export const SET_LOAD_MORE_REQUESTS = "SET_LOAD_MORE_REQUESTS";
export const CANCEL_REQUEST = "CANCEL_REQUEST";
export const SHARE_CONTACT_ACCEPT = "SHARE_CONTACT_ACCEPT";
export const SHARE_CONTACT_DECLINE = "SHARE_CONTACT_DECLINE";
export const MY_FINDR_SET_LOADING_NOTIFICATIONS =
  "MY_FINDR_SET_LOADING_NOTIFICATIONS";
export const DISMISS_NOTIFICATION = "DISMISS_NOTIFICATION";
export const REFRESH_AFTER_DISMISS = "REFRESH_AFTER_DISMISS";

const INITIAL_STATE = {
  bookedCalls: {
    loading: false,
    data: [],
  },
  notifications: {
    loading: false,
    toRead: 0,
    data: [],
  },
  requestsSent: {
    loading: false,
    hasMore: null,
    data: [],
  },
  requestsReceived: {
    loading: false,
    hasMore: null,
    data: [],
  },
  requestsCompleted: {
    loading: false,
    hasMore: null,
    data: [],
  },
};

export function myFindr(store) {
  store.on("@init", () => {
    return {
      myFindr: INITIAL_STATE,
    };
  });

  store.on(
    GET_REQUESTS,
    async ({ auth, myFindr }, { requestType, loadMore = false }) => {
      let after = "";
      if (requestType === REQUESTS_TYPES.SENT) {
        after = myFindr.requestsSent.hasMore
          ? `&after=${encodeURI(myFindr.requestsSent.hasMore)}`
          : "";
      } else if (requestType === REQUESTS_TYPES.RECEIVED) {
        after = myFindr.requestsReceived.hasMore
          ? `&after=${encodeURI(myFindr.requestsReceived.hasMore)}`
          : "";
      } else if (requestType === REQUESTS_TYPES.COMPLETED) {
        after = myFindr.requestsCompleted.hasMore
          ? `&after=${encodeURI(myFindr.requestsCompleted.hasMore)}`
          : "";
      }

      try {
        // set loading state to true
        store.dispatch(LOAD_REQUESTS, {
          state: true,
          type: requestType,
        });

        const response = await GetRequest(
          `${REQUESTS_ENDPOINT}?type=${requestType}&limit=3${
            loadMore ? after : ""
          }`,
          auth.accessToken
        );

        // set data into store
        store.dispatch(SET_REQUESTS, {
          data: response.data.data,
          type: requestType,
          loadMore,
        });

        store.dispatch(SET_LOAD_MORE_REQUESTS, {
          type: requestType,
          hasMore: response.data.lastKey === "" ? null : response.data.lastKey,
        });

        // set loading state to false
        store.dispatch(LOAD_REQUESTS, {
          state: false,
          type: requestType,
        });
      } catch (e) {
        store.dispatch(TOAST, {
          type: "error",
          message: textData.summary.tabs.sent.errors.loadingSent,
        });
        // set loading state to false
        store.dispatch(LOAD_REQUESTS, {
          state: false,
          type: requestType,
        });
      }
    }
  );

  store.on(SET_LOAD_MORE_REQUESTS, ({ myFindr }, { type, hasMore }) => {
    if (type === REQUESTS_TYPES.SENT) {
      return {
        myFindr: {
          ...myFindr,
          requestsSent: {
            ...myFindr.requestsSent,
            hasMore,
          },
        },
      };
    }

    if (type === REQUESTS_TYPES.RECEIVED) {
      return {
        myFindr: {
          ...myFindr,
          requestsReceived: {
            ...myFindr.requestsReceived,
            hasMore,
          },
        },
      };
    }

    return {
      myFindr: {
        ...myFindr,
        requestsCompleted: {
          ...myFindr.requestsCompleted,
          hasMore,
        },
      },
    };
  });

  store.on(REJECT_REQUEST, async ({ auth: { accessToken } }, { requestId }) => {
    try {
      await PatchRequest(
        `${REQUEST_ENDPOINT}/${requestId}/status`,
        {
          status: REQUEST_ENUM.REJECTED,
          discoveryTimes: [],
        },
        accessToken
      );

      store.dispatch();
    } catch (e) {
      store.dispatch(TOAST, {
        type: TOAST_ERROR,
        message: e,
        error: e,
      });
    }
  });

  store.on(LOAD_REQUESTS, ({ myFindr }, { state, type }) => {
    if (type === REQUESTS_TYPES.SENT) {
      return {
        myFindr: {
          ...myFindr,
          requestsSent: {
            ...myFindr.requestsSent,
            loading: state,
          },
        },
      };
    }

    if (type === REQUESTS_TYPES.RECEIVED) {
      return {
        myFindr: {
          ...myFindr,
          requestsReceived: {
            ...myFindr.requestsReceived,
            loading: state,
          },
        },
      };
    }

    // Completed case
    return {
      myFindr: {
        ...myFindr,
        requestsCompleted: {
          ...myFindr.requestsCompleted,
          loading: state,
        },
      },
    };
  });

  store.on(MY_FINDR_SET_LOADING_NOTIFICATIONS, ({ myFindr }, { state }) => {
    return {
      myFindr: {
        ...myFindr,
        notifications: {
          ...myFindr.notifications,
          loading: state,
        },
      },
    };
  });

  store.on(SET_REQUESTS, ({ myFindr }, { data, type, loadMore }) => {
    if (type === REQUESTS_TYPES.SENT) {
      return {
        myFindr: {
          ...myFindr,
          requestsSent: {
            ...myFindr.requestsSent,
            data: loadMore ? [...myFindr.requestsSent.data, ...data] : data,
          },
        },
      };
    }

    if (type === REQUESTS_TYPES.RECEIVED) {
      return {
        myFindr: {
          ...myFindr,
          requestsReceived: {
            ...myFindr.requestsReceived,
            data: loadMore ? [...myFindr.requestsReceived.data, ...data] : data,
          },
        },
      };
    }

    // completed case
    return {
      myFindr: {
        ...myFindr,
        requestsCompleted: {
          ...myFindr.requestsCompleted,
          data: loadMore ? [...myFindr.requestsCompleted.data, ...data] : data,
        },
      },
    };
  });

  store.on(SAVE_NOTIFICATIONS_TOKEN, async ({ auth }, token) => {
    // Send the Instance ID token your application server, so that it can:
    // - send messages back to this app
    const isTokenSaved =
      window.localStorage.getItem("findrTokenNotification") === "1";
    const isTokenUnauthenticatedSaved =
      window.localStorage.getItem("findrUnauthenticatedTokenNotification") ===
      "1";

    try {
      if (auth.isAuthenticated) {
        !isTokenSaved &&
          (await PostRequest(
            `${NOTIFICATIONS_TOKEN_ENDPOINT}`,
            { device: token },
            auth.accessToken
          ));

        return window.localStorage.setItem("findrTokenNotification", 1);
      } else {
        !isTokenUnauthenticatedSaved &&
          (await PostRequest(
            `${NOTIFICATIONS_TOKEN_UNAUTHENTICATED_ENDPOINT}`,
            {
              device: token,
            }
          ));

        return window.localStorage.setItem(
          "findrUnauthenticatedTokenNotification",
          1
        );
      }
    } catch (e) {
      console.log(e);
    }
  });

  store.on(GET_UNREAD_NOTIFICATIONS, async ({ auth }) => {
    try {
      const response = await GetRequest(
        UNREAD_NOTIFICATIONS_ENDPOINT,
        auth.accessToken
      );
      // set data into store
      store.dispatch(SET_UNREAD_NOTIFICATIONS, response.data);
    } catch (e) {
      console.log(e);
    }
  });

  store.on(SET_UNREAD_NOTIFICATIONS, ({ myFindr }, { newNotification }) => {
    return {
      myFindr: {
        ...myFindr,
        notifications: {
          ...myFindr.notifications,
          toRead: newNotification,
        },
      },
    };
  });

  store.on(GET_NOTIFICATIONS, async ({ auth }) => {
    try {
      store.dispatch(MY_FINDR_SET_LOADING_NOTIFICATIONS, {
        state: true,
      });

      if (!auth.disabled) {
        const response = await GetRequest(
          `${NOTIFICATIONS_ENDPOINT}`,
          auth.accessToken
        );
        // set data into store
        store.dispatch(SET_NOTIFICATIONS, response.data);
      }
    } catch (e) {
      store.dispatch(TOAST, {
        type: "error",
        message: `${textData.notifications.errors.loading}`,
      });
    } finally {
      store.dispatch(MY_FINDR_SET_LOADING_NOTIFICATIONS, {
        state: false,
      });
    }
  });

  store.on(CLEAR_NOTIFICATIONS, ({ myFindr }) => {
    return {
      myFindr: {
        ...myFindr,
        notifications: {
          ...myFindr.notifications,
          data: [],
        },
      },
    };
  });

  store.on(ADD_NOTIFICATIONS, ({ myFindr }, { data }) => {
    const toAdd = [];
    data.forEach((notification) => {
      if (!myFindr.notifications.data.some((n) => n.id === notification.id)) {
        toAdd.push(notification);
      }
    });
    return {
      myFindr: {
        ...myFindr,
        notifications: {
          ...myFindr.notifications,
          data: [...toAdd, ...myFindr.notifications.data],
        },
      },
    };
  });

  store.on(SET_NOTIFICATIONS, ({ myFindr }, { data, newNotification }) => {
    return {
      myFindr: {
        ...myFindr,
        notifications: {
          ...myFindr.notifications,
          data: [...data],
          toRead: newNotification,
        },
      },
    };
  });

  store.on(SET_NOTIFICATION_READ, async ({ auth }, id) => {
    try {
      await PatchRequest(
        `${NOTIFICATIONS_ENDPOINT}/${id}`,
        {
          id,
          status: NOTIFICATION_ENUM.READ,
        },
        auth.accessToken
      );

      store.dispatch(GET_NOTIFICATIONS);
    } catch (e) {
      store.dispatch(TOAST, {
        type: "error",
        message: e,
      });
    }
  });

  store.on(GET_BOOKED_CALLS, async ({ auth }, options) => {
    try {
      // set loading state to true
      store.dispatch(LOADING_BOOKED_CALLS, true);

      const response = await GetRequest(
        `${BOOKED_CALLS_ENDPOINT}?start=${options.start}&end=${options.end}`,
        auth.accessToken
      );
      // set data into store
      store.dispatch(SET_BOOKED_CALLS, response.data.data);

      // set loading state to false
      store.dispatch(LOADING_BOOKED_CALLS, false);
    } catch (e) {
      store.dispatch(TOAST, {
        type: "error",
        message: `${textData.discoveryCalls.errors.loading} ${e}`,
      });
    }
  });

  store.on(LOADING_BOOKED_CALLS, ({ myFindr }, loading) => {
    return {
      myFindr: {
        ...myFindr,
        bookedCalls: {
          ...myFindr.bookedCalls,
          loading,
        },
      },
    };
  });

  store.on(SET_BOOKED_CALLS, ({ myFindr }, data) => {
    return {
      myFindr: {
        ...myFindr,
        bookedCalls: {
          ...myFindr.bookedCalls,
          data,
        },
      },
    };
  });

  store.on(CANCEL_REQUEST, async ({ auth: { accessToken } }, data) => {
    try {
      await PatchRequest(
        `${REQUEST_ENDPOINT}/${data.requestId}/call`,
        {
          statusDescription: `${data.reason}|${data.comments}`,
        },
        accessToken
      );

      store.dispatch(REFRESH_AFTER_DISMISS, {
        start: data.start,
        end: data.end,
      });

      store.dispatch(TOAST, {
        type: TOAST_INFO,
        message: "Your request has been cancelled successfully",
      });
    } catch (e) {
      store.dispatch(TOAST, {
        type: TOAST_ERROR,
        message: e,
        error: e,
      });
    }
  });

  store.on(
    SHARE_CONTACT_ACCEPT,
    async ({ auth: { accessToken } }, { token, callback }) => {
      try {
        await PostRequest(
          `${SHARE_CONTACT_ACCEPT_ENDPOINT}/${token}`,
          {},
          accessToken
        );
        if (callback) {
          callback();
        }
      } catch (e) {
        store.dispatch(TOAST, {
          type: TOAST_ERROR,
          message: e,
          error: e,
        });
      }
    }
  );

  store.on(
    SHARE_CONTACT_DECLINE,
    async ({ auth: { accessToken } }, { reason, token, callback }) => {
      try {
        await PostRequest(
          `${SHARE_CONTACT_DECLINE_ENDPOINT}/${token}`,
          { reason },
          accessToken
        );
        if (callback) {
          callback();
        }
      } catch (e) {
        store.dispatch(TOAST, {
          type: TOAST_ERROR,
          message: e,
          error: e,
        });
      }
    }
  );

  store.on(
    DISMISS_NOTIFICATION,
    async ({ auth }, { payload, status, statusDescription, activeSummary }) => {
      try {
        await PatchRequest(
          `${REQUEST_ENDPOINT}/${payload.data.id}/status`,
          {
            status,
            statusDescription,
          },
          auth.accessToken
        );

        store.dispatch(GET_NOTIFICATIONS);
        store.dispatch(GET_REQUESTS, { requestType: activeSummary });

        store.dispatch(TOAST, {
          type: TOAST_INFO,
          message: "The request has been rejected successfully",
        });
      } catch (e) {
        store.dispatch(TOAST, {
          type: TOAST_ERROR,
          message: e,
        });
      }
    }
  );

  store.on(REFRESH_AFTER_DISMISS, async (_, { start, end }) => {
    store.dispatch(CLEAR_NOTIFICATIONS);
    store.dispatch(GET_NOTIFICATIONS);
    store.dispatch(GET_BOOKED_CALLS, {
      start,
      end,
    });
  });
}
