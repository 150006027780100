import React, { useEffect, useState } from "react";
import { useStoreon } from "storeon/react";
import { REFRESH_IMAGE, isExpired } from "../../core/store/image";

export const ImageSignedUrl = ({
  identifier,
  src = "",
  className = "",
  title = "",
  onClick,
  style = {},
  alt,
}) => {
  const {
    dispatch,
    image: { preload },
  } = useStoreon("image");

  const ownId =
    (/https*:\/\//.test(src) && src.split("?")[0]?.split("/")?.reverse()[0]) ||
    identifier;
  const isExpiredImage = isExpired(preload[ownId]);
  const defaultSrc = isExpiredImage ? src : preload[ownId];
  const [currentSrc, setCurrentSrc] = useState(defaultSrc);
  const [currentIdentifier, setCurrentIdentifier] = useState(ownId);

  useEffect(() => {
    setCurrentIdentifier(ownId);
    dispatch(REFRESH_IMAGE, { id: ownId, path: src });
  }, [ownId, src]);

  useEffect(() => {
    preload[currentIdentifier] &&
      preload[currentIdentifier] !== currentSrc &&
      setCurrentSrc(preload[currentIdentifier]);
  }, [preload[currentIdentifier]]);

  const handlerOnClick = () => typeof onClick === "function" && onClick();

  return (
    <>
      {currentSrc && (
        <img
          id={currentIdentifier}
          key={currentIdentifier}
          className={className}
          src={currentSrc}
          title={title}
          alt={alt || title}
          style={style}
          onClick={handlerOnClick}
        />
      )}
    </>
  );
};

export const ImageSignedUrlBackground = ({
  identifier,
  src = "",
  className = "",
  children,
}) => {
  const {
    dispatch,
    image: { preload },
  } = useStoreon("image");

  const ownId =
    (/https*:\/\//.test(src) && src.split("?")[0]?.split("/")?.reverse()[0]) ||
    identifier;
  const isExpiredImage = isExpired(preload[ownId]);
  const defaultSrc = isExpiredImage ? src : preload[ownId];
  const [currentSrc, setCurrentSrc] = useState(defaultSrc);
  const [currentIdentifier, setCurrentIdentifier] = useState(ownId);

  useEffect(() => {
    setCurrentIdentifier(ownId);
    dispatch(REFRESH_IMAGE, { id: ownId, path: src });
  }, [identifier, src]);

  useEffect(() => {
    preload[currentIdentifier] &&
      preload[currentIdentifier] !== currentSrc &&
      setCurrentSrc(preload[currentIdentifier]);
  }, [preload[currentIdentifier]]);

  return (
    <div
      id={currentIdentifier}
      key={currentIdentifier}
      className={className}
      style={
        src
          ? {
              backgroundImage: `url(${currentSrc})`,
              backgroundColor: "transparent",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }
          : {}
      }
    >
      {children}
    </div>
  );
};
