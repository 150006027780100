import { DeleteRequest, PostRequest } from "../api/api-request";
import { LIKES_ENDPOINT, FAVORITE_ENDPOINT } from "../../config/endpoints";
import { TOAST, TOAST_ERROR } from "./ui";
import axios from "axios";

export const FAVORITE_GET_LIKES = "FAVORITE_GET_LIKES";
export const FAVORITE_SET_LIKES = "FAVORITE_SET_LIKES";
export const FAVORITE_ADD_LIKE = "FAVORITE_ADD_LIKE";
export const FAVORITE_REMOVE_LIKE = "FAVORITE_REMOVE_LIKE";
export const FAVORITE_IS_LOADING = "FAVORITE_IS_LOADING";
export const FAVORITE_SET_PROCESSING_ID = "FAVORITE_SET_PROCESSING_ID";

export function favorite(store) {
  store.on("@init", () => ({
    favorite: {
      loading: false,
      inProcessId: null,
      likes: [],
    },
  }));

  store.on(FAVORITE_GET_LIKES, async ({ auth }, elementType) => {
    if (!auth?.profile?.id) return;
    try {
      store.dispatch(FAVORITE_IS_LOADING);
      const { data } = await axios.get(
        `${LIKES_ENDPOINT}?userId=${auth.profile.id}&elementType=${elementType}`
      );
      store.dispatch(FAVORITE_SET_LIKES, data);
    } catch (e) {
      store.dispatch(TOAST, {
        type: TOAST_ERROR,
        message: "Failed to get your favorites, try again later",
      });
    } finally {
      store.dispatch(FAVORITE_IS_LOADING);
    }
  });

  store.on(FAVORITE_SET_LIKES, ({ favorite }, likes) => ({
    favorite: {
      ...favorite,
      likes,
    },
  }));

  store.on(FAVORITE_IS_LOADING, ({ favorite }, inProcessId = null) => {
    return {
      favorite: {
        ...favorite,
        inProcessId,
        loading: !favorite.loading,
      },
    };
  });

  store.on(
    FAVORITE_ADD_LIKE,
    async (
      { auth: { strapiToken, profile }, favorite: { likes, loading } },
      { itemID, elementType }
    ) => {
      if (!strapiToken || loading) return;
      try {
        store.dispatch(FAVORITE_IS_LOADING, itemID);
        let like = {
          elementType,
          userId: profile.id,
          elementId: itemID.toString(),
        };

        let { status, data: newData } = await PostRequest(
          `${LIKES_ENDPOINT}`,
          like,
          strapiToken
        );

        if (status === 200 && newData) {
          store.dispatch(FAVORITE_SET_LIKES, [...likes, newData]);
        }
      } catch (e) {
        store.dispatch(TOAST, {
          type: TOAST_ERROR,
          message: "Failed to save your favorite, try again later",
        });
      } finally {
        store.dispatch(FAVORITE_IS_LOADING, null);
      }
    }
  );

  store.on(
    FAVORITE_REMOVE_LIKE,
    async ({ auth: { strapiToken }, favorite: { likes, loading } }, likeID) => {
      if (!strapiToken || loading) return;
      const elementId = likes.find((l) => l.id === likeID).elementId;
      try {
        store.dispatch(FAVORITE_IS_LOADING, elementId);
        let { status, data } = await DeleteRequest(
          `${LIKES_ENDPOINT}/${likeID}`,
          strapiToken
        );
        if (status === 200 && data) {
          const newLikes = likes.filter((like) => like.id !== data.id);
          store.dispatch(FAVORITE_SET_LIKES, newLikes);
        }
      } catch (e) {
        store.dispatch(TOAST, {
          type: TOAST_ERROR,
          message: "Failed to remove your favorite, try again later",
        });
      } finally {
        store.dispatch(FAVORITE_IS_LOADING, null);
      }
    }
  );
}

export const removeFavorite = async ({ favoriteId, strapiToken }) => {
  return DeleteRequest(`${FAVORITE_ENDPOINT}/${favoriteId}`, strapiToken)
    .then((res) => res)
    .catch((e) => e);
};
export const addFavorite = async ({ favorite, strapiToken }) => {
  return PostRequest(FAVORITE_ENDPOINT, favorite, strapiToken)
    .then((res) => res)
    .catch((e) => e);
};
