import * as moment from "moment";
import path from "path";

export const REFRESH_IMAGE = "REFRESH_IMAGE";
export const RESET_IMAGE = "RESET_IMAGE";

const INITIAL_STATE = {
  preload: {},
};

export const image = (store) => {
  store.on("@init", () => ({
    image: INITIAL_STATE,
  }));

  store.on(REFRESH_IMAGE, ({ image: { preload } }, { id, path }) => {
    if (!path) return;

    const newState = {
      image: {
        preload: {
          ...preload,
          [id]: path,
        },
      },
    };

    const domain = path.split("?")[0];

    if (preload[id] && preload[id].indexOf(domain) !== -1) {
      if (/https*:\/\//.test(path)) {
        const isInactive = isExpired(preload[id]);
        return isInactive ? newState : { image: { preload } };
      }
    }

    return id ? newState : { image: { preload } };
  });

  store.on(RESET_IMAGE, () => {
    return {
      image: INITIAL_STATE,
    };
  });
};

export const isExpired = (signedUrl) => {
  if (!signedUrl) return true;

  const url = new URL(
    signedUrl.startsWith("http")
      ? signedUrl
      : path.join("https://general.propose.host", signedUrl)
  );
  const profile = new URLSearchParams(url.search);
  const Expires = profile.has("Expires") && profile.get("Expires");

  return Expires
    ? moment(Number(Expires) * 1000).diff(moment(), "seconds") < 60
    : false;
};
