import { API_ENDPOINT } from "../../config/endpoints";
import { GetRequest } from "../api/api-request";

export const LOAD_PLANS = "LOAD_PLANS";
export const SET_PLANS = "SET_PLANS";

export function plans(store) {
  store.on("@init", () => {
    store.dispatch(LOAD_PLANS);
    return {
      plans: {
        list: [],
      },
    };
  });

  store.on(LOAD_PLANS, async () => {
    try {
      const response = await GetRequest(`${API_ENDPOINT}/payment/products`);

      store.dispatch(SET_PLANS, response?.data);
    } catch (e) {
      console.log(e);
    }
  });

  store.on(SET_PLANS, ({ plans }, items) => {
    return {
      plans: {
        ...plans,
        list:
          items?.map((x) => ({
            amount: x.amount,
            name: x.product.name,
            id: x.id,
          })) || [],
      },
    };
  });
}
