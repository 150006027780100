import { HEADER_V2 } from "../feature-flags";
import HeaderV1 from "../components/header/header";
import HeaderV2 from "../v2/components/header/header";
import React from "react";
import PodcastPlayer from "../components/podcastPlayer/podcast-player";
import { useStoreon } from "storeon/react";

const Header = HEADER_V2 ? HeaderV2 : HeaderV1;

export const Layout = ({ children }) => {
  const {
    settings: { disableLayout },
  } = useStoreon("settings");
  return (
    <>
      {!disableLayout && <Header />}
      {children}
      {!disableLayout && <PodcastPlayer />}
    </>
  );
};
