import { GetRequest, PostRequest } from "../api/api-request";
import { PROFILE_ENDPOINT, PROJECT_ENDPOINT } from "../../config/endpoints";
import { LOGIN_ROUTE } from "../../config/routes";
import { TOAST, TOAST_ERROR } from "./ui";
import { SIGN_IN, SIGN_OUT } from "./auth";

export const LOAD_USER_PROFILE = "LOAD_USER_PROFILE";
export const LOAD_USER_PROJECTS = "LOAD_USER_PROJECTS";
export const SET_USER_PROFILE = "SET_USER_PROFILE";
export const SET_USER_PROJECTS = "SET_USER_PROJECTS";
export const SET_LOADING_PROFILE = "SET_LOADING_PROFILE";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const CLEAR_LOADING_PROFILE = "CLEAR_LOADING_PROFILE";
export const REACTIVATE_PROFILE = "REACTIVATE_PROFILE";

export function profile(store) {
  store.on("@init", () => ({
    profile: {
      profile: {},
      projects: [],
      loadingProfile: null,
    },
  }));

  store.on(
    LOAD_USER_PROFILE,
    async (
      {
        auth: {
          accessToken,
          profile: { id },
          userInfo,
        },
      },
      profileId
    ) => {
      try {
        store.dispatch(SET_LOADING_PROFILE, profileId);

        const { data } = await GetRequest(
          `${PROFILE_ENDPOINT}${
            profileId && profileId !== id
              ? "/" + encodeURIComponent(profileId)
              : ""
          }`,
          accessToken
        );

        store.dispatch(SET_USER_PROFILE, { profile: data, profileId });

        if (!profileId || profileId === id) {
          store.dispatch(SIGN_IN, {
            accessToken,
            userInfo,
            profile: data,
            error: false,
          });
        }
      } catch (e) {
        store.dispatch(TOAST, { type: TOAST_ERROR, message: e, error: e });
      }
    }
  );

  store.on(
    LOAD_USER_PROJECTS,
    async (
      {
        auth: {
          accessToken,
          profile: { id },
        },
      },
      profileId
    ) => {
      try {
        store.dispatch(SET_LOADING_PROFILE, profileId);

        const response1 = await GetRequest(
          `${PROJECT_ENDPOINT}?limit=100${
            profileId && profileId !== id
              ? `&userId=${encodeURIComponent(profileId)}`
              : ""
          }`,
          accessToken
        );

        let data = [...response1.data.data];

        if (!profileId || profileId === id) {
          const response2 = await GetRequest(
            `${PROJECT_ENDPOINT}?limit=100&active=false`,
            accessToken
          );

          data = [...data, ...response2.data.data];
        }

        store.dispatch(SET_USER_PROJECTS, { data, profileId });
      } catch (e) {
        store.dispatch(TOAST, { type: TOAST_ERROR, message: e, error: e });
      }
    }
  );

  store.on(
    SET_USER_PROFILE,
    ({ profile }, { profile: newProfile, profileId }) => {
      return {
        profile: {
          ...profile,
          profile:
            profile.loadingProfile === profileId ? newProfile : profile.profile,
        },
      };
    }
  );

  store.on(SET_USER_PROJECTS, ({ profile }, { data: projects, profileId }) => {
    return {
      profile: {
        ...profile,
        projects:
          !profile.loadingProfile || profile.loadingProfile === profileId
            ? projects
            : profile.projects,
      },
    };
  });

  store.on(CLEAR_LOADING_PROFILE, ({ profile }) => {
    return {
      profile: {
        ...profile,
        loadingProfile: null,
      },
    };
  });

  store.on(CLEAR_PROFILE, () => {
    return {
      profile: {
        profile: {},
        projects: [],
        loadingProfile: null,
      },
    };
  });

  store.on(SET_LOADING_PROFILE, ({ profile }, loadingProfile) => {
    return {
      profile: {
        ...profile,
        loadingProfile,
      },
    };
  });

  store.on(REACTIVATE_PROFILE, async ({ auth: { accessToken } }, history) => {
    store.dispatch(SET_LOADING_PROFILE, true);

    try {
      await PostRequest(`${PROFILE_ENDPOINT}/reactivate`, null, accessToken);
      store.dispatch(SIGN_OUT);
      history.push(LOGIN_ROUTE);
    } catch (e) {
      store.dispatch(TOAST, { type: TOAST_ERROR, message: e, error: e });
    }
  });
}
