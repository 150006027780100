import {
  DeleteRequest,
  GetRequest,
  PatchRequest,
  PostRequest,
  PutRequest,
} from "../api/api-request";
import {
  BUCKET_ENDPOINT,
  PAYMENT_METHOD_DEFAULT_ENDPOINT,
  PAYMENT_METHOD_UPCOMING_INFO_ENDPOINT,
  PAYMENT_METHODS_ENDPOINT,
  PAYMENT_PROFILE_INVOICE_INFO,
  PAYMENT_PROFILE_PLANS_INFO,
  PROFILE_COMMUNICATIONS_ENDPOINT,
  PROFILE_ENDPOINT,
} from "../../config/endpoints";
import { TOAST, TOAST_ERROR, TOAST_INFO, TOAST_SUCCESS } from "./ui";
import { SET_STRAPI_TOKEN } from "./auth";
import { toMonthName } from "../utils/functions";

export const PROFILE_LOAD_SETTINGS = "PROFILE_LOAD_SETTINGS";
export const PROFILE_SET_SETTINGS = "PROFILE_SET_SETTINGS";
export const SETTINGS_SAVE_SETTINGS = "SETTINGS_SAVE_SETTINGS";
export const SETTINGS_UPLOAD_IMAGE = "SETTINGS_UPLOAD_IMAGE";
export const SETTINGS_DELETE_ACCOUNT = "PROFILE_DELETE_ACCOUNT";
export const SETTINGS_SET_DELETING = "SETTINGS_SET_DELETING";
export const SETTINGS_SET_DELETED = "SETTINGS_SET_DELETED";
export const SETTINGS_SAVE_COMMUNICATIONS = "SETTINGS_SAVE_COMMUNICATIONS";
export const SETTINGS_SET_COMMUNICATIONS_SAVING =
  "SETTINGS_SET_COMMUNICATIONS_SAVING";
export const SETTINGS_SET_PROFILE = "SETTINGS_SET_PROFILE";
export const SETTINGS_LOAD_CARDS = "SETTINGS_LOAD_CARDS";
export const SETTINGS_SET_CARDS = "SETTINGS_SET_CARDS";
export const SETTINGS_ADD_CARD = "SETTINGS_ADD_CARD";
export const SETTINGS_UPDATE_CARD = "SETTINGS_UPDATE_CARD";
export const SETTINGS_REMOVE_CARD = "SETTINGS_REMOVE_CARD";
export const SETTINGS_SET_DEFAULT_CARD = "SETTINGS_SET_DEFAULT_CARD";
export const SETTINGS_LOAD_UPCOMING_INFO = "SETTINGS_LOAD_UPCOMING_INFO";
export const SETTINGS_SET_UPCOMING_INFO = "SETTINGS_SET_UPCOMING_INFO";
export const SETTINGS_SET_RECENT_CHANGES = "SETTINGS_SET_RECENT_CHANGES";
export const SETTINGS_SET_LOADING = "SETTINGS_SET_LOADING";
export const SETTINGS_TAB = "SETTINGS_TAB";
export const SETTINGS_PROFILE_PLANS_INFO = "SETTINGS_PROFILE_PLANS_INFO";
export const SETTINGS_SET_PROFILE_PLANS_INFO =
  "SETTINGS_SET_PROFILE_PLANS_INFO";
export const SETTINGS_SET_LOADING_PROFILE_PLANS_INFO =
  "SETTINGS_SET_LOADING_PROFILE_PLANS_INFO";
export const SETTINGS_PROFILE_INVOICE_INFO = "SETTINGS_PROFILE_INVOICE_INFO";
export const SETTINGS_SET_PROFILE_INVOICE_INFO =
  "SETTINGS_SET_PROFILE_INVOICE_INFO";
export const SETTINGS_SET_LOADING_PROFILE_INVOICE_INFO =
  "SETTINGS_SET_LOADING_PROFILE_INVOICE_INFO";
export const SETTINGS_DOWNLOAD_PROFILE_INVOICES =
  "SETTINGS_DOWNLOAD_PROFILE_INVOICES";
export const SET_DISABLE_LAYOUT = "SET_DISABLE_LAYOUT";
export const DISABLE_LAYOUT = "DISABLE_LAYOUT";
export const ENABLE_LAYOUT = "ENABLE_LAYOUT";

export function settings(store) {
  store.on("@init", () => ({
    settings: {
      deleting: false,
      deleted: false,
      communicationsSaving: false,
      cards: [],
      defaultCard: "",
      recentChanges: false,
      tab: "details",
      loading: false,
      disableLayout: false,
      profilePlanInfo: {
        info: [],
        isLoadingInfo: false,
        hasInvoices: false,
      },
      profileInvoiceInfo: {
        info: "",
        fileName: "",
        isLoadingInfo: true,
      },
    },
  }));

  store.on(
    SETTINGS_SET_COMMUNICATIONS_SAVING,
    ({ settings }, communicationsSaving) => {
      return {
        settings: {
          ...settings,
          communicationsSaving,
        },
      };
    }
  );

  store.on(SETTINGS_SET_PROFILE, ({ auth }, profile) => {
    return {
      auth: {
        ...auth,
        profile,
      },
    };
  });

  store.on(SET_DISABLE_LAYOUT, ({ settings }, disableLayout) => {
    return { settings: { ...settings, disableLayout } };
  });
  store.on(DISABLE_LAYOUT, ({ settings }) => {
    return { settings: { ...settings, disableLayout: true } };
  });
  store.on(ENABLE_LAYOUT, ({ settings }) => {
    return { settings: { ...settings, disableLayout: false } };
  });

  store.on(
    SETTINGS_SAVE_COMMUNICATIONS,
    async ({ auth: { accessToken } }, communications) => {
      const data = { ...communications };

      try {
        store.dispatch(SETTINGS_SET_COMMUNICATIONS_SAVING, true);
        await PostRequest(PROFILE_COMMUNICATIONS_ENDPOINT, data, accessToken);
        const response = await GetRequest(`${PROFILE_ENDPOINT}`, accessToken);

        store.dispatch(SETTINGS_SET_PROFILE, response.data);
        store.dispatch(TOAST, {
          type: TOAST_SUCCESS,
          message: "Profile communications settings saved successfully",
        });
      } catch (e) {
        store.dispatch(TOAST, {
          type: TOAST_ERROR,
          message: e,
          error: e,
        });
      } finally {
        store.dispatch(SETTINGS_SET_COMMUNICATIONS_SAVING, false);
      }
    }
  );

  store.on(SETTINGS_SET_DELETING, ({ settings }, deleting) => {
    return {
      settings: {
        ...settings,
        deleting,
      },
    };
  });

  store.on(SETTINGS_SET_DELETED, ({ settings }, deleted) => {
    return {
      settings: {
        ...settings,
        deleted,
      },
    };
  });

  store.on(
    SETTINGS_DELETE_ACCOUNT,
    async ({ auth: { accessToken } }, reasons) => {
      store.dispatch(SETTINGS_SET_DELETING, true);
      try {
        await PatchRequest(`${PROFILE_ENDPOINT}/close`, reasons, accessToken);

        store.dispatch(SETTINGS_SET_DELETED, true);
      } catch (e) {
        store.dispatch(TOAST, {
          type: TOAST_ERROR,
          message: e,
          error: e,
        });
      } finally {
        store.dispatch(SETTINGS_SET_DELETING, false);
      }
    }
  );

  store.on(PROFILE_SET_SETTINGS, ({ auth }, profile) => {
    return {
      auth: {
        ...auth,
        profile,
      },
    };
  });

  store.on(
    SETTINGS_UPLOAD_IMAGE,
    async (
      { auth: { accessToken } },
      { avatarData, logoData, ...settings }
    ) => {
      // notify user
      store.dispatch(TOAST, {
        type: TOAST_INFO,
        message: "Uploading image...",
      });

      // to be send to the server
      let getAvatarUrl;
      let getLogoUrl;
      // to upload image
      let putAvatarUrl;
      let putLogoUrl;

      // then try to get the avatar image url endpoints
      if (avatarData) {
        try {
          const { avatarFile, ...imageConfig } = avatarData;
          const res = await PostRequest(
            `${BUCKET_ENDPOINT}`,
            imageConfig,
            accessToken
          );
          // update variables
          getAvatarUrl = res.data.get;
          putAvatarUrl = res.data.put;
        } catch (e) {
          store.dispatch(TOAST, {
            type: TOAST_ERROR,
            message: "Error uploading personal admin image. Try again",
            error: e,
          });
        }
      }

      // then try to get the company logo url endpoints
      if (logoData) {
        try {
          const { logoFile, ...imageConfig } = logoData;
          const res = await PostRequest(
            `${BUCKET_ENDPOINT}`,
            imageConfig,
            accessToken
          );
          // update variables
          getLogoUrl = res.data.get;
          putLogoUrl = res.data.put;
        } catch (e) {
          store.dispatch(TOAST, {
            type: TOAST_ERROR,
            message: "Error uploading company logo. Try again",
            error: e,
          });
        }
      }

      // upload images
      try {
        if (avatarData) {
          await PutRequest(putAvatarUrl, avatarData.avatarFile, {
            headers: {
              "Content-Type": avatarData.contentType,
            },
          });
        }
        if (logoData) {
          await PutRequest(putLogoUrl, logoData.logoFile, {
            headers: {
              "Content-Type": logoData.contentType,
            },
          });
        }
        const updatedSettings = {
          ...settings,
          photo: avatarData ? getAvatarUrl : settings.photo,
          companyLogo: logoData ? getLogoUrl : settings.companyLogo,
        };
        store.dispatch(SETTINGS_SAVE_SETTINGS, updatedSettings);
      } catch (e) {
        store.dispatch(TOAST, {
          type: TOAST_ERROR,
          message: "Error uploading image. Please try again",
          error: e,
        });
      }
    }
  );

  store.on(SETTINGS_SAVE_SETTINGS, async ({ auth }, settings) => {
    store.dispatch(TOAST, {
      type: TOAST_INFO,
      message: "Saving your settings...",
    });

    try {
      delete settings.notificationSettings;
      const { data } = await PatchRequest(
        `${PROFILE_ENDPOINT}`,
        {
          ...settings,
        },
        auth.accessToken
      );
      const { strapi, ...rest } = data;
      store.dispatch(SET_STRAPI_TOKEN, strapi.token);
      // update Settings store
      store.dispatch(PROFILE_SET_SETTINGS, rest);
      store.dispatch(TOAST, {
        type: TOAST_SUCCESS,
        message: "Your settings were saved.",
      });
    } catch (e) {
      store.dispatch(TOAST, {
        type: TOAST_ERROR,
        message: "We couldn't save your settings. Please try again",
        error: e,
      });
    }
  });

  store.on(SETTINGS_LOAD_CARDS, async ({ auth }) => {
    store.dispatch(SETTINGS_SET_LOADING, true);
    try {
      const response = await GetRequest(
        PAYMENT_METHODS_ENDPOINT,
        auth.accessToken
      );

      store.dispatch(SETTINGS_SET_CARDS, response.data);
    } catch (e) {
      store.dispatch(TOAST, {
        type: TOAST_ERROR,
        message: e,
        error: e,
      });
    }
    store.dispatch(SETTINGS_SET_LOADING, false);
  });

  store.on(SETTINGS_SET_CARDS, ({ settings }, data) => {
    return {
      settings: {
        ...settings,
        cards: data.items.reverse(),
        defaultCardId: data.defaultPaymentMethod,
      },
    };
  });

  store.on(SETTINGS_ADD_CARD, async ({ auth }, paymentMethodId) => {
    try {
      store.dispatch(TOAST, {
        type: TOAST_INFO,
        message: "Adding new card",
      });

      await PostRequest(
        PAYMENT_METHODS_ENDPOINT,
        {
          paymentMethodId,
        },
        auth.accessToken
      );

      store.dispatch(SETTINGS_LOAD_CARDS);
    } catch (e) {
      store.dispatch(TOAST, {
        type: TOAST_ERROR,
        message: e,
        error: e,
      });
    }
  });

  store.on(
    SETTINGS_UPDATE_CARD,
    async ({ auth: { accessToken } }, { data, isDefault }) => {
      try {
        store.dispatch(TOAST, {
          type: TOAST_INFO,
          message: "Updating card",
        });
        await PatchRequest(PAYMENT_METHODS_ENDPOINT, data, accessToken);
        store.dispatch(TOAST, {
          type: TOAST_SUCCESS,
          message: "Card updated successfully",
        });

        store.dispatch(SETTINGS_LOAD_CARDS);
        if (isDefault) {
          store.dispatch(SETTINGS_SET_RECENT_CHANGES, true);
        }
      } catch (e) {
        store.dispatch(TOAST, {
          type: TOAST_ERROR,
          message: e,
          error: e,
        });
      }
    }
  );

  store.on(SETTINGS_REMOVE_CARD, async ({ auth: { accessToken } }, id) => {
    try {
      store.dispatch(TOAST, {
        type: TOAST_INFO,
        message: "Removing card",
      });
      await DeleteRequest(`${PAYMENT_METHODS_ENDPOINT}/${id}`, accessToken);

      store.dispatch(SETTINGS_LOAD_CARDS);
    } catch (e) {
      store.dispatch(TOAST, {
        type: TOAST_ERROR,
        message: e,
        error: e,
      });
    }
  });

  store.on(SETTINGS_SET_DEFAULT_CARD, async ({ auth: { accessToken } }, id) => {
    try {
      store.dispatch(SETTINGS_SET_LOADING, true);
      store.dispatch(TOAST, {
        type: TOAST_INFO,
        message: "Setting default card",
      });
      await PatchRequest(
        PAYMENT_METHOD_DEFAULT_ENDPOINT,
        {
          id,
        },
        accessToken
      );

      store.dispatch(SETTINGS_LOAD_CARDS);
      store.dispatch(SETTINGS_SET_RECENT_CHANGES, true);
    } catch (e) {
      store.dispatch(TOAST, {
        type: TOAST_ERROR,
        message: e,
        error: e,
      });
    }
  });

  store.on(SETTINGS_LOAD_UPCOMING_INFO, async ({ auth: { accessToken } }) => {
    try {
      const response = await GetRequest(
        `${PAYMENT_METHOD_UPCOMING_INFO_ENDPOINT}`,
        accessToken
      );

      store.dispatch(SETTINGS_SET_UPCOMING_INFO, response.data);
    } catch (e) {
      console.log(e);
    }
  });

  store.on(SETTINGS_SET_UPCOMING_INFO, ({ settings }, upcomingInfo) => {
    return {
      settings: {
        ...settings,
        upcomingInfo,
      },
    };
  });

  store.on(SETTINGS_SET_RECENT_CHANGES, ({ settings }, recentChanges) => {
    return {
      settings: {
        ...settings,
        recentChanges,
      },
    };
  });

  store.on(SETTINGS_SET_LOADING, ({ settings }, loading) => {
    return {
      settings: {
        ...settings,
        loading,
      },
    };
  });

  store.on(SETTINGS_TAB, ({ settings }, tab) => {
    return {
      settings: {
        ...settings,
        tab,
      },
    };
  });

  store.on(
    SETTINGS_PROFILE_PLANS_INFO,
    async ({ auth, settings }, { year, callback = () => {} }) => {
      store.dispatch(SETTINGS_SET_LOADING_PROFILE_PLANS_INFO, true);

      try {
        const { data } = await GetRequest(
          `${PAYMENT_PROFILE_PLANS_INFO}?year=${year}`,
          auth.accessToken
        );

        let hasInvoices = data.some((item) => item.plans.length > 0);

        const currentYear = new Date().getFullYear();
        if (currentYear !== year) {
          hasInvoices = settings.profilePlanInfo.hasInvoices;
        }

        store.dispatch(SETTINGS_SET_PROFILE_PLANS_INFO, {
          info: data,
          hasInvoices,
        });
      } catch (e) {
        store.dispatch(TOAST, {
          type: TOAST_ERROR,
          message: e,
          error: e,
        });
      }
      store.dispatch(SETTINGS_SET_LOADING_PROFILE_PLANS_INFO, false);
      callback();
    }
  );

  store.on(
    SETTINGS_SET_PROFILE_PLANS_INFO,
    ({ settings }, { info, hasInvoices }) => {
      return {
        settings: {
          ...settings,
          profilePlanInfo: {
            ...settings.profilePlanInfo,
            info,
            hasInvoices,
          },
        },
      };
    }
  );

  store.on(SETTINGS_SET_LOADING_PROFILE_PLANS_INFO, ({ settings }, loading) => {
    return {
      settings: {
        ...settings,
        profilePlanInfo: {
          ...settings.profilePlanInfo,
          isLoadingInfo: loading,
        },
      },
    };
  });

  store.on(
    SETTINGS_PROFILE_INVOICE_INFO,
    async ({ auth }, { year, month, plan, type }) => {
      store.dispatch(SETTINGS_SET_LOADING_PROFILE_INVOICE_INFO, true);

      try {
        const response = await GetRequest(
          `${PAYMENT_PROFILE_INVOICE_INFO}?year=${year}&month=${month}&plan=${plan}&type=${type}`,
          auth.accessToken
        );

        store.dispatch(SETTINGS_SET_PROFILE_INVOICE_INFO, response.data);
      } catch (e) {
        store.dispatch(TOAST, {
          type: TOAST_ERROR,
          message: e,
          error: e,
        });
      }
      store.dispatch(SETTINGS_SET_LOADING_PROFILE_INVOICE_INFO, false);
    }
  );

  store.on(
    SETTINGS_SET_PROFILE_INVOICE_INFO,
    ({ settings }, { url, fileName }) => {
      return {
        settings: {
          ...settings,
          profileInvoiceInfo: {
            ...settings.profileInvoiceInfo,
            info: url,
            fileName,
          },
        },
      };
    }
  );

  store.on(
    SETTINGS_SET_LOADING_PROFILE_INVOICE_INFO,
    ({ settings }, loading) => {
      return {
        settings: {
          ...settings,
          profileInvoiceInfo: {
            ...settings.profileInvoiceInfo,
            isLoadingInfo: loading,
          },
        },
      };
    }
  );

  store.on(
    SETTINGS_DOWNLOAD_PROFILE_INVOICES,
    async (
      {
        auth,
        settings: {
          profilePlanInfo: { info },
        },
      },
      year
    ) => {
      try {
        info
          .filter((item) => item.plans.length)
          .forEach(({ month, plans }) => {
            plans.forEach((plan) => {
              GetRequest(
                `${PAYMENT_PROFILE_INVOICE_INFO}?year=${year}&month=${month}&plan=${plan}&type=Both&download=1`,
                auth.accessToken
              ).then((response) => {
                const a = document.createElement("a");
                a.href = response.data.url;
                a.download = `${plan}-${toMonthName(month)}-${year}.pdf`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
              });
            });
          });
      } catch (e) {
        store.dispatch(TOAST, {
          type: TOAST_ERROR,
          message: e,
          error: e,
        });
      }
    }
  );
}
