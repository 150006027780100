import axios from "axios";
import { CASE_STUDIES_ENDPOINT } from "../../config/endpoints";
import { TOAST, TOAST_ERROR } from "./ui";
import { stringToSlug } from "../utils/functions";

export const GET_CASE_STUDIES = "GET_CASE_STUDIES";
export const SET_CASE_STUDIES_LOADING = "SET_CASE_STUDIES_LOADING";
export const SET_CASE_STUDIES = "SET_CASE_STUDIES";
export const SET_IS_LIST_COMPLETED = "SET_IS_LIST_COMPLETED";
export const SET_SELECTED_CASE = "SET_SELECTED_CASE";
export const SET_CASE_STUDIES_CONFIG = "SET_CASE_STUDIES_CONFIG";
export const SET_CASE_STUDIES_PAGE = "SET_CASE_STUDIES_PAGE";

const INITIAL_STATE = {
  config: null,
  loading: true,
  page: 0,
  list: [],
  isListCompleted: false,
  selected: null,
  perPage: 12,
};

export function caseStudies(store) {
  store.on("@init", () => ({
    caseStudies: INITIAL_STATE,
  }));

  store.on(GET_CASE_STUDIES, async function ({ caseStudies }) {
    const mapCaseStudies = (caseStudies) => {
      return caseStudies.map(
        ({ case_study_body, case_study_company, ...rest }) => ({
          ...rest,
          ...case_study_body,
          ...case_study_company,
          slug: stringToSlug(case_study_body.title),
        })
      );
    };
    const groupByCollection = (response) => {
      const { case_study_section, ...mainConfig } = response;
      const { case_studies, ...caseStudiesConfig } = case_study_section;
      const newCaseStudies = mapCaseStudies(case_studies);
      return {
        config: { ...mainConfig, ...caseStudiesConfig },
        caseStudies: newCaseStudies,
      };
    };

    store.dispatch(SET_CASE_STUDIES_LOADING, true);
    try {
      let newData = [];
      let { list, page, perPage } = caseStudies;

      if (!page && list.length > 0) {
        store.dispatch(SET_CASE_STUDIES_PAGE, Math.ceil(list.length / perPage));
        return;
      }

      let { data, headers } = await axios(
        `${CASE_STUDIES_ENDPOINT}/?_caseStudyLimit=${perPage}&_caseStudyStart=${++page}`
      );

      const count = headers["x-total-count"];
      if (Array.isArray(data)) {
        newData = list.concat(mapCaseStudies(data));
      } else {
        const { config, caseStudies } = groupByCollection(data);
        newData = list.concat([...caseStudies]);
        store.dispatch(SET_CASE_STUDIES_CONFIG, config);
      }

      store.dispatch(SET_IS_LIST_COMPLETED, newData.length === +count);
      store.dispatch(SET_CASE_STUDIES, newData);
    } catch (err) {
      store.dispatch(SET_IS_LIST_COMPLETED, true);
      store.dispatch(TOAST, {
        type: TOAST_ERROR,
        message: "Failed to load case studies",
      });
    } finally {
      store.dispatch(SET_CASE_STUDIES_LOADING, false);
    }
  });

  store.on(SET_CASE_STUDIES_CONFIG, ({ caseStudies }, config) => {
    return {
      caseStudies: {
        ...caseStudies,
        config,
      },
    };
  });

  store.on(SET_CASE_STUDIES, ({ caseStudies }, list) => {
    return {
      caseStudies: {
        ...caseStudies,
        page: caseStudies.page + 1,
        list,
      },
    };
  });

  store.on(SET_CASE_STUDIES_LOADING, ({ caseStudies }, loading) => {
    return {
      caseStudies: {
        ...caseStudies,
        loading,
      },
    };
  });

  store.on(SET_IS_LIST_COMPLETED, ({ caseStudies }, isListCompleted) => {
    return {
      caseStudies: {
        ...caseStudies,
        isListCompleted,
      },
    };
  });

  store.on(SET_CASE_STUDIES_PAGE, ({ caseStudies }, page) => {
    return {
      caseStudies: {
        ...caseStudies,
        page,
      },
    };
  });
}
