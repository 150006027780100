/* eslint-disable */
export const API_ENDPOINT =
    process.env.BACKEND_URL || "https://api-dev.findr.global/v1";
export const API_STRAPI_ENDPOINT = process.env.STRAPI_URL || "https://dev-strapi.findr.global";
export const STRAPI_TOKEN_ENDPOINT = API_ENDPOINT + '/profile/login/strapi';
export const PROFILE_ENDPOINT = API_ENDPOINT + "/profile";
export const PROFILE_LINKEDIN_SYNC_ENDPOINT = PROFILE_ENDPOINT + "/linkedin/sync";
export const ENTERPRISE_MEMBER_ENDPOINT = API_ENDPOINT + "/profile/member";
export const VALIDATE_INVITATION_LINK_ENDPOINT = API_ENDPOINT + "/profile/invite/validate";
export const PROFILE_COMMUNICATIONS_ENDPOINT =
    API_ENDPOINT + "/profile/notification/settings";
export const PROJECT_ENDPOINT = API_ENDPOINT + "/project";
export const PROJECT_ENDPOINT_SAVED = `${PROJECT_ENDPOINT}/saved`;
export const MY_FINDR_MATCHES_ENDPOINT = API_ENDPOINT + "/project/search/match";
export const REQUEST_ENDPOINT = API_ENDPOINT + "/request";
export const NEWSLETTER_ENDPOINT = API_ENDPOINT + "/newsLetter";
export const BUCKET_ENDPOINT = API_ENDPOINT + "/storage/signedUrl";
export const BUCKET_DOC_ENDPOINT = API_ENDPOINT + "/storage/signedUrl-docs";
export const REQUESTS_ENDPOINT = API_ENDPOINT + "/request/type";
export const NOTIFICATIONS_ENDPOINT = API_ENDPOINT + "/profile/notification";
export const UNREAD_NOTIFICATIONS_ENDPOINT =
    API_ENDPOINT + "/profile/notification/unread";
export const NOTIFICATIONS_TOKEN_ENDPOINT =
    API_ENDPOINT + "/profile/device/register";
export const NOTIFICATIONS_TOKEN_UNAUTHENTICATED_ENDPOINT =
    API_ENDPOINT + "/profile/device/unauthenticated/register";
export const BOOKED_CALLS_ENDPOINT = API_ENDPOINT + "/request/booked";
export const CREATE_SUBSCRIPTION_ENDPOINT =
    API_ENDPOINT + "/payment/create-subscription";
export const PROFILE_CREATE_SUBSCRIPTION_ENDPOINT =
    API_ENDPOINT + "/profile/create-subscription-profile";
export const PROFILE_CONCURRENT_LOGIN_ENDPOINT =
    API_ENDPOINT + "/profile/concurrent-login";
export const UPDATE_SUBSCRIPTION_ENDPOINT =
    API_ENDPOINT + "/payment/updated-subscription";
export const DASHBOARD_ENDPOINT = API_ENDPOINT + "/project/dashboard";
export const PAYMENT_METHODS_ENDPOINT = API_ENDPOINT + "/payment/my-payment-methods";
export const PAYMENT_PROFILE_PLANS_INFO = API_ENDPOINT + "/payment/my-year-plans";
export const PAYMENT_PROFILE_INVOICE_INFO = API_ENDPOINT + "/payment/my-invoices";
export const PAYMENT_LICENSE_INFO_ENDPOINT =
    API_ENDPOINT + "/payment/enterprise-user-info";
export const PAYMENT_METHOD_DEFAULT_ENDPOINT =
    API_ENDPOINT + "/payment/set-my-default-payment-methods";
export const PAYMENT_METHOD_UPCOMING_INFO_ENDPOINT =
    API_ENDPOINT + "/payment/my-uncoming-invoice";
export const COMPANIES_ENDPOINT = API_ENDPOINT + "/logos";
export const SHARE_CONTACT_ACCEPT_ENDPOINT = REQUEST_ENDPOINT + "/share/consent";
export const SHARE_CONTACT_DECLINE_ENDPOINT = REQUEST_ENDPOINT + "/share/decline";
export const CASE_STUDIES_ENDPOINT = API_STRAPI_ENDPOINT + "/case-study-page";
export const BLOG_ENDPOINT = API_STRAPI_ENDPOINT + "/blog-page";
export const POST_ENDPOINT = API_STRAPI_ENDPOINT + "/articles/";
export const CATEGORY_ENDPOINT = API_STRAPI_ENDPOINT + "/categories";
export const LIKES_ENDPOINT = API_STRAPI_ENDPOINT + "/likes";
export const FAVORITE_ENDPOINT = API_STRAPI_ENDPOINT + "/favorites";
export const PODCAST_PAGE_ENDPOINT = API_STRAPI_ENDPOINT + "/podcast-page";
export const PODCAST_ENDPOINT = API_STRAPI_ENDPOINT + "/podcasts";
export const PRIVACY_ENDPOINT = API_STRAPI_ENDPOINT + "/privacy-policy-page";
export const TERMS_ENDPOINT = API_STRAPI_ENDPOINT + "/terms-and-conditions-page";
export const FAQS_ENDPOINT = API_STRAPI_ENDPOINT + "/faq-page";
export const FOOTER_ENDPOINT = API_STRAPI_ENDPOINT + "/footer";
export const HOW_IT_WORKS_ENDPOINT = API_STRAPI_ENDPOINT + "/how-it-works-page";
export const PLANS_ENDPOINT = API_STRAPI_ENDPOINT + "/plans-page";
export const HOME_ENDPOINT = API_STRAPI_ENDPOINT + "/home-page";

