import { GetRequest, PostRequest } from "../../../core/api/api-request";
import { TOAST, TOAST_ERROR, TOAST_SUCCESS } from "../../../core/store/ui";
import {
  PROJECT_ENDPOINT,
  API_STRAPI_ENDPOINT,
  MY_FINDR_MATCHES_ENDPOINT,
} from "../../config/endpoints";

export const SEARCH_PROJECTS = "SEARCH_PROJECTS";
export const LOAD_MARKETING_PROJECTS = "LOAD_MARKETING_PROJECTS";
export const SEARCH_PROJECTS_NOTIFY = "SEARCH_PROJECTS_NOTIFY";
export const SET_PROJECTS = "SET_PROJECTS";
export const SET_LOAD_MORE = "SET_LOAD_MORE";
export const SET_DISCOVER_IS_LOADING = "SET_DISCOVER_IS_LOADING";
export const SET_MARKETING_PROJECTS = "SET_MARKETING_PROJECTS";
export const SEARCH_PROJECTS_RESTART = "SEARCH_PROJECTS_RESTART";
export const SET_ADVANCED_FILTERS = "SET_ADVANCED_FILTERS";
export const SEARCH_PROJECTS_CLEANUP = "SEARCH_PROJECTS_CLEANUP";

export const INITIAL_FILTERS = {
  partnerType: "",
  solution: "",
  partnerStage: "",
  partnerSize: "",
  partnerMarkets: "",
  partnerCountries: "",
};

export function discover(store) {
  const limit = 12;

  store.on("@init", () => ({
    discover: {
      keywords: "",
      offset: 0,
      projects: [],
      canLoadMore: false,
      isLoading: false,
      marketingProjects: [],
      isFiRecommendationChecked: false,
      isFiRecommendationCheckedAtLeastOnce: false,
      advancedFilters: { ...INITIAL_FILTERS },
    },
  }));

  /* eslint-disable */
  store.on(LOAD_MARKETING_PROJECTS, async ({ auth }) => {
    if (!auth.strapiToken) return;
    try {
      const response = await GetRequest(
        `${API_STRAPI_ENDPOINT}/marketing-panel`,
        auth.strapiToken
      );

      const panels = [];
      const forLoop = async () => {
        for (let index = 0; index < response.data.panels.length; index++) {
          const element = response.data.panels[index];

          if (element.__component === "discover-section.profile") {
            try {
              const project = await GetRequest(
                `${PROJECT_ENDPOINT}/${element.project_id}`,
                auth.accessToken
              );

              panels.push({ ...element, ...project.data });
            } catch (error) {
              continue;
            }
          } else {
            panels.push(element);
          }
        }
      };
      await forLoop();
      store.dispatch(SET_MARKETING_PROJECTS, panels);
    } catch (e) {
      if (e && e.response && e.response.status === 404) {
        store.dispatch(SET_MARKETING_PROJECTS, []);
        return;
      }
      store.dispatch(TOAST, {
        type: TOAST_ERROR,
        message: e,
        error: e,
      });
    }
  });

  store.on(SET_MARKETING_PROJECTS, ({ discover }, marketingProjects) => {
    return {
      discover: {
        ...discover,
        marketingProjects,
      },
    };
  });

  store.on(
    SEARCH_PROJECTS,
    async ({ discover, auth }, { keywords, loadMore = false, offset }) => {
      try {
        store.dispatch(SET_DISCOVER_IS_LOADING, true);
        let response;
        const offsetResult = offset !== undefined ? offset : discover.offset;

        if (discover.isFiRecommendationChecked) {
          response = await GetRequest(
            `${MY_FINDR_MATCHES_ENDPOINT}?offset=${offsetResult}&limit=${limit}&excludeDeletedMatches=false`,
            auth.accessToken
          );
        } else {
          const getQueryURL = () => {
            const keyword =
              keywords && encodeURIComponent(keywords.replace(/\b(,\s)/g, ","));
            let query = `${PROJECT_ENDPOINT}/search?${
              keyword ? `keyword=${keyword}&` : ""
            }offset=${offsetResult}&limit=${12}`;
            if (
              typeof discover.advancedFilters &&
              Object.keys(discover.advancedFilters).length
            ) {
              for (let [fieldName, value] of Object.entries(
                discover.advancedFilters
              )) {
                if (value) {
                  const queryString = `&${fieldName}=${encodeURIComponent(
                    value.replace(/\b(,\s)/g, ",")
                  )}`;
                  query = query.concat(queryString);
                }
              }
            }
            return query;
          };

          response = await GetRequest(getQueryURL(), auth.accessToken);
        }

        store.dispatch(SET_PROJECTS, {
          projects: response.data,
          keywords: keywords,
          loadMore,
          init: false,
        });

        store.dispatch(SET_LOAD_MORE, response.data.length === 12);
      } catch (e) {
        store.dispatch(SET_LOAD_MORE, false);
      } finally {
        store.dispatch(SET_DISCOVER_IS_LOADING, false);
      }
    }
  );

  store.on(SEARCH_PROJECTS_NOTIFY, async ({ auth }, { keywords }) => {
    try {
      await PostRequest(
        `${PROJECT_ENDPOINT}/search/notify`,
        { keyword: keywords },
        auth.accessToken
      );
      store.dispatch(TOAST, {
        type: TOAST_SUCCESS,
        message: "When there is any result you will be notified",
      });
    } catch (e) {
      store.dispatch(TOAST, {
        type: TOAST_ERROR,
        message: e,
        error: e,
      });
    }
  });

  store.on(
    SET_PROJECTS,
    ({ discover }, { projects, keywords, loadMore = false, init = false }) => {
      return {
        discover: {
          ...discover,
          projects: !loadMore ? projects : [...discover.projects, ...projects],
          keywords,
          offset: init
            ? 12
            : loadMore
            ? discover.offset + projects.length
            : projects.length,
        },
      };
    }
  );

  store.on(SEARCH_PROJECTS_RESTART, () => {
    return {
      discover: {
        keywords: "",
        offset: 0,
        projects: [],
        canLoadMore: false,
        isLoading: false,
        marketingProjects: [],
        advancedFilters: { ...INITIAL_FILTERS },
      },
    };
  });

  /* eslint-enable */
  // eslint-disable-next-line no-shadow
  store.on(SEARCH_PROJECTS_CLEANUP, ({ discover }, overrides) => {
    return {
      discover: {
        ...discover,
        keywords: "",
        offset: 0,
        projects: [],
        canLoadMore: false,
        isLoading: false,
        ...overrides,
      },
    };
  });
  /* eslint-disable */

  store.on(
    SET_ADVANCED_FILTERS,
    (
      { discover },
      {
        advancedFilters = { ...INITIAL_FILTERS },
        isFiRecommendationChecked = false,
      }
    ) => {
      return {
        discover: {
          ...discover,
          advancedFilters,
          isFiRecommendationChecked,
          isFiRecommendationCheckedAtLeastOnce:
            isFiRecommendationChecked ||
            discover.isFiRecommendationCheckedAtLeastOnce,
        },
      };
    }
  );

  store.on(SET_LOAD_MORE, ({ discover }, canLoadMore) => {
    return {
      discover: {
        ...discover,
        canLoadMore,
      },
    };
  });

  store.on(SET_DISCOVER_IS_LOADING, ({ discover }, isLoading) => {
    return {
      discover: {
        ...discover,
        isLoading,
      },
    };
  });
}
