import React, { useEffect, useRef, useState, useContext } from "react";
import "./header.scss";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useStoreon } from "storeon/react";
import { useAuth0 } from "@auth0/auth0-react";
import classNames from "classnames";
import textData from "../../../content/header.yaml";
import { ImageSignedUrl } from "../imageSignedUrl/imageSignedUrl";
import logo from "../../../assets/img/Master-Logo.svg";
import hamburger from "../../assets/img/hamburger.svg";
import bell from "../../../assets/img/bell.svg";
import bellHighlight from "../../../assets/img/bell-highlight.svg";
import circle from "../../../assets/img/michael-dam-m-ez-3-po-f-gs-k-unsplash.svg";
import orangeXIcon from "../../../assets/img/orange_close_icon.svg";
import transparentLogo from "../../../assets/img/logo.svg";
import { ReactComponent as ChevronRight } from "../../../assets/img/chevron-right-white.svg";
import { SIGN_OUT, CLEAN_CONCURRENT_LOGIN } from "../../core/store/auth";
import { RESET_IMAGE } from "../../../core/store/image";
import {
  SETTINGS_ROUTE_DASHBOARD,
  HOME_ROUTE,
  LOCAL_ROUTE,
  LOGIN_ROUTE,
  MY_FINDR_ROUTE,
  SETTINGS_ROUTE_ACCOUNT,
  SETTINGS_ROUTE_COMMUNICATIONS,
  SETTINGS_ROUTE_DETAILS,
  SETTINGS_ROUTE_PAYMENT,
  SIGNUP_ROUTE,
  SETTINGS_ROUTE,
  SETTINGS_ROUTE_MANAGE_TEAM,
  PROFILE_ROUTE,
  PROJECT_ROUTE,
  MY_FINDR_MATCHES_ROUTE,
} from "../../../config/routes";
import Modal from "../../../components/modal/modal";
import { SETTINGS_TAB } from "../../../core/store/settings";
import { GET_NOTIFICATIONS } from "../../../core/store/my-findr";
import { UITestContext } from "../../core/utils/contexts";

/* eslint-disable */
function Header() {
  const uiTest = useContext(UITestContext);
  const {
    auth: { isAuthenticated, isLoading, profile, onboardingProcess },
    myFindr: { notifications },
    dispatch,
  } = useStoreon("auth", "myFindr");
  const {
    project: { isOwner },
  } = useStoreon("project");
  const { dispatch: settingsDispatch } = useStoreon("settings");
  const [openSubmenu, setOpenSubmenu] = useState(false);
  const [openExploreSubmenu, setOpenExploreSubmenu] = useState(
    uiTest?.openExploreSubMenu || false
  );
  const [showExitModal, setShowExitModal] = useState(false);
  const submenuRef = useRef(null);
  const submenuExploreRef = useRef(null);

  const disableHeaderLinks = onboardingProcess || isLoading;

  const isMember = profile?.role === "MEMBER";
  const hasAccessToPayment =
    profile?.stripe?.planName?.indexOf("PAYG") === -1 && !isMember;
  const isEnterprise =
    profile?.stripe?.planName?.indexOf("ENTERPRISE") > -1 && !isMember;
  const isNotificationArrived = !!notifications?.toRead;

  useEffect(() => {
    const clickOutside = (event) => {
      if (submenuRef.current && !submenuRef.current.contains(event.target)) {
        setOpenSubmenu(false);
      }
    };
    window.addEventListener("click", clickOutside);
    return () => {
      window.removeEventListener("click", clickOutside);
    };
  }, [openSubmenu]);

  useEffect(() => {
    const clickExplorerOutside = (event) => {
      if (
        submenuExploreRef &&
        submenuExploreRef.current &&
        !submenuExploreRef.current.contains(event.target)
      ) {
        setOpenExploreSubmenu(false);
      }
    };
    const closeSubmenuOnScroll = () => {
      if (openExploreSubmenu) setOpenExploreSubmenu(false);
    };
    document.addEventListener("scroll", closeSubmenuOnScroll);
    window.addEventListener("click", clickExplorerOutside);
    return () => {
      window.removeEventListener("click", clickExplorerOutside);
      document.removeEventListener("scroll", closeSubmenuOnScroll);
    };
  }, [openExploreSubmenu]);

  useEffect(() => {
    if (isAuthenticated && !onboardingProcess && !isLoading) {
      dispatch(GET_NOTIFICATIONS);
    }
  }, [isAuthenticated, isLoading]);

  const location = useLocation();

  const { logout } = useAuth0();
  const logoutAction = () => {
    if (onboardingProcess) return;

    toggleExitModal();
    dispatch(CLEAN_CONCURRENT_LOGIN, () => {
      goHome();
      dispatch(SIGN_OUT);
      dispatch(RESET_IMAGE);
      logout({ returnTo: LOCAL_ROUTE + HOME_ROUTE });
    });
  };

  const [settingsActive, setSettingsActive] = useState(false);
  const toggleSettingsActive = (e) => {
    e.stopPropagation();
    setSettingsActive(!settingsActive);
  };

  const [popupActive, setPopupActive] = useState(false);
  const togglePopup = () => {
    if (!onboardingProcess) {
      setSettingsActive(false);
      setPopupActive(!popupActive);
    }
  };

  const toggleExitModal = () => {
    setShowExitModal(!showExitModal);
  };

  const toggleOpenSubmenu = () => {
    setOpenSubmenu(!openSubmenu);
  };

  const history = useHistory();

  const goHome = () => {
    if (onboardingProcess) return;

    history.push(HOME_ROUTE);
  };

  const setActiveTabWithLocation = (value) => {
    history.push(`${SETTINGS_ROUTE}${value}`);
    settingsDispatch(SETTINGS_TAB, value);
  };

  return (
    <>
      {showExitModal && (
        <Modal handleClose={toggleExitModal} className="SignOutModal">
          <div className="Title">{textData.signout.title}</div>

          <div className="Content">{textData.signout.content}</div>

          <div className="Buttons">
            <div className="Button Primary-Outline" onClick={logoutAction}>
              {textData.signout.yes}
            </div>
            <div className="Button" onClick={toggleExitModal}>
              {textData.signout.no}
            </div>
          </div>
        </Modal>
      )}
      <nav
        className={classNames(
          "HeaderWrapperV2",
          "NoSelect",
          !isAuthenticated && "UnLogged"
        )}
      >
        <div className="HeaderContainer">
          <div className="LogoContainerHeader">
            <img src={logo} className="LogoImage" alt="Logo" onClick={goHome} />
          </div>
          <div className="Menu">
            {!isAuthenticated && (
              <div className="Links">
                {textData.links.map((link, i) =>
                  link.data.type === "dropdown" ? (
                    <div
                      className="ExploreSubmenu"
                      key={link}
                      ref={submenuExploreRef}
                    >
                      <div
                        key={i}
                        className={`Link ${
                          uiTest?.activeLearnMenu ||
                          link.data?.items?.some((element) =>
                            location.pathname.includes(element.data.url)
                          )
                            ? "ActiveMenu"
                            : ""
                        } ${openExploreSubmenu ? "SubmenuActive" : ""}`}
                        onClick={() => {
                          setOpenExploreSubmenu((state) => !state);
                        }}
                      >
                        {link.data.title}
                        <div>
                          <ChevronRight className="exploreChevron" />
                        </div>
                      </div>
                      <div
                        className={`Submenu ${
                          openExploreSubmenu ? "SubmenuOpenned" : ""
                        }`}
                      >
                        <div>
                          {link.data?.items?.map((item, j) => (
                            <Link
                              key={j}
                              to={item.data.url}
                              className={
                                location.pathname.includes(item.data.url)
                                  ? "ActiveSubmenu"
                                  : ""
                              }
                              onClick={() => {
                                setOpenExploreSubmenu((state) => !state);
                              }}
                            >
                              {item.data.title}
                            </Link>
                          ))}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <Link
                      key={i}
                      className={`Link ${
                        location.pathname.indexOf(link.data.url) > -1
                          ? "Active"
                          : ""
                      }`}
                      to={link.data.url}
                    >
                      {link.data.title}
                    </Link>
                  )
                )}
              </div>
            )}

            {isAuthenticated && (
              <div className="Links">
                {textData.dashboardLinks.map((link, i) =>
                  link.data.type === "dropdown" ? (
                    <div
                      className={`ExploreSubmenu ${
                        disableHeaderLinks ? "Disabled" : ""
                      }`}
                      ref={submenuExploreRef}
                      key={i}
                    >
                      <div
                        key={i}
                        className={`Link ${
                          uiTest?.activeLearnMenu ||
                          link.data?.items?.some((element) =>
                            location.pathname.includes(element.data.url)
                          )
                            ? "ActiveMenu"
                            : ""
                        } ${openExploreSubmenu ? "SubmenuActive" : ""}`}
                        onClick={() => {
                          setOpenExploreSubmenu((state) => !state);
                        }}
                      >
                        {link.data.title}
                        <div>
                          <ChevronRight className="exploreChevron" />
                        </div>
                      </div>
                      <div
                        className={`Submenu ${
                          openExploreSubmenu ? "SubmenuOpenned" : ""
                        }`}
                      >
                        <div>
                          {link.data?.items?.map((item, j) => (
                            <Link
                              key={j}
                              to={item.data.url}
                              className={
                                location.pathname.includes(item.data.url)
                                  ? "ActiveSubmenu"
                                  : ""
                              }
                              onClick={() => {
                                setOpenExploreSubmenu((state) => !state);
                              }}
                            >
                              {item.data.title}
                            </Link>
                          ))}
                        </div>
                      </div>
                    </div>
                  ) : (
                    (!isMember ||
                      (isMember && link.data.title !== "Plans")) && (
                      <Link
                        key={i}
                        className={`Link ${
                          location.pathname === link.data.url ||
                          (link.data.url === "/discover" &&
                            location.pathname === MY_FINDR_MATCHES_ROUTE) ||
                          (link.data.url === "/discover" &&
                            location.pathname.includes(PROJECT_ROUTE) &&
                            !isOwner) ||
                          (link.data.url === "/profile" &&
                            location.pathname.includes(PROJECT_ROUTE) &&
                            isOwner) ||
                          (link.data.url === "/profile" &&
                            location.pathname.includes(PROFILE_ROUTE))
                            ? "Active"
                            : ""
                        } ${disableHeaderLinks ? "Disabled" : ""}`}
                        to={link.data.url}
                      >
                        {link.data.title}
                      </Link>
                    )
                  )
                )}
              </div>
            )}
            <div className="Buttons">
              {isAuthenticated && (
                <>
                  <Link
                    className={`${disableHeaderLinks ? "Disabled" : ""}`}
                    to={MY_FINDR_ROUTE}
                  >
                    <div
                      className={`NotificationBell ${
                        isNotificationArrived ? "NewArrived" : ""
                      }`}
                    >
                      <img
                        className={`Circle Bell2 ${
                          disableHeaderLinks ? "Disabled" : ""
                        }`}
                        src={isNotificationArrived ? bellHighlight : bell}
                        alt="Notifications"
                      />
                      {isNotificationArrived && (
                        <div className="NotificationBadge">
                          {notifications?.toRead}
                        </div>
                      )}
                    </div>
                  </Link>
                  <div
                    className={classNames(
                      "Circle",
                      "Avatar",
                      disableHeaderLinks && "Disabled"
                    )}
                    onClick={toggleOpenSubmenu}
                    ref={submenuRef}
                  >
                    <ImageSignedUrl
                      identifier={profile.id}
                      className="Circle Avatar"
                      src={profile.photo || circle}
                      title="Avatar"
                    />
                    <div
                      className={`ProfileSubmenu ${
                        openSubmenu ? "SubmenuOpenned" : ""
                      }`}
                    >
                      {!disableHeaderLinks && (
                        <Link
                          to={SETTINGS_ROUTE_DETAILS}
                          className={
                            location.pathname === SETTINGS_ROUTE_DETAILS
                              ? "ActiveSubmenu"
                              : ""
                          }
                          onClick={toggleOpenSubmenu}
                          data-testid="settings-link"
                        >
                          {textData.settings.title}
                        </Link>
                      )}
                      <a
                        href="#"
                        onClick={() => {
                          toggleOpenSubmenu();
                          toggleExitModal();
                        }}
                      >
                        {textData.signOut}
                      </a>
                    </div>
                  </div>
                </>
              )}
              {!isAuthenticated && (
                <>
                  <Link
                    className="Button Primary-Outline SignUp Small"
                    to={LOGIN_ROUTE}
                  >
                    {textData.login}
                  </Link>
                  <Link className="Button Small" to={SIGNUP_ROUTE}>
                    {textData.signUp}
                  </Link>
                </>
              )}
            </div>
            <div className="MobileCornerButtons">
              {isAuthenticated && (
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <Link
                    to={MY_FINDR_ROUTE}
                    className={`${disableHeaderLinks ? "Disabled" : ""}`}
                  >
                    <img
                      className={`Bell ${disableHeaderLinks ? "Disabled" : ""}`}
                      src={isNotificationArrived ? bellHighlight : bell}
                      alt="Bell"
                    />

                    {isNotificationArrived && (
                      <div className="NotificationBadge">
                        {notifications?.toRead}
                      </div>
                    )}
                  </Link>
                </div>
              )}
              <img
                className={`PopupIcon ${disableHeaderLinks ? "Disabled" : ""}`}
                src={hamburger}
                alt="Popup"
                onClick={togglePopup}
              />
            </div>
          </div>
        </div>
      </nav>

      {popupActive && (
        <div
          className={`PopupMenu ${isAuthenticated ? "Authenticated" : ""}`}
          onClick={togglePopup}
        >
          <div className="PopupMenuHeader">
            <img
              src={transparentLogo}
              className="TransparentLogo"
              alt="Logo"
              onClick={goHome}
            />

            <img
              src={orangeXIcon}
              alt="exitIcon"
              className="OrangeXIcon"
              onClick={togglePopup}
            />
          </div>
          {!isAuthenticated && (
            <div className="Links">
              {textData.links.map((link, i) =>
                link.data.type === "dropdown" ? (
                  <div
                    className="Actions ExploreSubmenuMovilContainer"
                    key={link}
                  >
                    <div
                      className={`Action NoSelect ${
                        openExploreSubmenu ? "Active" : ""
                      } ${
                        link.data?.items?.some((element) =>
                          location.pathname.includes(element.data.url)
                        )
                          ? "ActiveMovilSubmenu"
                          : ""
                      }`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenExploreSubmenu((state) => !state);
                      }}
                    >
                      <span className="ExploreTrigger">{link.data.title}</span>
                      <div>
                        <ChevronRight className="exploreChevron" />
                      </div>
                    </div>
                    {openExploreSubmenu && (
                      <div className="SettingsBox">
                        {link.data?.items?.map((item, j) => (
                          <Link
                            key={j}
                            to={item.data.url}
                            className={`MovilSubmenuItem ${
                              location.pathname.includes(item.data.url)
                                ? "ActiveMovilSubmenuItem"
                                : ""
                            }`}
                          >
                            {item.data.title}
                          </Link>
                        ))}
                      </div>
                    )}
                  </div>
                ) : (
                  <Link
                    key={i}
                    className={`Link ${
                      link.data.url === location.pathname ? "Active" : ""
                    }`}
                    to={link.data.url}
                  >
                    {link.data.title}
                  </Link>
                )
              )}
            </div>
          )}

          {isAuthenticated && (
            <div className="Links">
              {textData.dashboardLinks.map((link, i) =>
                link.data.type === "dropdown" ? (
                  <div
                    className="Actions ExploreSubmenuMovilContainer"
                    key={link}
                  >
                    <div
                      className={`Action NoSelect ${
                        openExploreSubmenu ? "Active" : ""
                      } ${
                        link.data?.items?.some((element) =>
                          location.pathname.includes(element.data.url)
                        )
                          ? "ActiveMovilSubmenu"
                          : ""
                      }`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenExploreSubmenu((state) => !state);
                      }}
                    >
                      <span className="ExploreTrigger">{link.data.title}</span>
                      <div>
                        <ChevronRight className="exploreChevron" />
                      </div>
                    </div>
                    {openExploreSubmenu && (
                      <div className="SettingsBox">
                        {link.data?.items?.map((item, j) => (
                          <Link
                            key={j}
                            to={item.data.url}
                            className={`MovilSubmenuItem ${
                              location.pathname.includes(item.data.url)
                                ? "ActiveMovilSubmenuItem"
                                : ""
                            }`}
                          >
                            {item.data.title}
                          </Link>
                        ))}
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="Link">
                    <Link
                      key={i}
                      className={`Link ${
                        location.pathname === link.data.url ||
                        (link.data.url === "/discover" &&
                          location.pathname === MY_FINDR_MATCHES_ROUTE) ||
                        (link.data.url === "/discover" &&
                          location.pathname.includes(PROJECT_ROUTE) &&
                          !isOwner) ||
                        (link.data.url === "/profile" &&
                          location.pathname.includes(PROJECT_ROUTE) &&
                          isOwner) ||
                        (link.data.url === "/profile" &&
                          location.pathname.includes(PROFILE_ROUTE))
                          ? "Active"
                          : ""
                      } ${disableHeaderLinks ? "Disabled" : ""}`}
                      to={link.data.url}
                    >
                      {link.data.title}
                    </Link>
                  </div>
                )
              )}
            </div>
          )}
          <div
            style={{
              height: 0,
              margin: "0 0 30px 0",
              opacity: 0.2,
              border: "solid 1px #ff7420",
            }}
          />
          {!isAuthenticated && (
            <div className="Buttons">
              <Link className="Button Small Primary-Outline" to={LOGIN_ROUTE}>
                {textData.login}
              </Link>
              <Link className="Button Small" to={SIGNUP_ROUTE}>
                {textData.signUp}
              </Link>
            </div>
          )}
          {isAuthenticated && (
            <div className="Actions ExploreSubmenuMovilContainer">
              <div
                className={`Action NoSelect ${settingsActive ? "Active" : ""} ${
                  [
                    SETTINGS_ROUTE_DETAILS,
                    SETTINGS_ROUTE_COMMUNICATIONS,
                    SETTINGS_ROUTE_ACCOUNT,
                    SETTINGS_ROUTE_PAYMENT,
                    SETTINGS_ROUTE_DASHBOARD,
                    SETTINGS_ROUTE_MANAGE_TEAM,
                  ].some((element) => element === location.pathname)
                    ? "selectedSettingMenu"
                    : ""
                }`}
                onClick={toggleSettingsActive}
              >
                <span>Settings</span>
                <div>
                  <ChevronRight className="exploreChevron" />
                </div>
              </div>
              {settingsActive && (
                <div className="SettingsBox">
                  <Link
                    to={SETTINGS_ROUTE_DETAILS}
                    onClick={() => {
                      setActiveTabWithLocation("details");
                    }}
                    className={`MovilSubmenuItem
                                            ${
                                              SETTINGS_ROUTE_DETAILS ===
                                              location.pathname
                                                ? "selectedItemSettingMenu"
                                                : ""
                                            }`}
                  >
                    {textData.myDetails}
                  </Link>
                  {hasAccessToPayment && (
                    <Link
                      to={SETTINGS_ROUTE_PAYMENT}
                      onClick={() => {
                        setActiveTabWithLocation("payment");
                      }}
                      className={`MovilSubmenuItem
                                                ${
                                                  SETTINGS_ROUTE_PAYMENT ===
                                                  location.pathname
                                                    ? "selectedItemSettingMenu"
                                                    : ""
                                                }`}
                    >
                      {textData.payments}
                    </Link>
                  )}
                  <Link
                    to={SETTINGS_ROUTE_COMMUNICATIONS}
                    onClick={() => {
                      setActiveTabWithLocation("communications");
                    }}
                    className={`MovilSubmenuItem
                                            ${
                                              SETTINGS_ROUTE_COMMUNICATIONS ===
                                              location.pathname
                                                ? "selectedItemSettingMenu"
                                                : ""
                                            }`}
                  >
                    {textData.communications}
                  </Link>
                  <Link
                    to={SETTINGS_ROUTE_ACCOUNT}
                    onClick={() => {
                      setActiveTabWithLocation("account");
                    }}
                    className={`MovilSubmenuItem
                                            ${
                                              SETTINGS_ROUTE_ACCOUNT ===
                                              location.pathname
                                                ? "selectedItemSettingMenu"
                                                : ""
                                            }`}
                  >
                    {textData.accountManagement}
                  </Link>
                  {isEnterprise && (
                    <>
                      <Link
                        to={SETTINGS_ROUTE_MANAGE_TEAM}
                        onClick={() => {
                          setActiveTabWithLocation("manageTeam");
                        }}
                        className={`MovilSubmenuItem
                                                ${
                                                  SETTINGS_ROUTE_MANAGE_TEAM ===
                                                  location.pathname
                                                    ? "selectedItemSettingMenu"
                                                    : ""
                                                }`}
                      >
                        {textData.manageTeam}
                      </Link>
                      <Link
                        to={SETTINGS_ROUTE_DASHBOARD}
                        onClick={() => {
                          setActiveTabWithLocation("dashboard");
                        }}
                        className={`MovilSubmenuItem
                                                ${
                                                  SETTINGS_ROUTE_DASHBOARD ===
                                                  location.pathname
                                                    ? "selectedItemSettingMenu"
                                                    : ""
                                                }`}
                      >
                        {textData.dashboard}
                      </Link>
                    </>
                  )}
                </div>
              )}
              <div className="Action NoSelect" onClick={toggleExitModal}>
                <span>Sign Out</span>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default Header;
