import axios from "axios";
import { PRIVACY_ENDPOINT } from "../../config/endpoints";
import { TOAST, TOAST_ERROR } from "./ui";

export const GET_PRIVACY = "GET_PRIVACY";
export const SET_PRIVACY = "SET_PRIVACY";
export const PRIVACY_SET_IS_LOADING = "PRIVACY_SET_IS_LOADING";

const INITIAL_STATE = {
  back_label: "",
  title: "",
  body: "",
  isLoading: true,
};

export function privacy(store) {
  store.on("@init", () => ({
    privacy: INITIAL_STATE,
  }));

  store.on(PRIVACY_SET_IS_LOADING, ({ privacy }, isLoading) => {
    return {
      privacy: {
        ...privacy,
        isLoading,
      },
    };
  });

  store.on(GET_PRIVACY, async function () {
    try {
      store.dispatch(PRIVACY_SET_IS_LOADING, true);

      const { data } = await axios(`${PRIVACY_ENDPOINT}`);

      store.dispatch(SET_PRIVACY, data);
    } catch (err) {
      store.dispatch(TOAST, {
        type: TOAST_ERROR,
        message: "Failed to load privacy",
      });
    } finally {
      store.dispatch(PRIVACY_SET_IS_LOADING, false);
    }
  });

  store.on(SET_PRIVACY, ({ privacy }, { back_label, title, body }) => {
    return {
      privacy: {
        ...privacy,
        back_label,
        title,
        body,
      },
    };
  });
}
