export const PRIVACY_PUBLIC = "Public";
export const PRIVACY_ONLY_ME = "OnlyMe";

export const REQUEST_ENUM = {
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  CONFIRM: "CONFIRM",
};

export const NOTIFICATION_ENUM = {
  READ: "READ",
  UNREAD: "UNREAD",
};
