import moment from "moment";
import momentTZ from "moment-timezone";

const countries = momentTZ.tz.countries();
const tz = countries.map((v) => momentTZ.tz.zonesForCountry(v, true));

// eslint-disable-next-line import/prefer-default-export
export const TIMEZONE_LIST = [];

export const getTimezone = (timezone) =>
  TIMEZONE_LIST.find((zone) => zone.name === timezone);

export const formatTimezone = (timezone, separator = "|") => {
  const label = `${timezone?.city}, ${timezone?.continent}`;
  return `${label}${separator}UTC ${timezone?.format}`;
};

export function getZoneFromFormattedString(timezone, separator = "|") {
  const [label, offset] = timezone.split(separator);
  const [city, continent] = label.split(", ");
  return TIMEZONE_LIST.find(
    (zone) =>
      zone.city === city &&
      zone.continent === continent &&
      offset.includes(zone.format)
  );
}

const getZoneId = (zone) => {
  const info = zone.name.split("/");
  return `${info[0]}/${info[info.length - 1]}`;
};

tz.forEach((country) =>
  country.forEach((zone) => {
    const sign = zone.offset <= 0 ? "+" : "-";
    const format =
      zone.offset === 0
        ? "00:00"
        : moment()
            .startOf("day")
            .add(Math.abs(zone.offset), "minutes")
            .format("HH:mm");
    const zoneInfo = zone.name.split("/");
    if (
      !TIMEZONE_LIST.find((timezone) => getZoneId(zone) === getZoneId(timezone))
    ) {
      TIMEZONE_LIST.push({
        continent: zoneInfo[0],
        city: zoneInfo[zoneInfo.length - 1].replace(/_/g, " "),
        ...zone,
        format: `${sign}${format}`,
        name: zone.name,
      });
    }
  })
);
