export const FIREBASE_SET_INITIALIZED = "FIREBASE_SET_INITIALIZED";
export const USER_SET_ACTION = "USER_SET_ACTION";

export function firebase(store) {
  store.on("@init", () => ({
    firebase: {
      isInitialized: false,
      hasUserAction: false,
    },
  }));

  store.on(FIREBASE_SET_INITIALIZED, ({ firebase }, isInitialized = true) => {
    return {
      firebase: {
        ...firebase,
        isInitialized,
      },
    };
  });

  store.on(USER_SET_ACTION, ({ firebase }, hasUserAction = true) => {
    return {
      firebase: {
        ...firebase,
        hasUserAction,
      },
    };
  });
}
