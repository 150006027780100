import React from "react";
import classNames from "classnames";
import Modal from "../../../components/modal/modal";
import textData from "../../content/auth.yaml";
import "./currently-on-holiday-modal.scss";

export default function CurrentlyOnHolidayModal({
  open = true,
  onClose,
  onContinueHoliday,
  onEndInstantly,
}) {
  return (
    <div className={classNames("CurrentlyOnHolidayModal", !open && "Hidden")}>
      <Modal handleClose={onClose}>
        <div className="Title">{textData.currentlyOnHolidayModal.title}</div>
        <div className="Note">{textData.currentlyOnHolidayModal.content}</div>
        <div className="Buttons">
          <button
            type="button"
            className="Button Primary-Outline"
            onClick={onContinueHoliday}
          >
            {textData.currentlyOnHolidayModal.actions.secondary}
          </button>
          <button
            type="button"
            className="Button Primary"
            onClick={onEndInstantly}
          >
            {textData.currentlyOnHolidayModal.actions.primary}
          </button>
        </div>
      </Modal>
    </div>
  );
}
