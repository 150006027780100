import {
  GetRequest,
  PatchRequest,
  PostRequest,
  PutRequest,
} from "../api/api-request";
import {
  PROJECT_ENDPOINT,
  REQUEST_ENDPOINT,
  BUCKET_ENDPOINT,
  BUCKET_DOC_ENDPOINT,
} from "../../config/endpoints";
import { TOAST, TOAST_ERROR, TOAST_SUCCESS, TOAST_INFO } from "./ui";
import {
  NOT_FOUND,
  PROFILE_ROUTE,
  PROJECT_DETAILS_ROUTE,
} from "../../config/routes";
import { generateProjectFormFields } from "../utils/requestFixers";
import { PRIVACY_ONLY_ME } from "../../config/enums";
import { SET_PROJECTS } from "./discover";
import textData from "../../content/project.yaml";

export const LOAD_PROJECT = "LOAD_PROJECT";
export const CREATE_PROJECT = "CREATE_PROJECT";
export const CREATE_PROJECT_WITH_IMAGE = "CREATE_PROJECT_WITH_IMAGE";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const UPDATE_PROJECT_WITH_IMAGE = "UPDATE_PROJECT_WITH_IMAGE";
export const CLOSE_PROJECT = "CLOSE_PROJECT";
export const REQUEST_DISCOVERY_CALL = "REQUEST_DISCOVERY_CALL";
export const UPLOAD_FILE = "UPLOAD_FILE";
export const CLEAR_PROJECT = "CLEAR_PROJECT";
export const SET_PROJECT = "SET_PROJECT";
export const SET_LOADING_PROJECT = "SET_LOADING_PROJECT";
export const SET_OWNER_PROJECT = "SET_OWNER_PROJECT";
export const SET_DOCSSTATE = "SET_DOCSSTATE";
export const SET_DOC = "SET_DOC";
export const SET_DOCSPROGRESS = "SET_DOCSPROGRESS";

export const MODE_VIEW = "MODE_VIEW";
export const MODE_PREVIEW = "MODE_PREVIEW";
export const MODE_CREATE = "MODE_CREATE";
export const MODE_UPDATE = "MODE_UPDATE";

export const PROJECT_INITIAL_STATE = {
  objective: "Not public",
  businessIndustry: "Not public",
  partnerType: "Not public",
  solutionType: "Not public",
  stage: "Not public",
  revenue: "Not public",
  size: "Not public",
  countries: "Not public",
  markets: "Not public",
  partnerStage: "Not public",
  solution: "Not public",
  partnerSize: "Not public",
  partnerMarkets: "Not public",
  partnerCountries: "Not public",
  timing: "Not public",
  exclusion: "Not public",
  partnerRevenue: "Not public",
  budget: "Not public",
  projectOutcome: "Not public",
  description: "Not public",
};

export const INITIAL_STATE = {
  objective: "",
  businessIndustry: "",
  partnerType: "",
  solutionType: "",
  stage: "",
  revenue: "",
  size: "",
  countries: "",
  markets: "",
  partnerStage: "",
  solution: "",
  partnerSize: "",
  partnerMarkets: "",
  partnerCountries: "",
  timing: "",
  exclusion: "",
  partnerRevenue: "",
  budget: "",
  projectOutcome: "",
  description: "",
  docs: [],
  image: "",
  privacy: {
    objective: "Public",
    businessIndustry: "Public",
    partnerType: "Public",
    solutionType: "Public",
    stage: "Public",
    revenue: "Public",
    size: "Public",
    markets: "Public",
    solution: "Public",
    partnerStage: "Public",
    partnerSize: "Public",
    partnerMarkets: "Public",
    timing: "Public",
    exclusion: "Public",
    partnerRevenue: "Public",
    budget: "Public",
    projectOutcome: "Public",
    description: "Public",
    countries: "Public",
    partnerCountries: "Public",
  },
};

const DOCUMENTS_INITIAL_STATE = {
  docsState: [
    { state: "empty", message: "" },
    { state: "empty", message: "" },
    { state: "empty", message: "" },
  ],
  docs: ["", "", ""],
  docsProgress: [0, 0, 0],
};

export function project(store) {
  store.on("@init", () => {
    return {
      project: {
        project: INITIAL_STATE,
        documents: DOCUMENTS_INITIAL_STATE,
        isLoading: false,
        isOwner: true,
      },
    };
  });

  // Load methods
  store.on(
    LOAD_PROJECT,
    async (
      { auth: { accessToken, profile } },
      { projectId, preview, history }
    ) => {
      try {
        cleanInputsFileUpload();

        store.dispatch(SET_LOADING_PROJECT, true);
        store.dispatch(SET_PROJECT, INITIAL_STATE);

        const { data: project } = await GetRequest(
          `${PROJECT_ENDPOINT}/${projectId}`,
          accessToken
        );

        if (preview && project && project.privacy) {
          Object.entries(project.privacy).forEach(([key, value]) => {
            value === PRIVACY_ONLY_ME && delete project[key];
          });
        }

        const isOwner = project.ownerId === profile.id;

        const finalProject = {
          ...(!isOwner || preview ? PROJECT_INITIAL_STATE : INITIAL_STATE),
          ...project,
        };

        store.dispatch(SET_PROJECT, finalProject);
        store.dispatch(SET_OWNER_PROJECT, isOwner);
      } catch (e) {
        if (e?.response?.status === 404) {
          store.dispatch(SET_PROJECTS, { projects: [], loadMore: false });
          history.replace(NOT_FOUND);
        } else {
          store.dispatch(TOAST, { type: TOAST_ERROR, message: e });
        }
      } finally {
        store.dispatch(SET_LOADING_PROJECT, false);
      }
    }
  );

  // Set methods
  store.on(SET_PROJECT, ({ project }, newProject) => {
    if (newProject.docs && newProject.docs.length) {
      let size = newProject.docs.length;
      for (let index = 0; index < size; index++) {
        store.dispatch(SET_DOCSSTATE, {
          state: { state: "success", message: "Success" },
          indexDocument: index,
        });
        store.dispatch(SET_DOC, {
          doc: newProject.docs[index],
          indexDocument: index,
        });
        store.dispatch(SET_DOCSPROGRESS, {
          progress: 0,
          indexDocument: index,
        });
      }
    }

    return {
      project: {
        ...project,
        project: {
          ...INITIAL_STATE,
          ...newProject,
        },
        documents: {
          ...store.get().project.documents,
        },
      },
    };
  });

  store.on(SET_LOADING_PROJECT, ({ project }, isLoading) => {
    return {
      project: {
        ...project,
        isLoading,
      },
    };
  });

  store.on(SET_OWNER_PROJECT, ({ project }, isOwner) => {
    return {
      project: {
        ...project,
        isOwner,
      },
    };
  });

  store.on(SET_DOCSSTATE, ({ project }, { state, indexDocument }) => {
    let docsState = [...project.documents.docsState];
    docsState[indexDocument] = { ...state };

    return {
      project: {
        ...project,
        documents: {
          ...project.documents,
          docsState: [...docsState],
        },
      },
    };
  });

  store.on(SET_DOC, ({ project }, { doc, indexDocument }) => {
    let docs = [...project.documents.docs];
    docs[indexDocument] = doc;

    return {
      project: {
        ...project,
        documents: {
          ...project.documents,
          docs: [...docs],
        },
      },
    };
  });

  store.on(SET_DOCSPROGRESS, ({ project }, { progress, indexDocument }) => {
    let docsProgress = [...project.documents.docsProgress];
    docsProgress[indexDocument] = progress;

    return {
      project: {
        ...project,
        documents: {
          ...project.documents,
          docsProgress: [...docsProgress],
        },
      },
    };
  });

  // Mutate methods
  store.on(CLEAR_PROJECT, () => {
    return {
      project: {
        project: INITIAL_STATE,
        documents: DOCUMENTS_INITIAL_STATE,
        isLoading: false,
        isOwner: true,
      },
    };
  });

  store.on(CREATE_PROJECT, async ({ auth }, { project, history }) => {
    try {
      store.dispatch(SET_LOADING_PROJECT, true);
      const projectPayload = generateProjectFormFields(project);

      if (projectPayload.image === "") {
        delete projectPayload.image;
      }
      await PostRequest(
        `${PROJECT_ENDPOINT}`,
        projectPayload,
        auth.accessToken
      );

      history.push(PROFILE_ROUTE);

      store.dispatch(TOAST, {
        type: TOAST_SUCCESS,
        message: "Project created successfully",
      });

      // clean inputs File upload
      cleanInputsFileUpload();
    } catch (e) {
      store.dispatch(TOAST, { type: TOAST_ERROR, message: e, error: e });
    } finally {
      store.dispatch(SET_LOADING_PROJECT, false);
    }
  });

  store.on(
    CREATE_PROJECT_WITH_IMAGE,
    async (
      { auth: { accessToken } },
      { project: { logoData, ...data }, history }
    ) => {
      // notify user
      store.dispatch(TOAST, {
        type: TOAST_INFO,
        message: "Uploading image...",
      });

      // to be send to the server
      let getLogoUrl;
      // to upload image
      let putLogoUrl;

      // then try to get the company logo url endpoints
      if (logoData) {
        try {
          const { logoFile, ...imageConfig } = logoData;
          const res = await PostRequest(
            `${BUCKET_ENDPOINT}`,
            imageConfig,
            accessToken
          );
          // update variables
          getLogoUrl = res.data.get;
          putLogoUrl = res.data.put;
        } catch (e) {
          store.dispatch(TOAST, {
            type: TOAST_ERROR,
            message: textData.errors.logo,
          });
        }
      }

      // upload images
      try {
        if (logoData) {
          await PutRequest(putLogoUrl, logoData.logoFile, {
            headers: {
              "Content-Type": logoData.contentType,
            },
          });
        }
        const updatedProject = {
          ...data,
          image: logoData ? getLogoUrl : data.projectLogo,
        };
        store.dispatch(CREATE_PROJECT, {
          project: updatedProject,
          history,
        });
      } catch (e) {
        store.dispatch(TOAST, {
          type: TOAST_ERROR,
          message: textData.errors.image,
        });
      }
    }
  );

  store.on(
    UPDATE_PROJECT_WITH_IMAGE,
    async (
      { auth: { accessToken } },
      { project: { logoData, ...data }, history }
    ) => {
      // notify user
      store.dispatch(TOAST, {
        type: TOAST_INFO,
        message: "Uploading image...",
      });

      // to be send to the server
      let getLogoUrl;
      // to upload image
      let putLogoUrl;

      // then try to get the company logo url endpoints
      if (logoData) {
        try {
          const { logoFile, ...imageConfig } = logoData;
          const res = await PostRequest(
            `${BUCKET_ENDPOINT}`,
            imageConfig,
            accessToken
          );
          // update variables
          getLogoUrl = res.data.get;
          putLogoUrl = res.data.put;
        } catch (e) {
          store.dispatch(TOAST, {
            type: TOAST_ERROR,
            message: textData.errors.logo,
          });
        }
      }

      // upload images
      try {
        if (logoData) {
          await PutRequest(putLogoUrl, logoData.logoFile, {
            headers: {
              "Content-Type": logoData.contentType,
            },
          });
        }
        const updatedProject = {
          ...data,
          image: logoData ? getLogoUrl : data.projectLogo,
        };
        store.dispatch(UPDATE_PROJECT, {
          project: updatedProject,
          history,
        });
      } catch (e) {
        store.dispatch(TOAST, {
          type: TOAST_ERROR,
          message: textData.errors.image,
        });
      }
    }
  );

  store.on(UPDATE_PROJECT, async ({ auth }, { project, history }) => {
    try {
      const projectPayload = generateProjectFormFields({ ...project });

      if (projectPayload.image === "") {
        delete projectPayload.image;
      }
      await PatchRequest(
        `${PROJECT_ENDPOINT}/${project.id}`,
        projectPayload,
        auth.accessToken
      );

      store.dispatch(TOAST, {
        type: TOAST_SUCCESS,
        message: "Project saved successfully",
      });

      cleanInputsFileUpload();

      history.push(`${PROJECT_DETAILS_ROUTE}/${project.id}`);
    } catch (e) {
      store.dispatch(TOAST, { type: TOAST_ERROR, message: e, error: e });
    }
  });

  store.on(CLOSE_PROJECT, async ({ auth }, { projectId, callback }) => {
    try {
      await PatchRequest(
        `${PROJECT_ENDPOINT}/${projectId}/close`,
        { id: projectId },
        auth.accessToken
      );
      store.dispatch(TOAST, {
        type: TOAST_SUCCESS,
        message: "Project closed successfully",
      });

      callback();
    } catch (e) {
      store.dispatch(TOAST, { type: TOAST_ERROR, message: e, error: e });
    }
  });

  store.on(
    REQUEST_DISCOVERY_CALL,
    async ({ auth }, { project, toggleModal }) => {
      try {
        await PostRequest(
          `${REQUEST_ENDPOINT}`,
          { projectId: project.id },
          auth.accessToken
        );

        toggleModal();
      } catch (e) {
        store.dispatch(TOAST, { type: TOAST_ERROR, message: e, error: e });
      }
    }
  );

  store.on(
    UPLOAD_FILE,
    async ({ auth: { accessToken } }, { document: { fileData, ...data } }) => {
      try {
        const file = fileData?.file;
        delete fileData.file;
        // get signedUrl
        const {
          data: { get, put },
        } = await PostRequest(`${BUCKET_DOC_ENDPOINT}`, fileData, accessToken);
        // init local variable
        store.dispatch(SET_DOCSSTATE, {
          state: { state: "uploading", message: "Uploading" },
          indexDocument: data.indexDocument,
        });
        // upload file
        await PutRequest(put, file, {
          headers: {
            "Content-Type": fileData.contentType,
          },
          onUploadProgress: (progressEvent) => {
            let progress = (progressEvent.loaded / progressEvent.total) * 100;
            store.dispatch(SET_DOCSPROGRESS, {
              progress: progress,
              indexDocument: data.indexDocument,
            });
          },
        });
        // update local variable
        store.dispatch(SET_DOCSSTATE, {
          state: { state: "success", message: "Success" },
          indexDocument: data.indexDocument,
        });

        store.dispatch(SET_DOC, {
          doc: get,
          indexDocument: data.indexDocument,
        });
      } catch (e) {
        console.log("Error uploading file. Please try again", e);
        if (e.response) {
          store.dispatch(SET_DOCSSTATE, {
            state: { state: "error", message: "Error uploading file." },
            indexDocument: data.indexDocument,
          });
        } else {
          store.dispatch(SET_DOCSSTATE, {
            state: { state: "error", message: "Connection Error" },
            indexDocument: data.indexDocument,
          });
        }
      }
    }
  );

  const cleanInputsFileUpload = () => {
    let size = store.get().project.documents.docs.length;
    for (let index = 0; index < size; index++) {
      store.dispatch(SET_DOCSSTATE, {
        state: { state: "empty", message: "" },
        indexDocument: index,
      });
      store.dispatch(SET_DOC, { doc: "", indexDocument: index });
      store.dispatch(SET_DOCSPROGRESS, {
        progress: 0,
        indexDocument: index,
      });
    }
  };
}
