import { GetRequest, PatchRequest } from "../api/api-request";
import { MY_FINDR_MATCHES_ENDPOINT } from "../../config/endpoints";
import { TOAST, TOAST_ERROR, TOAST_SUCCESS } from "./ui";

export const LOAD_MY_FINDR_MATCHES = "LOAD_MY_FINDR_MATCHES";
export const REMOVE_MY_FINDR_MATCH = "REMOVE_MY_FINDR_MATCH";
export const SET_REMOVE_MATCH = "SET_REMOVE_MATCH";
export const SET_MY_FINDR_MATCHES = "SET_MY_FINDR_MATCHES";
export const SET_LOAD_MORE_MATCHES = "SET_LOAD_MORE_MATCHES";
export const SET_MY_FINDR_MATCHES_IS_LOADING =
  "SET_MY_FINDR_MATCHES_IS_LOADING";
export const MY_FINDR_MATCHES_RESTART = "MY_FINDR_MATCHES_RESTART";

export function myFindrMatches(store) {
  const limit = 12;

  store.on("@init", () => ({
    myFindrMatches: {
      offset: 0,
      projects: [],
      canLoadMore: true,
      isLoading: false,
      init: true,
    },
  }));

  store.on(
    LOAD_MY_FINDR_MATCHES,
    async ({ myFindrMatches, auth }, { loadMore = false }) => {
      try {
        store.dispatch(SET_MY_FINDR_MATCHES_IS_LOADING, true);

        const response = await GetRequest(
          `${MY_FINDR_MATCHES_ENDPOINT}?offset=${myFindrMatches.offset}&limit=${limit}&excludeDeletedMatches=true`,
          auth.accessToken
        );

        store.dispatch(SET_MY_FINDR_MATCHES, {
          projects: response.data,
          loadMore,
          init: false,
        });

        store.dispatch(SET_LOAD_MORE_MATCHES, response.data.length === limit);
      } catch (e) {
        store.dispatch(SET_LOAD_MORE_MATCHES, false);
      } finally {
        store.dispatch(SET_MY_FINDR_MATCHES_IS_LOADING, false);
      }
    }
  );

  store.on(REMOVE_MY_FINDR_MATCH, async ({ auth }, { id }) => {
    try {
      await PatchRequest(
        `${MY_FINDR_MATCHES_ENDPOINT}/${id}`,
        {},
        auth.accessToken
      );

      store.dispatch(SET_REMOVE_MATCH, {
        id: id,
      });

      store.dispatch(TOAST, {
        type: TOAST_SUCCESS,
        message: "Removed",
      });
    } catch (e) {
      store.dispatch(TOAST, {
        type: TOAST_ERROR,
        message: e,
        error: e,
      });
    }
  });

  store.on(SET_REMOVE_MATCH, ({ myFindrMatches }, { id }) => {
    const index = myFindrMatches.projects.findIndex((i) => i.id === id);
    const modifiedProjects = [...myFindrMatches.projects];

    if (index !== -1) {
      modifiedProjects.splice(index, 1);
    }

    return {
      myFindrMatches: {
        ...myFindrMatches,
        projects: [...modifiedProjects],
      },
    };
  });

  store.on(
    SET_MY_FINDR_MATCHES,
    ({ myFindrMatches }, { projects, loadMore = false, init = false }) => {
      return {
        myFindrMatches: {
          ...myFindrMatches,
          projects: !loadMore
            ? projects
            : [...myFindrMatches.projects, ...projects],
          offset: init
            ? limit
            : loadMore
            ? myFindrMatches.offset + projects.length
            : projects.length,
          init,
        },
      };
    }
  );

  store.on(SET_LOAD_MORE_MATCHES, ({ myFindrMatches }, canLoadMore) => {
    return {
      myFindrMatches: {
        ...myFindrMatches,
        canLoadMore,
      },
    };
  });

  store.on(SET_MY_FINDR_MATCHES_IS_LOADING, ({ myFindrMatches }, isLoading) => {
    return {
      myFindrMatches: {
        ...myFindrMatches,
        isLoading,
      },
    };
  });

  store.on(MY_FINDR_MATCHES_RESTART, () => {
    return {
      myFindrMatches: {
        offset: 0,
        projects: [],
        canLoadMore: true,
        isLoading: false,
        init: true,
      },
    };
  });
}
