import axios from "axios";
import { SET_USER_DISABLED } from "../store/auth";

export const axiosResponseInterceptor = (store) => {
  axios.interceptors.response.use(
    (next) => Promise.resolve(next),
    (error) => {
      // firefox aborting requests while navigating causes this axios error which is to be handled silently
      if (error.code === "ECONNABORTED") {
        console.log("silent axios error", error);
        return new Promise(() => {});
      }
      if (
        error.response?.status === 403 &&
        error.response?.data?.message === "user disabled"
      ) {
        store.dispatch(SET_USER_DISABLED, true);

        return Promise.reject({ message: "Your account is disabled" });
      }

      return Promise.reject(error);
    }
  );
};
