import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useStoreon } from "storeon/react";
import { useAuth0 } from "@auth0/auth0-react";
import { useIdleTimer } from "react-idle-timer";
import {
  CONFIRM_PLAN_ROUTE,
  CREATE_PROFILE_ROUTE,
  HOME_ROUTE,
  ONBOARDING_ROUTE,
  PLANS_ROUTE,
  PRIVACY_ROUTE,
  TERMS_ROUTE,
  LOCAL_ROUTE,
  PAYMENT_3DS_RETURN_ROUTE,
} from "../../config/routes";
import {
  LOGIN_OR_SIGNUP,
  SIGN_OUT,
  VALIDATE_CONCURRENT_LOGIN,
  CLEAN_CONCURRENT_LOGIN,
} from "../store/auth";
import { RESET_IMAGE } from "../store/image";
import jwt_decode from "jwt-decode";
import moment from "moment";

const Auth = ({ children }) => {
  const { dispatch, auth } = useStoreon("auth");
  const location = useLocation();
  const history = useHistory();
  const query = (() => new URLSearchParams(location.search))();

  // These routes are allowed to be visited when the user is logged
  // without completing the onboarding process
  const allowedRoutes = [PRIVACY_ROUTE, TERMS_ROUTE];

  const {
    isAuthenticated,
    getAccessTokenSilently,
    user: userInfo,
    getIdTokenClaims,
    userExists,
    logout,
  } = useAuth0();

  const [definingRoute, setDefiningRoute] = useState(true);

  useEffect(() => {
    if (
      location.pathname === HOME_ROUTE ||
      location.pathname === PAYMENT_3DS_RETURN_ROUTE
    ) {
      setDefiningRoute(false);
      return;
    }

    const selectedPlan = localStorage.getItem(
      `${auth.userInfo.sub}-selectedPlan`
    );
    const planName = auth.profile?.stripe?.planName;
    const userIsCreated = !!auth.profile?.firstName;
    const isFindrTeam = !!auth.profile?.isFindrTeam;
    const isMember = auth.profile?.role === "MEMBER";

    if (
      auth.onboardingProcess &&
      auth.isAuthenticated &&
      !auth.profile?.onBoarding?.completed
    ) {
      setDefiningRoute(true);

      if (allowedRoutes.includes(location.pathname)) {
        setDefiningRoute(false);
        return;
      }

      if (
        location.pathname === CREATE_PROFILE_ROUTE &&
        !userIsCreated &&
        selectedPlan
      ) {
        setDefiningRoute(false);
        return;
      }

      if (
        location.pathname === CONFIRM_PLAN_ROUTE &&
        userIsCreated &&
        planName === "PAYG" &&
        selectedPlan &&
        selectedPlan !== "PAYG"
      ) {
        setDefiningRoute(false);
        return;
      }

      if (
        location.pathname === ONBOARDING_ROUTE &&
        userIsCreated &&
        planName &&
        (isMember || isFindrTeam) &&
        auth.profile.termsAccepted
      ) {
        setDefiningRoute(false);
        return;
      }

      if (userIsCreated && planName) {
        let route = ONBOARDING_ROUTE;

        if ((isMember || isFindrTeam) && !auth.profile.termsAccepted) {
          route = CREATE_PROFILE_ROUTE;
        }

        history.push(route);
        setDefiningRoute(false);
        return;
      }

      localStorage.removeItem(`${auth.userInfo.sub}-selectedPlan`);
      history.push(PLANS_ROUTE);
    }

    setDefiningRoute(false);
  }, []);

  useEffect(() => {
    (async () => {
      if (location.pathname === HOME_ROUTE) {
        const shareContact = query.get("shareContact");

        if (shareContact) {
          return;
        }

        if (["local", "develop", "staging"].includes(process.env.STAGE)) {
          const storeon = localStorage.getItem("storeon");
          const data = JSON.parse(storeon);
          const accessToken = data?.auth?.accessToken;
          const isAuth = data?.auth?.isAuthenticated;
          const userInfo = data?.auth?.userInfo;
          const e2eMode =
            userInfo && userInfo.sub && userInfo.sub.includes("e2e");

          if (isAuth && e2eMode) {
            dispatch(LOGIN_OR_SIGNUP, { userInfo, accessToken, history });
            return;
          }
        }

        if (isAuthenticated) {
          const accessToken = await getAccessTokenSilently();
          const claims = await getIdTokenClaims();
          userInfo.id_token = claims.__raw;

          dispatch(VALIDATE_CONCURRENT_LOGIN, {
            userInfo,
            sid: claims.sid,
            accessToken,
            history,
          });
        }
      }
    })();
  }, [isAuthenticated, userExists]);

  const [tokenCheck, setTokenCheck] = useState(null);

  useEffect(() => {
    if (auth.accessToken) {
      tokenCheck && clearInterval(tokenCheck);
      const decoded = jwt_decode(auth.accessToken);

      setTokenCheck(
        setInterval(() => {
          if (moment(decoded.exp * 1000).diff(moment(), "seconds") < 0) {
            sigout();
            clearInterval(tokenCheck);
          }
        }, 10000)
      );

      if (moment(decoded.exp * 1000).diff(moment(), "seconds") < 0) {
        sigout();
      }
    }
  }, [auth.accessToken]);

  useIdleTimer({
    timeout: 900000,
    StopOnIdle: true,
    onIdle: () => sigout(),
  });

  useEffect(() => {
    auth.isConcurrentUserLogin && sigout();
  }, [auth.isConcurrentUserLogin]);

  const sigout = () => {
    if (auth.isAuthenticated) {
      dispatch(CLEAN_CONCURRENT_LOGIN, () => {
        dispatch(SIGN_OUT);
        dispatch(RESET_IMAGE);
        logout({ returnTo: LOCAL_ROUTE + HOME_ROUTE });
      });
    }
  };

  return definingRoute ? <></> : children;
};

export default Auth;
