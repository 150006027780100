export const HOME_ROUTE = "/";
export const LOGIN_ROUTE = "/login";
export const INVITED_ROUTE = "/invited/";
export const SIGNUP_ROUTE = "/signup";
export const CREATE_PROFILE_ROUTE = "/create-profile";
export const ONBOARDING_ROUTE = "/onboarding";
export const HOW_IT_WORKS_ROUTE = "/how-it-works";
export const DISCOVER_ROUTE = "/discover";
export const PROFILE_ROUTE = "/profile";
export const PROFILE_ROUTE_ID = "/profile/:id";
export const SETTINGS_ROUTE = "/settings/";
export const SETTINGS_ROUTE_TAB = "/settings/:tab";
export const SETTINGS_ROUTE_DETAILS = "/settings/details";
export const SETTINGS_ROUTE_COMMUNICATIONS = "/settings/communications";
export const SETTINGS_ROUTE_ACCOUNT = "/settings/account";
export const SETTINGS_ROUTE_PAYMENT = "/settings/payment";
export const SETTINGS_ROUTE_DASHBOARD = "/settings/dashboard";
export const SETTINGS_ROUTE_MANAGE_TEAM = "/settings/manageTeam";
export const PROJECT_ROUTE = "/project";
export const PROJECT_DETAILS_ROUTE = `${PROJECT_ROUTE}/details`;
export const PROJECT_CREATE_ROUTE = `${PROJECT_ROUTE}/create`;
export const PROJECT_UPDATE_ROUTE = `${PROJECT_ROUTE}/update`;
export const PROJECT_PREVIEW_ROUTE = `${PROJECT_ROUTE}/preview`;
export const PROJECT_ROUTE_VIEW = "/project/details/:id";
export const PROJECT_ROUTE_PREVIEW = "/project/preview/:id";
export const PROJECT_ROUTE_CREATE = "/project/create";
export const PROJECT_ROUTE_UPDATE = "/project/update/:id";
export const PAYMENT_ROUTE = "/payment";
export const RECEIVED_ROUTE = "/received";
export const MY_FINDR_ROUTE = "/my-findr";
export const MY_FINDR_MATCHES_ROUTE = "/my-findr-matches";
export const DISCOVERY_CALL_ROUTE = "/discovery-call";
export const DISCOVERY_SECURED_ROUTE = "/discovery-secured";
export const TERMS_ROUTE = "/terms-and-conditions";
export const PRIVACY_ROUTE = "/privacy-policy";
export const COOKIES_ROUTE = "/cookies-policy";
export const FAQS_ROUTE = "/faqs";
export const PLANS_ROUTE = "/plan";
export const CONFIRM_PLAN_ROUTE = "/plan-confirm";
export const REASONS_ROUTE = "/reasons";
export const ENTERPRISE_BOARDING_ROUTE = "/enterprise-boarding";
export const ENTERPRISE_ADD_LICENSE_ROUTE = "/enterprise-add-license";
export const ENTERPRISE_INVITE_USERS_ROUTE = "/enterprise-invite-users";
export const BLOG_POST_ROUTE = "/blog/post/:id";
export const BLOG_ROUTE = "/blog";
export const BLOG_DETAIL_ROUTE = "/blog/:blogId";
export const PODCASTS_ROUTE = "/podcasts";
export const CASE_STUDIES_ROUTE = "/case-studies";
export const CASE_STUDIES_DETAIL_ROUTE = "/case-studies/case/:id";
export const UNSUBSCRIBE_STATUS_ROUTE = "/unsubscribe";
export const UNSUBSCRIBE_SUCCESSFUL_STATUS_ROUTE = "/unsubscribe/successful";
export const NOT_FOUND = "/not-found";
export const PAYMENT_3DS_RETURN_ROUTE = "/payment-3ds-return";
export const LOCAL_ROUTE =
  process.env.NODE_ENV === "development"
    ? process.env.LOCAL_ROUTE || "http://localhost:3000"
    : process.env.SITE_URL;
