import React, { useContext, useEffect, useState } from "react";
import { useStoreon } from "storeon/react";
import { REFRESH_IMAGE } from "../../../core/store/image";
import { UITestContext } from "../../core/utils/contexts";
import keyHandler from "../../core/utils/key-handler";

export function ImageSignedUrl({
  identifier,
  src = "",
  className = "",
  title = "",
  onClick,
  style = {},
  alt,
  rawSrcForTest,
}) {
  const {
    dispatch,
    image: { preload },
  } = useStoreon("image");

  const uiTest = useContext(UITestContext);

  const ownId =
    (/https*:\/\//.test(src) && src.split("?")[0]?.split("/")?.reverse()[0]) ||
    identifier;

  const uiTestLocal = uiTest && uiTest[identifier || ownId];

  const [currentSrc, setCurrentSrc] = useState(
    uiTestLocal?.src || (uiTest && rawSrcForTest) || preload[ownId]
  );
  const [currentIdentifier, setCurrentIdentifier] = useState(ownId);

  useEffect(() => {
    setCurrentIdentifier(ownId);
    dispatch(REFRESH_IMAGE, { id: ownId, path: src });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownId, src]);

  useEffect(() => {
    if (preload[currentIdentifier] && preload[currentIdentifier] !== currentSrc)
      setCurrentSrc(uiTestLocal?.src || preload[currentIdentifier]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preload[currentIdentifier]]);

  const handlerOnClick = () => typeof onClick === "function" && onClick();

  return currentSrc ? (
    <img
      id={currentIdentifier}
      key={currentIdentifier}
      className={className}
      src={currentSrc}
      title={title}
      alt={alt || title}
      style={style}
      onClick={handlerOnClick}
      onKeyUp={keyHandler(handlerOnClick)}
      // Would require a button wrapper
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
      role="button"
      tabIndex={0}
    />
  ) : (
    ""
  );
}

/* eslint-disable */
export function ImageSignedUrlBackground({
  identifier,
  src = "",
  className = "",
  children,
  rawSrcForTest,
}) {
  const {
    dispatch,
    image: { preload },
  } = useStoreon("image");

  const uiTest = useContext(UITestContext);

  const ownId =
    (/https*:\/\//.test(src) && src.split("?")[0]?.split("/")?.reverse()[0]) ||
    identifier;
  const [currentSrc, setCurrentSrc] = useState(
    (uiTest && rawSrcForTest) || preload[ownId]
  );
  const [currentIdentifier, setCurrentIdentifier] = useState(ownId);

  console.log({ currentSrc });
  useEffect(() => {
    setCurrentIdentifier(ownId);
    dispatch(REFRESH_IMAGE, { id: ownId, path: src });
  }, [identifier, src]);

  useEffect(() => {
    preload[currentIdentifier] &&
      preload[currentIdentifier] !== currentSrc &&
      setCurrentSrc(preload[currentIdentifier]);
  }, [preload[currentIdentifier]]);

  return (
    <div
      id={currentIdentifier}
      key={currentIdentifier}
      className={className}
      style={
        src
          ? {
              backgroundImage: `url(${currentSrc})`,
              backgroundColor: "transparent",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }
          : {}
      }
    >
      {children}
    </div>
  );
}
