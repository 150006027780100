import axios from "axios";
import { TOAST, TOAST_ERROR } from "./ui";
import { PODCAST_PAGE_ENDPOINT } from "../../config/endpoints";

export const GET_SERIES = "GET_SERIES";
export const SET_SERIES = "SET_SERIES";
export const GET_SERIES_IS_LOADING = "GET_SERIES_IS_LOADING";
export const SET_SELECTED_SERIES = "SET_SELECTED_SERIES";

const INITIAL_STATE = {
  isLoading: true,
  config: {},
  series: [],
  selectedSeries: {},
  indexSelectedSeries: 0,
};

export function series(store) {
  store.on("@init", () => {
    return {
      series: INITIAL_STATE,
    };
  });

  store.on(GET_SERIES_IS_LOADING, ({ series }, isLoading) => {
    return {
      series: {
        ...series,
        isLoading,
      },
    };
  });

  store.on(GET_SERIES, async () => {
    try {
      store.dispatch(GET_SERIES_IS_LOADING, true);

      const { data } = await axios(`${PODCAST_PAGE_ENDPOINT}`);

      store.dispatch(SET_SERIES, data);
    } catch (err) {
      store.dispatch(TOAST, {
        type: TOAST_ERROR,
        message: "Failed to get series",
      });
    } finally {
      store.dispatch(GET_SERIES_IS_LOADING, false);
    }
  });

  store.on(
    SET_SERIES,
    ({ series }, { serie_section: { series: newSeries }, ...config }) => {
      return {
        series: {
          ...series,
          config,
          series: newSeries,
          selectedSeries: newSeries[0],
          indexSelectedSeries: 0,
        },
      };
    }
  );

  store.on(SET_SELECTED_SERIES, ({ series }, selectedSeries) => {
    const { series: currentSeries } = series;
    return {
      series: {
        ...series,
        selectedSeries: currentSeries[selectedSeries],
        indexSelectedSeries: selectedSeries,
      },
    };
  });
}
