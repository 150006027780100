import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { StoreContext } from "storeon/react";
import { Auth0Provider } from "@auth0/auth0-react";
import { ToastContainer } from "react-toastify";
import { store } from "./core/store";
import ProtectedRoute from "./core/auth/protectedRoute";
import ErrorBoundary from "./core/utils/errorBoundary";
import Scroller from "./core/utils/scroller";
import Auth from "./v2/core/auth/auth";
import {
  CONFIRM_PLAN_ROUTE,
  COOKIES_ROUTE,
  CREATE_PROFILE_ROUTE,
  DISCOVER_ROUTE,
  DISCOVERY_CALL_ROUTE,
  DISCOVERY_SECURED_ROUTE,
  ENTERPRISE_ADD_LICENSE_ROUTE,
  ENTERPRISE_BOARDING_ROUTE,
  ENTERPRISE_INVITE_USERS_ROUTE,
  FAQS_ROUTE,
  HOME_ROUTE,
  HOW_IT_WORKS_ROUTE,
  INVITED_ROUTE,
  LOCAL_ROUTE,
  LOGIN_ROUTE,
  MY_FINDR_ROUTE,
  ONBOARDING_ROUTE,
  PAYMENT_ROUTE,
  PLANS_ROUTE,
  PRIVACY_ROUTE,
  PROFILE_ROUTE,
  REASONS_ROUTE,
  RECEIVED_ROUTE,
  SETTINGS_ROUTE,
  SETTINGS_ROUTE_TAB,
  SIGNUP_ROUTE,
  TERMS_ROUTE,
  CASE_STUDIES_ROUTE,
  BLOG_ROUTE,
  BLOG_DETAIL_ROUTE,
  PODCASTS_ROUTE,
  BLOG_POST_ROUTE,
  CASE_STUDIES_DETAIL_ROUTE,
  MY_FINDR_MATCHES_ROUTE,
  UNSUBSCRIBE_SUCCESSFUL_STATUS_ROUTE,
  UNSUBSCRIBE_STATUS_ROUTE,
  PROJECT_ROUTE_VIEW,
  PROJECT_ROUTE_PREVIEW,
  PROJECT_ROUTE_CREATE,
  PROJECT_ROUTE_UPDATE,
  PROFILE_ROUTE_ID,
  NOT_FOUND,
  PAYMENT_3DS_RETURN_ROUTE,
} from "./config/routes";

import "react-toastify/dist/ReactToastify.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "@fontsource/roboto";
import { AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN } from "./config/auth0";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { axiosResponseInterceptor } from "./core/interceptor/interceptor";
import { Layout } from "./templates/layout";
import NewUpdate from "./core/new-update/new-update";
import PushNotification from "./core/firebase/notifications";
import UserDisabled from "./core/user-disabled/user-disabled";
import Payment3dsReturn from "./views/payment-3ds-return/payment-3ds-return";
import {
  CREATE_PROFILE_V2,
  SETTINGS_V2,
  DISCOVERY_CALL_V2,
  MY_FINDR_MATCHES_V2,
  DISCOVERY_SECURED_CALL_V2,
  RECEIVED_CALL_V2,
  MY_FINDR_V2,
  PAYMENT_V2,
  PROJECT_VIEW_V2,
  PROFILE_V2,
  HOW_IT_WORKS_V2,
  DISCOVER_V2,
  CONFIRM_PLAN_V2,
  CASE_STUDIES_V2,
} from "./feature-flags";
const Home = lazy(() => import("./views/home/home"));
const Onboarding = lazy(() => import("./views/onboarding/onboarding"));
const Login = lazy(() => import("./views/auth/login/login"));
const SignUp = lazy(() => import("./views/auth/signup/signup"));
const CreateProfile = CREATE_PROFILE_V2
  ? lazy(() => import("./v2/views/auth/create-profile/create-profile"))
  : lazy(() => import("./views/auth/create-profile/create-profile"));
const HowItWorks = HOW_IT_WORKS_V2
  ? lazy(() => import("./v2/views/how-it-works/how-it-works"))
  : lazy(() => import("./views/how-it-works/how-it-works"));
const Discover = DISCOVER_V2
  ? lazy(() => import("./v2/views/discover/discover"))
  : lazy(() => import("./views/discover/discover"));
const Profile = PROFILE_V2
  ? lazy(() => import("./v2/views/profile/profile"))
  : lazy(() => import("./views/profile/profile"));
const Settings = SETTINGS_V2
  ? lazy(() => import("./v2/views/settings/settings"))
  : lazy(() => import("./views/settings/settings"));

const DiscoveryCall = DISCOVERY_CALL_V2
  ? lazy(() => import("./v2/views/discovery-call/discovery-call"))
  : lazy(() => import("./views/discovery-call/discoveryCall"));
const MyFindr = MY_FINDR_V2
  ? lazy(() => import("./v2/views/my-findr/my-findr"))
  : lazy(() => import("./views/my-findr/my-findr"));
const Payment = PAYMENT_V2
  ? lazy(() => import("./v2/views/payment/payment"))
  : lazy(() => import("./views/payment/payment"));
const Received = RECEIVED_CALL_V2
  ? lazy(() => import("./v2/views/received/received"))
  : lazy(() => import("./views/received/received"));
const Terms = lazy(() => import("./views/terms/terms"));
const Privacy = lazy(() => import("./views/privacy/privacy"));
const Faqs = lazy(() => import("./views/faqs/faqs"));
const Reasons = lazy(() => import("./views/reasons/reasons"));
const DiscoverySecured = DISCOVERY_SECURED_CALL_V2
  ? lazy(() => import("./v2/views/discovery-secured/discovery-secured"))
  : lazy(() => import("./views/discovery-secured/discovery-secured"));
const FirebaseAnalytics = lazy(() => import("./core/firebase/analitycs"));
const Cookies = lazy(() => import("./views/cookies/cookies"));
const EnterpriseBoarding = lazy(() =>
  import("./views/enterprise-boarding/enterprise-boarding")
);
const Plans = lazy(() => import("./views/plans/plans"));
const ConfirmPlan = CONFIRM_PLAN_V2
  ? lazy(() => import("./v2/views/confirm-plan/confirm-plan"))
  : lazy(() => import("./views/confirm-plan/confirm-plan"));
const EnterpriseAddLicense = lazy(() =>
  import("./views/enterprise-boarding/enterprise-add-license")
);
const EnterpriseInviteUsers = lazy(() =>
  import("./views/enterprise-boarding/enterprise-invite-users")
);
const CaseStudies = lazy(() =>
  CASE_STUDIES_V2
    ? import("./v2/views/case-studies/case-studies")
    : import("./views/case-studies/case-studies")
);
const Blog = lazy(() => import("./views/blog/blog"));
const Podcasts = lazy(() => import("./views/podcasts/podcasts"));
const Post = lazy(() => import("./components/post/post"));
const NotFound = lazy(() => import("./components/not-found/not-found"));
const CaseStudy = lazy(() => import("./components/caseStudy/case-study"));
const MyFindrMatches = MY_FINDR_MATCHES_V2
  ? lazy(() => import("./v2/views/my-findr-matches/my-findr-matches"))
  : lazy(() => import("./views/my-findr-matches/my-findr-matches"));
const UnsubscribeSuccessful = lazy(() =>
  import("./views/unsubscribe/successful/unsubscribe-successful")
);
const Unsubscribe = lazy(() => import("./views/unsubscribe/unsubscribe"));
const ProjectCreate = lazy(() => import("./views/project/project-create"));
const ProjectUpdate = lazy(() => import("./views/project/project-update"));
const ProjectPreview = lazy(() => import("./views/project/project-preview"));
const ProjectView = PROJECT_VIEW_V2
  ? lazy(() => import("./v2/views/project/project-view"))
  : lazy(() => import("./views/project/project-view"));

Sentry.init({
  dsn: "https://12c27d77f9994c9aba2710018581e484@o474169.ingest.sentry.io/5510051",
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [
        "localhost",
        "https://dev.findr.global/",
        "https://staging.findr.global/",
        "https://findr.global/",
        /^\//,
      ],
    }),
  ],
  // Avoid sending the transaction information to Sentry when
  // Cypress E2E tests are running.
  tracesSampler: () => {
    if (["local", "develop", "staging"].includes(process.env.STAGE)) {
      const storeon = localStorage.getItem("storeon");
      const data = JSON.parse(storeon);
      const userInfo = data?.auth?.userInfo;
      const e2eMode = userInfo && userInfo.sub && userInfo.sub.includes("e2e");

      if (e2eMode) return 0;
    }
    return 1;
  },
});

Sentry.setTag("environment", process.env.STAGE || "local");

axiosResponseInterceptor(store);

export default function App() {
  return (
    <ErrorBoundary>
      <Auth0Provider
        domain={AUTH0_DOMAIN}
        clientId={AUTH0_CLIENT_ID}
        redirectUri={LOCAL_ROUTE}
        audience={AUTH0_AUDIENCE}
        onRedirectCallback={(appState) => {
          // flag to be handled by the after login process
          sessionStorage.setItem("isLoginSession", "true");
          // default redirect handle
          window.history.replaceState(
            {},
            document.title,
            appState?.returnTo || window.location.pathname
          );
        }}
      >
        <StoreContext.Provider value={store}>
          <Suspense fallback={<></>}>
            <Router>
              <Scroller>
                <ToastContainer />
                <Auth>
                  <UserDisabled>
                    <PushNotification />
                    <NewUpdate />
                    <Layout>
                      <Switch>
                        <Route exact path={HOME_ROUTE} component={Home} />
                        <Route
                          exact
                          path={HOW_IT_WORKS_ROUTE}
                          component={HowItWorks}
                        />
                        <Route exact path={LOGIN_ROUTE} component={Login} />
                        <Route exact path={INVITED_ROUTE} component={SignUp} />
                        <Route exact path={SIGNUP_ROUTE} component={SignUp} />
                        <Route exact path={TERMS_ROUTE} component={Terms} />
                        <Route exact path={PRIVACY_ROUTE} component={Privacy} />
                        <Route exact path={COOKIES_ROUTE} component={Cookies} />
                        <Route exact path={FAQS_ROUTE} component={Faqs} />
                        <Route exact path={BLOG_ROUTE} component={Blog} />
                        <Route
                          exact
                          path={BLOG_DETAIL_ROUTE}
                          component={Blog}
                        />
                        <Route
                          exact
                          path={PODCASTS_ROUTE}
                          component={Podcasts}
                        />
                        <Route
                          exact
                          path={PAYMENT_3DS_RETURN_ROUTE}
                          component={Payment3dsReturn}
                        />
                        <Route
                          exact
                          path={CASE_STUDIES_ROUTE}
                          component={CaseStudies}
                        />
                        <Route
                          exact
                          path={CASE_STUDIES_DETAIL_ROUTE}
                          component={CaseStudy}
                        />
                        <Route exact path={BLOG_POST_ROUTE} component={Post} />
                        <Route
                          exact
                          path={UNSUBSCRIBE_STATUS_ROUTE}
                          component={Unsubscribe}
                        />
                        <Route
                          exact
                          path={UNSUBSCRIBE_SUCCESSFUL_STATUS_ROUTE}
                          component={UnsubscribeSuccessful}
                        />
                        <ProtectedRoute
                          exact
                          allowUnauthenticated={true}
                          path={PLANS_ROUTE}
                          component={Plans}
                        />
                        <ProtectedRoute
                          exact
                          path={ONBOARDING_ROUTE}
                          component={Onboarding}
                        />
                        <ProtectedRoute
                          exact
                          path={CREATE_PROFILE_ROUTE}
                          component={CreateProfile}
                        />
                        <ProtectedRoute
                          exact
                          path={DISCOVER_ROUTE}
                          component={Discover}
                        />
                        <ProtectedRoute
                          exact
                          path={SETTINGS_ROUTE}
                          component={Settings}
                        />
                        <ProtectedRoute
                          exact
                          path={SETTINGS_ROUTE_TAB}
                          component={Settings}
                        />
                        <ProtectedRoute
                          exact
                          path={PROFILE_ROUTE}
                          component={Profile}
                        />
                        <ProtectedRoute
                          exact
                          path={PROFILE_ROUTE_ID}
                          component={Profile}
                        />
                        <ProtectedRoute
                          exact
                          path={PROJECT_ROUTE_CREATE}
                          component={ProjectCreate}
                        />
                        <ProtectedRoute
                          exact
                          path={PROJECT_ROUTE_VIEW}
                          component={ProjectView}
                        />
                        <ProtectedRoute
                          exact
                          path={PROJECT_ROUTE_PREVIEW}
                          component={ProjectPreview}
                        />
                        <ProtectedRoute
                          exact
                          path={PROJECT_ROUTE_UPDATE}
                          component={ProjectUpdate}
                        />
                        <ProtectedRoute
                          exact
                          path={PAYMENT_ROUTE}
                          component={Payment}
                        />
                        <ProtectedRoute
                          exact
                          path={RECEIVED_ROUTE}
                          component={Received}
                        />
                        <ProtectedRoute
                          exact
                          path={MY_FINDR_ROUTE}
                          component={MyFindr}
                        />
                        <ProtectedRoute
                          exact
                          path={DISCOVERY_CALL_ROUTE}
                          component={DiscoveryCall}
                        />
                        <ProtectedRoute
                          exact
                          path={DISCOVERY_SECURED_ROUTE}
                          component={DiscoverySecured}
                        />
                        <ProtectedRoute
                          exact
                          path={REASONS_ROUTE}
                          component={Reasons}
                        />
                        <ProtectedRoute
                          exact
                          path={ENTERPRISE_BOARDING_ROUTE}
                          component={EnterpriseBoarding}
                        />
                        <ProtectedRoute
                          exact
                          path={ENTERPRISE_ADD_LICENSE_ROUTE}
                          component={EnterpriseAddLicense}
                        />
                        <ProtectedRoute
                          exact
                          path={ENTERPRISE_INVITE_USERS_ROUTE}
                          component={EnterpriseInviteUsers}
                        />
                        <ProtectedRoute
                          exact
                          path={CONFIRM_PLAN_ROUTE}
                          component={ConfirmPlan}
                        />
                        <ProtectedRoute
                          exact
                          path={MY_FINDR_MATCHES_ROUTE}
                          component={MyFindrMatches}
                        />
                        <Route exact path={NOT_FOUND} component={NotFound} />
                        <Redirect from="*" to={NOT_FOUND} />
                      </Switch>
                    </Layout>
                  </UserDisabled>
                </Auth>
              </Scroller>
              <FirebaseAnalytics />
            </Router>
          </Suspense>
        </StoreContext.Provider>
      </Auth0Provider>
    </ErrorBoundary>
  );
}
