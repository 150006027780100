import {
  DeleteRequest,
  GetRequest,
  PatchRequest,
  PostRequest,
  PutRequest,
} from "../api/api-request";
import {
  BUCKET_ENDPOINT,
  ENTERPRISE_MEMBER_ENDPOINT,
  PROFILE_ENDPOINT,
  PROFILE_LINKEDIN_SYNC_ENDPOINT,
  PROJECT_ENDPOINT_SAVED,
  STRAPI_TOKEN_ENDPOINT,
  VALIDATE_INVITATION_LINK_ENDPOINT,
  PROFILE_CONCURRENT_LOGIN_ENDPOINT,
} from "../../config/endpoints";
import {
  CONFIRM_PLAN_ROUTE,
  CREATE_PROFILE_ROUTE,
  DISCOVER_ROUTE,
  ENTERPRISE_BOARDING_ROUTE,
  ONBOARDING_ROUTE,
  PLANS_ROUTE,
} from "../../config/routes";
import { TOAST, TOAST_ERROR, TOAST_INFO, TOAST_SUCCESS } from "./ui";
import { generateProfileFromFields } from "../utils/requestFixers";

export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";
export const CREATE_PROFILE = "CREATE_PROFILE";
export const CREATE_PROFILE_UPLOAD_IMAGE = "CREATE_PROFILE_UPLOAD_IMAGE";
export const LOGIN_OR_SIGNUP = "LOGIN_OR_SIGNUP";
export const LOGIN_OR_SIGNUP_LOADING = "LOGIN_OR_SIGNUP_LOADING";
export const SET_ACCESSED_PROFILE = "SET_ACCESSED_PROFILE";
export const SET_LOGO_WARNING_PROFILE = "SET_LOGO_WARNING_PROFILE";
export const LOAD_PROFILE = "LOAD_PROFILE";
export const LOAD_NEW_ENTERPRISE_PROFILE = "LOAD_NEW_ENTERPRISE_PROFILE";
export const SET_ONBOARDING_LOCK = "SET_ONBOARDING_LOCK";
export const SET_STRAPI_TOKEN = "SET_STRAPI_TOKEN";
export const VALIDATE_INVITATION_LINK = "VALIDATE_INVITATION_LINK";
export const SAVE_PROJECT = "SAVE_PROJECT";
export const SET_SAVE_PROJECT = "SET_SAVE_PROJECT";
export const SET_REMOVE_PROJECT = "SET_REMOVE_PROJECT";
export const VALIDATE_CONCURRENT_LOGIN = "VALIDATE_CONCURRENT_LOGIN";
export const CLEAN_CONCURRENT_LOGIN = "CLEAN_CONCURRENT_LOGIN";
export const SET_USER_DISABLED = "SET_USER_DISABLED";

const INIT_STATE = {
  isAuthenticated: false,
  accessToken: "",
  strapiToken: "",
  userInfo: {},
  profile: {},
  accessedProfile: false,
  logoWarningVisible: true,
  error: false,
  onboardingProcess: false,
  isLoading: false,
  isConcurrentUserLogin: false,
  sessionId: "",
  disabled: false,
};

export function auth(store) {
  store.on("@init", () => ({
    auth: INIT_STATE,
  }));

  store.on(
    SIGN_IN,
    ({ auth }, { accessToken, userInfo, profile = {}, error = false }) => {
      return {
        auth: {
          ...auth,
          isAuthenticated: true,
          accessToken,
          userInfo,
          profile,
          error,
        },
      };
    }
  );

  store.on(SIGN_OUT, () => {
    document.cookie =
      "auth0.is.authenticated=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    localStorage.clear();
    sessionStorage.clear();

    return {
      auth: INIT_STATE,
    };
  });

  store.on(
    CREATE_PROFILE_UPLOAD_IMAGE,
    async (
      { auth: { accessToken } },
      { profile: { logoData, ...data }, history }
    ) => {
      // notify user
      store.dispatch(TOAST, {
        type: TOAST_INFO,
        message: "Uploading image...",
      });

      // to be send to the server
      let getLogoUrl;
      // to upload image
      let putLogoUrl;

      // then try to get the company logo url endpoints
      if (logoData) {
        try {
          const { logoFile, ...imageConfig } = logoData;
          const res = await PostRequest(
            `${BUCKET_ENDPOINT}`,
            imageConfig,
            accessToken
          );
          // update variables
          getLogoUrl = res.data.get;
          putLogoUrl = res.data.put;
        } catch (e) {
          store.dispatch(TOAST, {
            type: TOAST_ERROR,
            message: "Error uploading company logo. Try again",
          });
        }
      }

      // upload images
      try {
        if (logoData) {
          await PutRequest(putLogoUrl, logoData.logoFile, {
            headers: {
              "Content-Type": logoData.contentType,
            },
          });
        }
        const updatedProfile = {
          ...data,
          companyLogo: logoData ? getLogoUrl : data.companyLogo,
        };
        store.dispatch(CREATE_PROFILE, {
          profile: updatedProfile,
          history,
        });
      } catch (e) {
        store.dispatch(TOAST, {
          type: TOAST_ERROR,
          message: "Error uploading image. Please try again",
        });
      }
    }
  );

  store.on(CREATE_PROFILE, async ({ auth }, { profile, history }) => {
    const selectedPlan = localStorage.getItem(
      `${auth.userInfo.sub}-selectedPlan`
    );

    const data = generateProfileFromFields({
      ...profile,
      id_token: auth.userInfo.id_token,
      paygPlan: selectedPlan === `PAYG`,
    });

    let response = null;
    try {
      try {
        response = await GetRequest(`${PROFILE_ENDPOINT}`, auth.accessToken);
      } catch (e) {
        console.log(e);
      }
      let requestResponse;
      if (response) {
        requestResponse = await PatchRequest(
          `${PROFILE_ENDPOINT}`,
          {
            jobTitle: profile.jobTitle,
            termsAccepted: true,
            companyEmail: profile.companyEmail,
          },
          auth.accessToken
        );
      } else {
        requestResponse = await PostRequest(
          PROFILE_ENDPOINT,
          data,
          auth.accessToken
        );
      }

      const strapiToken = requestResponse.data?.strapi?.token;
      if (strapiToken) {
        store.dispatch(SET_STRAPI_TOKEN, strapiToken);
      }

      // Setting the profile
      response = await GetRequest(`${PROFILE_ENDPOINT}`, auth.accessToken);
      store.dispatch(SIGN_IN, {
        userInfo: auth.userInfo,
        accessToken: auth.accessToken,
        profile: response.data,
        error: false,
      });

      const { role } = response.data;

      if (
        (!profile.stripe || profile.stripe.planName === "PAYG") &&
        role !== "MEMBER" &&
        selectedPlan &&
        selectedPlan !== "PAYG"
      ) {
        history.push(`${CONFIRM_PLAN_ROUTE}?plan=${selectedPlan}&new=true`);
      } else {
        history.push(ONBOARDING_ROUTE);
      }
      store.dispatch(TOAST, {
        type: TOAST_SUCCESS,
        message: "Profile create successfully",
      });
    } catch (e) {
      store.dispatch(SIGN_IN, {
        isAuthenticated: auth.isAuthenticated,
        accessToken: auth.accessToken,
        userInfo: auth.userInfo,
        profile: {},
        accessedProfile: auth.accessedProfile,
        logoWarningVisible: auth.logoWarningVisible,
        error: true,
      });
      store.dispatch(TOAST, {
        type: TOAST_ERROR,
        message: e,
        error: e,
      });
    }
  });

  store.on(LOAD_PROFILE, async ({ auth }) => {
    try {
      const response = await GetRequest(
        `${PROFILE_ENDPOINT}`,
        auth.accessToken
      );

      store.dispatch(SIGN_IN, {
        userInfo: auth.userInfo,
        accessToken: auth.accessToken,
        profile: response.data,
        error: false,
      });
    } catch (e) {
      console.log(e);
    }
  });

  store.on(LOAD_NEW_ENTERPRISE_PROFILE, async ({ auth }, { history }) => {
    try {
      const response = await GetRequest(
        `${PROFILE_ENDPOINT}`,
        auth.accessToken
      );

      store.dispatch(SIGN_IN, {
        userInfo: auth.userInfo,
        accessToken: auth.accessToken,
        profile: response.data,
        error: false,
      });
      store.dispatch(SET_ONBOARDING_LOCK, false);
      history.push(ENTERPRISE_BOARDING_ROUTE);
    } catch (e) {
      console.log(e);
    }
  });

  store.on(LOGIN_OR_SIGNUP, async (_, { userInfo, accessToken, history }) => {
    let choosePlan = false;
    let createProfile = false;

    store.dispatch(LOGIN_OR_SIGNUP_LOADING, true);

    store.dispatch(SIGN_IN, {
      userInfo,
      accessToken,
    });
    try {
      const inviteToken = localStorage.getItem("inviteToken");
      localStorage.removeItem("inviteToken");

      let response = null;
      if (inviteToken) {
        response = await PostRequest(
          `${ENTERPRISE_MEMBER_ENDPOINT}/activate`,
          {
            memberId: inviteToken,
            idToken: userInfo.id_token,
          },
          accessToken
        );
        if (response.data.message === "This user already exist") {
          await PatchRequest(
            PROFILE_LINKEDIN_SYNC_ENDPOINT,
            {
              idToken: userInfo.id_token,
            },
            accessToken
          );

          response = await GetRequest(`${PROFILE_ENDPOINT}`, accessToken);
        }
      } else {
        await PatchRequest(
          PROFILE_LINKEDIN_SYNC_ENDPOINT,
          {
            idToken: userInfo.id_token,
          },
          accessToken
        );

        response = await GetRequest(`${PROFILE_ENDPOINT}`, accessToken);
      }

      if (response.data.isActive) {
        if (
          !(
            response.data?.stripe?.planName || response.data?.role === "MEMBER"
          ) ||
          (response.data?.isFindrTeam && !response.data.termsAccepted)
        ) {
          choosePlan = true;
        } else if (
          !response.data?.firstName ||
          (response.data?.role === "MEMBER" && !response.data.termsAccepted)
        ) {
          createProfile = true;
        } else {
          const {
            data: { token: strapiToken },
          } = await PostRequest(
            STRAPI_TOKEN_ENDPOINT,
            {
              userId: userInfo.sub,
            },
            accessToken
          );
          if (response.data.onBoarding.completed) {
            store.dispatch(SET_ONBOARDING_LOCK, false);

            const redirectPath = localStorage.getItem("redirect");
            if (redirectPath) {
              localStorage.removeItem("redirect");
              history.push(redirectPath);
            } else {
              history.push(DISCOVER_ROUTE);
            }
          } else {
            store.dispatch(SET_ONBOARDING_LOCK, true);
            history.push(ONBOARDING_ROUTE);
          }
          store.dispatch(SIGN_IN, {
            userInfo,
            accessToken,
            profile: response.data,
            error: false,
          });
          store.dispatch(SET_STRAPI_TOKEN, strapiToken);
        }
      }
    } catch (e) {
      choosePlan = true;
    } finally {
      if (choosePlan) {
        store.dispatch(SET_ONBOARDING_LOCK, true);
        history.push(PLANS_ROUTE);
      } else if (createProfile) {
        store.dispatch(SET_ONBOARDING_LOCK, true);
        history.push(CREATE_PROFILE_ROUTE);
      }
      store.dispatch(LOGIN_OR_SIGNUP_LOADING, false);
    }
  });

  store.on(LOGIN_OR_SIGNUP_LOADING, ({ auth }, isLoading) => {
    return {
      auth: {
        ...auth,
        isLoading,
      },
    };
  });

  store.on(SET_ACCESSED_PROFILE, ({ auth }) => {
    return {
      auth: {
        ...auth,
        accessedProfile: true,
      },
    };
  });

  store.on(SET_LOGO_WARNING_PROFILE, ({ auth }) => {
    return {
      auth: {
        ...auth,
        logoWarningVisible: false,
      },
    };
  });

  store.on(SET_STRAPI_TOKEN, ({ auth }, strapiToken) => {
    return {
      auth: {
        ...auth,
        strapiToken,
      },
    };
  });

  store.on(SET_ONBOARDING_LOCK, ({ auth }, onboardingProcess) => {
    return {
      auth: {
        ...auth,
        onboardingProcess,
      },
    };
  });

  store.on(
    VALIDATE_INVITATION_LINK,
    async ({ auth }, { inviteToken, loginWithRedirect }) => {
      const response = await GetRequest(
        `${VALIDATE_INVITATION_LINK_ENDPOINT}/${inviteToken}`
      );

      if (response.data.validLink) {
        localStorage.setItem("inviteToken", inviteToken);
        loginWithRedirect({ connection: "linkedin" });
        return { auth };
      } else {
        store.dispatch(TOAST, {
          type: TOAST_ERROR,
          message: "Invitation link is already used or invalid",
        });
      }
    }
  );

  store.on(SAVE_PROJECT, async ({ auth }, { project, callback = () => {} }) => {
    try {
      const toSave = !auth.profile.savedProjects.some(
        (p) => p.id === project.id
      );

      if (toSave) {
        await PostRequest(
          PROJECT_ENDPOINT_SAVED,
          { id: project.id },
          auth.accessToken
        );

        store.dispatch(SET_SAVE_PROJECT, project);

        store.dispatch(TOAST, {
          type: TOAST_SUCCESS,
          message: "This project has been successfully saved",
        });
      } else {
        await DeleteRequest(
          `${PROJECT_ENDPOINT_SAVED}/${project.id}`,
          auth.accessToken
        );

        store.dispatch(SET_REMOVE_PROJECT, project);

        store.dispatch(TOAST, {
          type: TOAST_SUCCESS,
          message: "This project has been removed from your saved project list",
        });
      }
    } catch (e) {
      store.dispatch(TOAST, {
        type: TOAST_ERROR,
        message: e,
        error: e,
      });
    } finally {
      callback();
    }
  });

  store.on(SET_SAVE_PROJECT, ({ auth }, project) => {
    return {
      auth: {
        ...auth,
        profile: {
          ...auth.profile,
          savedProjects: [...auth.profile.savedProjects, project],
        },
      },
    };
  });

  store.on(SET_REMOVE_PROJECT, ({ auth }, project) => {
    return {
      auth: {
        ...auth,
        profile: {
          ...auth.profile,
          savedProjects: [
            ...auth.profile.savedProjects.filter((p) => p.id !== project.id),
          ],
        },
      },
    };
  });

  store.on(
    VALIDATE_CONCURRENT_LOGIN,
    async ({ auth }, { userInfo, sid, accessToken, history }) => {
      let isConcurrentUserLogin = false;

      try {
        const res = await PostRequest(
          `${PROFILE_CONCURRENT_LOGIN_ENDPOINT}`,
          { userWebClient: sid },
          accessToken
        );

        isConcurrentUserLogin = !res?.data?.success;

        if (isConcurrentUserLogin) {
          store.dispatch(TOAST, {
            type: TOAST_ERROR,
            message: "You are already logged in",
          });
        } else {
          store.dispatch(LOGIN_OR_SIGNUP, { userInfo, accessToken, history });
        }
      } catch (e) {
        console.log(e);
      }

      return {
        auth: {
          ...auth,
          sessionId: sid,
          isConcurrentUserLogin,
        },
      };
    }
  );

  store.on(CLEAN_CONCURRENT_LOGIN, async ({ auth }, callback) => {
    try {
      await DeleteRequest(
        `${PROFILE_CONCURRENT_LOGIN_ENDPOINT}`,
        auth.accessToken
      );
    } catch (e) {
      store.dispatch(TOAST, {
        type: TOAST_ERROR,
        message: "Could not delete login data",
      });
    }

    if (typeof callback === "function") callback();
    return { auth };
  });

  store.on(SET_USER_DISABLED, ({ auth }, disabled) => {
    return {
      auth: {
        ...auth,
        disabled,
      },
    };
  });
}
