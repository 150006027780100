// store modules
export const STORE_AUTH_V2 = true;
export const STORE_PROJECT_V2 = true;
export const STORE_SETTINGS_V2 = true;
export const STORE_DISCOVER_V2 = true;

// components
export const AUTH_V2 = true; // developing ongoing

// views
export const SETTINGS_V2 = true;
// it depends on SETTINGS_V2 and STORE_SETTINGS_V2
export const SETTINGS_PAYMENT_V2 = true && SETTINGS_V2 && STORE_SETTINGS_V2;
// it depends on STORE_AUTH_V2
export const CREATE_PROFILE_V2 = true && STORE_AUTH_V2;
// Accept requests
export const DISCOVERY_CALL_V2 = true;
export const DISCOVERY_SECURED_CALL_V2 = true;
export const MY_FINDR_MATCHES_V2 = true;
export const RECEIVED_CALL_V2 = true;
export const MY_FINDR_V2 = true;
export const PAYMENT_V2 = true;
export const PROJECT_VIEW_V2 = true;
export const PROFILE_V2 = true;
export const HOW_IT_WORKS_V2 = true;
export const DISCOVER_V2 = true;
// Confirm plan
export const CONFIRM_PLAN_V2 = true;
// Case studies
export const CASE_STUDIES_V2 = true;

export const HEADER_V2 = true;
