import { getAnalytics, logEvent } from "firebase/analytics";
import { CountriesRegions } from "../../../core/utils/countries-regions";
import { REQUEST_DISCOVERY_CALL } from "../store/project";
/* eslint-disable */

export function sleep(milliseconds) {
  const date = Date.now();
  let currentDate = null;
  do {
    currentDate = Date.now();
  } while (currentDate - date < milliseconds);
}

export function calculateTime(
  seconds,
  outcome = {
    concat: false,
    abbrev: "SHORT",
  }
) {
  const MINUTES_SHORT = ["min", "mins"];
  const HOURS_SHORT = ["hr", "hrs"];
  const MINUTES_LONG = ["minute", "minutes"];
  const HOURS_LONG = ["hour", "hours"];

  const suffixMap = new Map([
    [
      "SHORT",
      {
        minute: MINUTES_SHORT,
        hour: HOURS_SHORT,
      },
    ],
    [
      "LONG",
      {
        minute: MINUTES_LONG,
        hour: HOURS_LONG,
      },
    ],
  ]);
  let { concat, abbrev } = outcome;
  const suffix = suffixMap.get(abbrev);
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - hours * 3600) / 60);

  const hoursFormat = `${hours.toString().padStart(2)} ${
    hours > 1 ? suffix.hour[1] : suffix.hour[0]
  }`;

  const minutesFormat = `${minutes.toString().padStart(2, "0")} ${
    minutes > 1 ? suffix.minute[1] : suffix.minute[0]
  }`;

  if (concat) return `${hoursFormat} ${minutesFormat}`;

  if (hours) {
    return hoursFormat;
  }
  return minutesFormat;
}

export const getCountryFromCode = (code) => {
  return CountriesRegions.find((x) => x["alpha-2"] === code)?.name || "";
};

export const getCodeFromCountry = (name) => {
  const country = CountriesRegions.find(
    (x) => x.name.toLowerCase() === name.toLowerCase()
  );
  return country ? country["alpha-2"] : "";
};

export const getTextEllipse = (text = "", amountChars, isEllipse = true) => {
  if (text == null) return;
  const maxLength = amountChars;
  const maxLengthNotProvided = !maxLength;
  const isShorterThanMaximumLength = text?.length < maxLength;
  if (maxLengthNotProvided || isShorterThanMaximumLength) {
    return text;
  }
  const shortenedString = text.substr(0, maxLength);
  return isEllipse ? shortenedString.concat("...") : shortenedString;
};

export const getShareSocialNetworkLink = (url = "") => {
  let twitterURL, linkedinURL, mailtoURL;
  if (typeof url === "string") {
    twitterURL = `https://twitter.com/intent/tweet?url=${url}`;
    linkedinURL = `https://www.linkedin.com/sharing/share-offsite/?url=${url}`;
    mailtoURL = `mailto:?subject=Findr&body=${url}`;
  } else {
    twitterURL = `https://twitter.com/intent/tweet?url=${url.twitter}`;
    linkedinURL = `https://www.linkedin.com/sharing/share-offsite/?url=${url.linkedin}`;
    mailtoURL = `mailto:?subject=Findr&body=${url.mail}`;
  }

  return {
    twitterURL,
    linkedinURL,
    mailtoURL,
  };
};

export function stringToSlug(str) {
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to = "aaaaaeeeeiiiioooouuuunc------";

  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return str;
}

export const isRequestCode = (code) => {
  const regex = /^FDR[a-zA-Z0-9]{6}$/;
  return regex.test(code);
};

export const isTrialCode = (code) => {
  const regex = /^(FDR_P)[a-zA-Z0-9]{6}$/;
  return regex.test(code);
};

export const clipboard = (function (window, document, navigator) {
  var textArea, copy;

  function isOS() {
    return navigator.userAgent.match(/ipad|iphone/i);
  }

  function createTextArea(text) {
    textArea = document.createElement("textArea");
    textArea.value = text;
    document.body.appendChild(textArea);
  }

  function selectText() {
    var range, selection;

    if (isOS()) {
      range = document.createRange();
      range.selectNodeContents(textArea);
      selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      textArea.setSelectionRange(0, 999999);
    } else {
      textArea.select();
    }
  }

  function copyToClipboard() {
    document.execCommand("copy");
    document.body.removeChild(textArea);
  }

  copy = function (text) {
    createTextArea(text);
    selectText();
    copyToClipboard();
  };

  return {
    copy: copy,
  };
})(window, document, navigator);

export const getSearchOptionSpan = (option, optionPart) => {
  const index = removeDirtyCharsOption(option).indexOf(
    optionPart.toLowerCase()
  );
  if (index < 0) return option; //return unaltered if there's no match
  const searchPart = option.substring(index, index + optionPart.length);
  return option.replace(searchPart, `<span>${searchPart}</span>`);
};

export const getSearchOptions = (options, searchPart) => {
  return options.filter((option) =>
    removeDirtyCharsOption(option).includes(searchPart.toLowerCase())
  );
};

export const getSearchOptionsImages = (options, images, searchPart) => {
  const finalImages = [];
  const finalOptions = options.filter((option, index) => {
    if (removeDirtyCharsOption(option).includes(searchPart.toLowerCase())) {
      finalImages.push(images[index]);
      return true;
    }
    return false;
  });

  return { finalOptions, finalImages };
};

const removeDirtyCharsOption = (option) =>
  option.replace("Å", "A").replace("ô", "o").replace("ç", "c").toLowerCase();

export const requestCall = (project, dispatch, afterCallback) => {
  const analytics = getAnalytics();
  logEvent(analytics, "view_project_request_call_action");
  dispatch(REQUEST_DISCOVERY_CALL, { project, afterCallback });
};

export const toMonthName = (monthNumber) => {
  const date = new Date(0);
  date.setMonth(monthNumber);

  return date.toLocaleString("en-US", {
    month: "long",
  });
};

export const cleanUnneededCountries = (origin, affected) => {
  const countries = affected?.split(",") || [];
  const regions = origin?.split(",") || [];

  const result = countries.filter((country) =>
    CountriesRegions.some(
      (countryRegion) =>
        countryRegion.name === country &&
        regions.some(
          (region) => region === "Global" || region === countryRegion.region
        )
    )
  );

  return result.join(",");
};

export const getCompanyWebsiteUrl = (company) =>
  (company?.websiteUrl.includes("http") ? "" : "https://") +
  company?.websiteUrl;

export const jsonToQueryParams = (json) =>
  Object.keys(jsonCleanUndefinedProperties(json))
    .map((v) => v + "=" + json[v])
    .join("&");

export const jsonCleanUndefinedProperties = (json) => {
  Object.keys(json).map((v) => typeof json[v] == "undefined" && delete json[v]);

  return json;
};
