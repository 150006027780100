import React, { lazy, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useStoreon } from "storeon/react";
import Modal from "../../components/modal/modal";
import { REACTIVATE_PROFILE } from "../store/profile";
import textData from "../../content/user-disabled.yaml";
import { SIGN_OUT } from "../store/auth";
import { HOME_ROUTE } from "../../config/routes";
import Header from "../../components/header/header";
const Home = lazy(() => import("../../views/home/home"));

const UserDisabled = ({ children }) => {
  const [isUserDisabled, setIsUserDisabled] = useState(false);

  const {
    dispatch,
    auth: { disabled },
  } = useStoreon("auth");

  const {
    profile: { loadingProfile },
  } = useStoreon("profile");

  const history = useHistory();

  useEffect(() => {
    typeof disabled === "boolean"
      ? setIsUserDisabled(disabled)
      : setIsUserDisabled(false);
  }, [disabled, loadingProfile]);

  const handleOnClose = () => {
    dispatch(SIGN_OUT);
    history.push(HOME_ROUTE);
  };

  const onClickHandler = () => {
    dispatch(REACTIVATE_PROFILE, history);
  };

  return isUserDisabled ? (
    <>
      <Header />
      <Home />
      <Modal handleClose={handleOnClose}>
        <div className="modal-body">
          <div className="Title">{textData.title}</div>
          <div className="Content">
            <span
              dangerouslySetInnerHTML={{
                __html: textData.description,
              }}
            />
          </div>
          <div className="Buttons">
            <div
              className={`Button  Primary-Outline ${
                loadingProfile ? "Disabled" : ""
              }`}
              onClick={handleOnClose}
            >
              {textData.buttons.cancel}
            </div>
            <div
              className={`Button ${loadingProfile ? "Disabled" : ""}`}
              onClick={onClickHandler}
            >
              {textData.buttons.reactivate}
            </div>
          </div>
        </div>
      </Modal>
    </>
  ) : (
    children
  );
};

export default UserDisabled;
