import axios from "axios";
import { FAQS_ENDPOINT } from "../../config/endpoints";
import { TOAST, TOAST_ERROR } from "./ui";

export const GET_FAQS = "GET_FAQS";
export const SET_FAQS = "SET_FAQS";
export const FAQS_SET_IS_LOADING = "FAQS_SET_IS_LOADING";

const INITIAL_STATE = {
  back_label: "",
  faq_title: "",
  video_play_icon: {},
  faqs: {
    title: "",
    questions: "",
    order: "",
  },
  isLoading: true,
};

export function faqs(store) {
  store.on("@init", () => ({
    faqs: INITIAL_STATE,
  }));

  store.on(FAQS_SET_IS_LOADING, ({ faqs }, isLoading) => {
    return {
      faqs: {
        ...faqs,
        isLoading,
      },
    };
  });

  store.on(GET_FAQS, async function () {
    try {
      store.dispatch(FAQS_SET_IS_LOADING, true);

      const { data } = await axios(`${FAQS_ENDPOINT}`);

      store.dispatch(SET_FAQS, data);
    } catch (err) {
      store.dispatch(TOAST, {
        type: TOAST_ERROR,
        message: "Failed to load faqs",
      });
    } finally {
      store.dispatch(FAQS_SET_IS_LOADING, false);
    }
  });

  store.on(
    SET_FAQS,
    ({ faqs }, { back_label, faq_title, video_play_icon, faqs: data }) => {
      return {
        faqs: {
          ...faqs,
          back_label,
          faq_title,
          video_play_icon,
          faqs: data,
        },
      };
    }
  );
}
