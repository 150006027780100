import React, { useEffect, useRef, useState } from "react";
import share from "../../assets/img/social-share.svg";
import close from "../../assets/img/close.svg";
import "./share-button.scss";
import twitterIcon from "../../assets/img/twitter-logo.svg";
import mail from "../../assets/img/mail.svg";
import link from "../../assets/img/link.svg";
import linkedIn from "../../assets/img/linkedIn-filled.svg";
import shareColor from "../../assets/img/share-color.svg";
import exportShare from "../../assets/img/export-share.svg";
import exportShareActive from "../../assets/img/fr_export_share_active.svg";
import {
  clipboard,
  getShareSocialNetworkLink,
} from "../../core/utils/functions";
import useOutsideClick from "../../core/utils/use-outside-click";
import { TOAST, TOAST_ERROR } from "../../core/store/ui";
import { useStoreon } from "storeon/react";
import useMedia from "../../core/hooks/use-media";

const SHARE_ICON = {
  normal: share,
  active: close,
  title: "Share",
};

const EXPORT_ICON = {
  normal: exportShare,
  active: exportShareActive,
  title: "Listen on",
};

export default function ShareButton({
  type,
  isShowTitle,
  isTooltip,
  customList,
  isTriggerCopied,
  isOpen,
  label,
  successMsg,
  shareLink,
  copyLink,
  onClick,
  onHideCopiedMessage,
}) {
  const [clicked, setClicked] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [isTooltipRunningOutSpace, setIsTooltipRunningOutSpace] =
    useState(false);
  const tooltipWrapperRef = useRef(null);
  const tooltipIconsContainerRef = useRef(null);
  const tooltipRef = useRef(null);

  const [{ title, active, normal }, setIcon] = useState(SHARE_ICON);
  const { twitterURL, mailtoURL, linkedinURL } =
    getShareSocialNetworkLink(shareLink);
  const itemsPerRow = useMedia(
    ["(max-width: 500px)", "(max-width: 1024px)"],
    [4, 6],
    Infinity
  );
  const { dispatch } = useStoreon();

  useOutsideClick(tooltipIconsContainerRef, () => {
    if (isTooltipVisible) {
      setIsTooltipVisible(false);
      setClicked(false);
    }
  });

  useEffect(() => {
    if (isTriggerCopied) handleOnCopy();
  }, [isTriggerCopied]);

  useEffect(() => {
    setClicked(isOpen);
  }, [isOpen]);

  useEffect(() => {
    let shareIcon = {
      ...SHARE_ICON,
      label: label,
    };
    let exportIcon = { ...EXPORT_ICON };
    switch (type) {
      case "SHARE":
        setIcon(shareIcon);
        break;
      case "EXPORT":
        setIcon(exportIcon);
        break;
      default:
        setIcon(shareIcon);
    }

    function setTooltipPosition() {
      if (tooltipWrapperRef.current) {
        const position = tooltipWrapperRef.current.getBoundingClientRect();
        setIsTooltipRunningOutSpace(position.top < 150);
      }
    }

    window.addEventListener("scroll", setTooltipPosition);
    return () => window.removeEventListener("scroll", setTooltipPosition);
  }, [type]);

  const handleOnClick = () => {
    if (isTooltip) {
      setIsTooltipVisible((prev) => !prev);
    }
    if (isOpen) setClicked((prev) => !prev);
    // eslint-disable-next-line no-unused-expressions
    onClick?.();
  };

  async function handleOnCopy() {
    if (copyLink || shareLink) {
      try {
        clipboard.copy(copyLink || shareLink);
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
          // eslint-disable-next-line no-unused-expressions
          onHideCopiedMessage?.();
        }, 1000);
      } catch (e) {
        setIsCopied(false);
        dispatch(TOAST, {
          type: TOAST_ERROR,
          message: "Unable to copy share link to clipboard",
        });
      }
    }
  }

  function getIconSource() {
    if (isTooltip && clicked) {
      return customList?.length ? exportShareActive : shareColor;
    }
    return clicked ? active : normal;
  }

  function isMobileHasMoreThanXItems() {
    return customList?.length >= itemsPerRow;
  }

  return (
    <button
      className={`share-button-icon ${isShowTitle ? "has-title" : ""}`}
      onClick={handleOnClick}
      ref={tooltipWrapperRef}
      style={{ position: "relative" }}
    >
      <div ref={tooltipIconsContainerRef}>
        {/* TOOLTIP ICONS */}
        <div
          className={`
          share-button-icon__tooltip ${isTooltipVisible && "tooltip-visible"} ${
            (isTooltipRunningOutSpace || itemsPerRow === 4) && "tooltip-down"
          } ${isMobileHasMoreThanXItems() && "column"}
        `}
          ref={tooltipRef}
        >
          {customList ? (
            customList.map(({ link, icon: { icon, label } }) => (
              <a
                href={link}
                key={link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="tooltip-icon"
                  src={icon.url}
                  title={label}
                  alt={label}
                />
              </a>
            ))
          ) : (
            <>
              {/* LINKEDIN ICON */}
              <a href={linkedinURL} target="_blank" rel="noopener noreferrer">
                <img className="tooltip-icon" src={linkedIn} alt="linkedIn" />
              </a>
              {/* TWITTER ICON */}
              <a href={twitterURL} target="_blank" rel="noopener noreferrer">
                <img className="tooltip-icon" src={twitterIcon} alt="twitter" />
              </a>
              {/* MAIL ICON */}
              <a href={mailtoURL} target="_blank" rel="noopener noreferrer">
                <img className="tooltip-icon" src={mail} alt="mail" />
              </a>
              {/* LINK ICON */}
              <a onClick={handleOnCopy}>
                <img className="tooltip-icon" src={link} alt="link" />
              </a>
            </>
          )}
        </div>
        {/* TOOLTIP LINK COPIED */}
        <div
          className={`tooltip-copied__wrapper ${
            (isCopied || isTriggerCopied) && "show"
          }`}
          style={{ display: type === "EXPORT" ? "none" : "block" }}
        >
          <div className="tooltip-copied">
            <span>{successMsg || "Link Copied!"}</span>
          </div>
        </div>
      </div>
      {/* ICON */}
      <img
        className="share-button-icon_icon"
        src={getIconSource()}
        alt="Share this item"
        title={label || title}
      />
      <p className={isShowTitle ? "has-title" : ""}>{label || title}</p>
    </button>
  );
}
