import React from "react";
import "./modal.scss";
import close from "../../assets/img/close-icon.svg";

const Modal = ({
  children,
  handleClose = () => {},
  className = "",
  width = "",
  height = "",
  closeIcon = close,
  childClass = "",
  paddingX = "",
  isCloseable = true,
}) => {
  return (
    <div className={`ModalWrapper ${className}`}>
      <div
        className={`ModalContainer ${childClass}`}
        style={{
          width: width,
          height: height,
          paddingLeft: paddingX,
          paddingRight: paddingX,
        }}
      >
        {isCloseable && (
          <img
            onClick={handleClose}
            className="Close"
            src={closeIcon}
            alt="Close"
          />
        )}
        {children}
      </div>
    </div>
  );
};

export default Modal;
