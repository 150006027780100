import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import jump from "jump.js";

const Scroller = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.getElementById(location.hash.substr(1)) && jump(location.hash);
  }, [location.key, location.hash]);

  return <>{children}</>;
};

export default Scroller;
