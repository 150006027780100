import axios from "axios";
import { TERMS_ENDPOINT } from "../../config/endpoints";
import { TOAST, TOAST_ERROR } from "./ui";

export const GET_TERMS = "GET_TERMS";
export const SET_TERMS = "SET_TERMS";
export const TERMS_SET_IS_LOADING = "TERMS_SET_IS_LOADING";

const INITIAL_STATE = {
  back_label: "",
  title: "",
  body: "",
  isLoading: true,
};

export function terms(store) {
  store.on("@init", () => ({
    terms: INITIAL_STATE,
  }));

  store.on(TERMS_SET_IS_LOADING, ({ terms }, isLoading) => {
    return {
      terms: {
        ...terms,
        isLoading,
      },
    };
  });

  store.on(GET_TERMS, async function () {
    try {
      store.dispatch(TERMS_SET_IS_LOADING, true);

      const { data } = await axios(`${TERMS_ENDPOINT}`);

      store.dispatch(SET_TERMS, data);
    } catch (err) {
      store.dispatch(TOAST, {
        type: TOAST_ERROR,
        message: "Failed to load terms",
      });
    } finally {
      store.dispatch(TERMS_SET_IS_LOADING, false);
    }
  });

  store.on(SET_TERMS, ({ terms }, { back_label, title, body }) => {
    return {
      terms: {
        ...terms,
        back_label,
        title,
        body,
      },
    };
  });
}
