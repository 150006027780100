import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useStoreon } from "storeon/react";
import {
  CONFIRM_PLAN_ROUTE,
  HOME_ROUTE,
  LOGIN_ROUTE,
} from "../../config/routes";
import { routePerms } from "./routePerms";

const ProtectedRoute = (props) => {
  const {
    auth: { isAuthenticated, profile },
  } = useStoreon("auth");

  const path = window.location.pathname;

  const perms = routePerms[path] || (() => true);
  const allowUnauthenticated = props.allowUnauthenticated;

  if ((allowUnauthenticated || isAuthenticated) && perms(profile)) {
    return <Route {...props} />;
  }

  return (
    <Route
      path={props.path}
      render={() => {
        if (path !== HOME_ROUTE && path !== CONFIRM_PLAN_ROUTE) {
          localStorage.setItem("redirect", path);
        }

        return <Redirect to={isAuthenticated ? HOME_ROUTE : LOGIN_ROUTE} />;
      }}
    />
  );
};

ProtectedRoute.defaultProps = {
  allowUnauthenticated: false,
};

export default ProtectedRoute;
