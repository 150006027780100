export const generateProjectFormFields = (project) => {
  const form = {
    objective: project.objective,
    businessIndustry: project.businessIndustry,
    partnerType: project.partnerType,
    solution: project.solution,
    solutionType: project.solutionType,
    stage: project.stage,
    revenue: project.revenue,
    size: project.size,
    markets: project.markets,
    countries: project.countries,
    partnerStage: project.partnerStage,
    partnerSize: project.partnerSize,
    partnerMarkets: project.partnerMarkets,
    partnerCountries: project.partnerCountries,
    timing: project.timing,
    exclusion: project.exclusion,
    partnerRevenue: project.partnerRevenue,
    budget: project.budget,
    projectOutcome: project.projectOutcome,
    description: project.description,
    privacy: project.privacy,
    docs: project.docs,
    image: project.image ? project.image.split("?")[0] : "",
    hqLocation: project.hqLocation,
  };

  return Object.keys(form)
    .filter((key) => typeof project[key] !== "undefined")
    .reduce((acc, current) => ({ ...acc, [current]: form[current] }), {});
};

export const generateSettingsFormFields = (settings) => {
  const form = {
    email: settings.email,
    jobTitle: settings.jobTitle,
    phoneNumber: settings.phoneNumber,
    companyName: settings.companyName,
    companyEmail: settings.companyEmail,
    companyHqLocation: settings.companyHqLocation,
    companyWebsiteUrl: settings.companyWebsiteUrl,
    termsAccepted: settings.termsAccepted,
  };

  return Object.keys(form)
    .filter((key) => typeof settings[key] !== "undefined")
    .reduce((acc, current) => ({ ...acc, [current]: settings[current] }), {});
};

export const generateProfileFromFields = (profile) => {
  const form = {
    id_token: profile.id_token,
    jobTitle: profile.jobTitle,
    companyName: profile.companyName,
    companyEmail: profile.companyEmail,
    newsletter: profile.newsletter,
    companyWebsiteUrl: profile.companyWebsiteUrl,
    companyHqLocation: profile.companyHqLocation,
    companyLogo: profile.companyLogo,
    paygPlan: profile.paygPlan,
  };

  return Object.keys(form)
    .filter((key) => typeof profile[key] !== "undefined")
    .reduce((acc, current) => ({ ...acc, [current]: profile[current] }), {});
};

export const generateCommunicationsSettingsFromFields = (communications) => {
  const form = {
    email: communications.email,
    sms: communications.sms,
    push: communications.push,
    newsletter: communications.newsletter,
    match: communications.match,
  };

  return Object.keys(form)
    .filter((key) => typeof communications[key] !== "undefined")
    .reduce(
      (acc, current) => ({ ...acc, [current]: communications[current] }),
      {}
    );
};

export const generateDeleteAccountReasonsFromFields = (reasons) => {
  const form = {
    reason: reasons.reason,
    comments: reasons.comments,
  };

  return Object.keys(form)
    .filter((key) => typeof reasons[key] !== "undefined")
    .reduce((acc, current) => ({ ...acc, [current]: reasons[current] }), {});
};
