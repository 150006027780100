import React, { useEffect, useRef, useState } from "react";

import "./podcast-player.scss";
import "./volume-range.scss";

import ShareButton from "../share-button/share-button";
import skip from "../../assets/img/fr-skip-icon.svg";
import speaker from "../../assets/img/fr-speaker-icon.svg";
import pause from "../../assets/img/fr_podcast_player_pause_icon.svg";
import play from "../../assets/img/fr_podcast_player_play_icon.svg";
import defaultPoster from "../../assets/img/fr_podcast_default_poster.svg";

import Wavesurfer from "wavesurfer.js";
import moment from "moment";
import {
  PODCAST_SET_EPISODE_LOADED,
  PODCAST_SET_IS_PLAYING,
  PODCAST_SET_SELECTED_EPISODE,
} from "../../core/store/podcast";
import { useStoreon } from "storeon/react";
import { LikeButton } from "../like-button/like-button";

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const getFormWaveSurferOptions = (ref) => ({
  container: ref,
  backend: "MediaElement",
  waveStyle: "soundWave",
  waveColor: "#ff742070",
  progressColor: "#ff7420",
  cursorColor: "transparent",
  barWidth: 1,
  barGap: 0,
  barRadius: 1,
  responsive: true,
  barHeight: 1,
  height: 54,
  normalize: true,
  partialRender: true,
});

export default function PodcastPlayer() {
  const rangeSliderRef = useRef(null);
  const waveformRef = useRef(null);
  const waveSurferRef = useRef(null);
  const podcastPlayerRef = useRef(null);

  const [volume, setVolume] = useState(0.5);
  const [trackDuration, setTrackDuration] = useState(0);
  const [trackCurrentTime, setTrackCurrentTime] = useState(0);
  const [isSkipButtonEnabled, setIsSkipButtonEnabled] = useState({
    next: true,
    back: true,
  });
  const {
    podcast: { selectedEpisode, episodes, isPlaying, isEpisodeLoaded },
    dispatch,
  } = useStoreon("podcast");

  const selectedTrackURL = selectedEpisode?.audio?.url;

  useEffect(() => {
    if (selectedEpisode) {
      setPlayerVisibility();
    }
  }, [selectedEpisode?.id]);

  useEffect(() => {
    let context,
      processor = null;
    if (isSafari) {
      const AudioContext = window.AudioContext || window.webkitAudioContext;
      context = new AudioContext();
      processor = context.createScriptProcessor(1024, 1, 1);
    }

    const options = getFormWaveSurferOptions(waveformRef.current);
    waveSurferRef.current = Wavesurfer.create({
      ...options,
      audioContext: context,
      audioScriptProcessor: processor,
    });

    if (selectedTrackURL) {
      waveSurferRef.current.load(
        selectedTrackURL,
        selectedEpisode?.audio?.peaks?.values
      );
    }

    waveSurferRef.current.on("error", () => handleSetLoadingState(false));

    waveSurferRef.current.on("ready", () => {
      setTrackDuration(0);
      waveSurferRef.current.setVolume(volume);
      setVolume(volume);
      let currentTrackLength = waveSurferRef.current.getDuration();
      setTrackDuration(currentTrackLength);
      waveSurferRef.current.play();
      handleSetLoadingState();
    });

    waveSurferRef.current.on("audioprocess", () => {
      let cursor = document.querySelector(".podcast-player__wave-cursor");
      let currentTrackPlayedTime = waveSurferRef.current.getCurrentTime();
      let wave = document.querySelector(
        "div.podcast-player__waves > wave > wave"
      );
      cursor.style.width = wave.style.width;
      setTrackCurrentTime(currentTrackPlayedTime);
    });

    return () => waveSurferRef.current.destroy();
  }, [selectedTrackURL]);

  useEffect(() => {
    if (waveSurferRef.current) {
      waveSurferRef.current[isPlaying ? "play" : "pause"]();
    }
  }, [isPlaying]);

  const handleSetLoadingState = (value = true) => {
    dispatch(PODCAST_SET_EPISODE_LOADED, value);
  };

  const handleOnClose = () => {
    if (isPlaying) {
      handleTrackPlaying(false);
      try {
        if (waveSurferRef.current && waveSurferRef.current.isReady) {
          waveSurferRef.current.cancelAjax();
          waveSurferRef.current.stop();
          handleSetLoadingState(false);
        }
      } catch (err) {
        console.log(err);
      }
    }
    setPlayerVisibility("none");
    dispatch(PODCAST_SET_SELECTED_EPISODE, null);
  };

  const setPlayerVisibility = (value = "flex") =>
    podcastPlayerRef.current.style.setProperty("--is-player-visible", value);

  const handleTrackPlaying = (isPlaying) => {
    dispatch(PODCAST_SET_IS_PLAYING, isPlaying);
  };

  const handleTrackSelected = (track) => {
    dispatch(PODCAST_SET_SELECTED_EPISODE, track);
  };

  const handleVolumeChange = (e) => {
    const { target } = e;
    const newValue = +target.value;
    if (newValue) {
      setVolume(newValue);
      waveSurferRef.current.setVolume(newValue || 1);
    }
  };

  const formatTime = (seconds) => {
    return moment("2000-01-01 00:00:00")
      .add(moment.duration(seconds, "seconds"))
      .format("mm:ss");
  };

  const handleSkipButton = async (type = "next") => {
    let isNext = type === "next";
    let isLimit = await onSkipTrack(isNext ? 1 : -1);
    setIsSkipButtonEnabled(
      isNext
        ? {
            next: !isLimit,
            back: true,
          }
        : {
            back: !isLimit,
            next: true,
          }
    );
  };

  const checkSkipButtonIsEnabled = (type) => {
    return isSkipButtonEnabled[type] ? "" : "grayed-and-not-allowed";
  };

  const onSkipTrack = (next = 1) => {
    return new Promise((resolve) => {
      let currentTrackIndex = episodes.findIndex(
        (t) => t.id === selectedEpisode.id
      );
      let totalTracks = episodes.length;
      let newIndex = currentTrackIndex + next;
      let isLimitTrack = false;
      if (
        (currentTrackIndex === 0 && next === -1) ||
        (newIndex === totalTracks && next === 1)
      ) {
        isLimitTrack = true;
      }
      !isLimitTrack && handleTrackSelected(episodes[newIndex]);
      resolve(isLimitTrack);
    });
  };

  return (
    <footer
      className="podcast-player__wrapper"
      ref={podcastPlayerRef}
      style={{ opacity: isEpisodeLoaded ? 1 : 0 }}
    >
      {/* TRACK WAVEFORM CURSOR */}
      <div className="podcast-player__wave-cursor" />

      {/* TRACK WAVEFORM */}
      <div className="podcast-player__waves-wrapper">
        <div className="podcast-player__waves" ref={waveformRef} />
      </div>

      <section>
        {/* TRACK PICTURE */}
        <img
          className="podcast-player__picture"
          src={selectedEpisode?.image?.url || defaultPoster}
        />

        {/* TRACK INFO */}
        <div className="podcast-player__info-wrapper">
          <h2>{selectedEpisode?.title}</h2>
          <p>
            Episode {selectedEpisode?.episode_numeration.replace(/\D/g, "")}
            &nbsp;
            <span>
              {`· ${formatTime(trackCurrentTime)} / ${formatTime(
                trackDuration
              )}`}
            </span>
          </p>
        </div>
      </section>

      <section>
        {/* TRACK CURRENT TIME */}
        <time className="podcast-player__time-before">
          {formatTime(trackCurrentTime)}
        </time>

        <div className="podcast-player__controls-wrapper">
          {/* TRACK SKIP BACK BUTTON */}
          <img
            className={`podcast-player__rewind-control ${checkSkipButtonIsEnabled(
              "back"
            )}`}
            src={skip}
            alt="Podcast skip back button"
            onClick={() => handleSkipButton("back")}
          />

          {/* TRACK PLAY BUTTON */}
          <img
            className="podcast-player__play-control"
            src={isPlaying ? pause : play}
            onClick={() => handleTrackPlaying(!isPlaying)}
          />

          {/* TRACK SKIP FORWARD BUTTON */}
          <img
            className={`podcast-player__forward-control ${checkSkipButtonIsEnabled(
              "next"
            )}`}
            src={skip}
            alt="Podcast skip next button"
            onClick={() => handleSkipButton("next")}
          />
        </div>

        {/* TRACK DURATION */}
        <time className="podcast-player__time-after">
          {formatTime(trackDuration) || "---"}
        </time>
      </section>

      <section>
        {/* TRACK SOCIAL SHARE */}
        <div className="podcast-player__social-wrapper">
          <LikeButton itemID={selectedEpisode?.id} elementType="episode" />
          <ShareButton
            type="SHARE"
            isTooltip
            shareLink={selectedEpisode?.audio?.url}
          />
          <ShareButton
            type="EXPORT"
            shareLink={selectedEpisode?.audio?.url}
            customList={selectedEpisode?.listen_on}
            isTooltip
          />
        </div>

        {/* TRACK VOLUMEN */}
        <div className="podcast-player__volume-wrapper">
          <img className="podcast-player__volume-icon" src={speaker} />

          <div className="range-slider flat" ref={rangeSliderRef}>
            <input
              type="range"
              min="0.01"
              max="1"
              step=".025"
              defaultValue="0.50"
              onChange={handleVolumeChange}
              onInput={(e) => {
                if (rangeSliderRef.current) {
                  rangeSliderRef.current.style.setProperty(
                    "--value",
                    e.currentTarget.value
                  );
                  rangeSliderRef.current.style.setProperty(
                    "--text-value",
                    JSON.stringify(e.currentTarget.value)
                  );
                }
              }}
            />
            <output></output>
            <div className="range-slider__progress" />
          </div>
        </div>
      </section>
      {/* CLOSE X */}
      <div className="podcast-player__close" onClick={handleOnClose} />
    </footer>
  );
}
