import { createStoreon } from "storeon";
import { persistState } from "@storeon/localstorage";
import { onboarding } from "./onboarding";
import { ui } from "./ui";
import { profile } from "./profile";
import { myFindr } from "./my-findr";
import { dashboard } from "./dashboard";
import { plans } from "./plans";
import { enterprise } from "./enterprise";
import { storeonDevtools } from "storeon/devtools";
import { blog } from "./blog";
import { podcast } from "./podcast";
import { series } from "./series";
import { favorite } from "./favorite";
import { myFindrMatches } from "./my-findr-matches";
import { privacy } from "./privacy";
import { terms } from "./terms";
import { faqs } from "./faqs";
import { home } from "./home";
import { category } from "./category";
import { image } from "./image";
import { firebase } from "./firebase";
// version selected modules
import {
  STORE_AUTH_V2,
  STORE_DISCOVER_V2,
  STORE_PROJECT_V2,
  STORE_SETTINGS_V2,
  CASE_STUDIES_V2,
} from "../../feature-flags";
import { discover as discoverV1 } from "./discover";
import { discover as discoverV2 } from "../../v2/core/store/discover";

import { auth as authV1 } from "./auth";
import { auth as authV2 } from "../../v2/core/store/auth";

import { project as projectV1 } from "./project";
import { project as projectV2 } from "../../v2/core/store/project";

import { settings as settingsV1 } from "./settings";
import { settingsStore as settingsV2 } from "../../v2/core/store/settings";

import { caseStudies as caseStudiesV1 } from "./case-studies";
import { caseStudies as caseStudiesV2 } from "../../v2/core/store/case-studies";

const auth = STORE_AUTH_V2 ? authV2 : authV1;
const project = STORE_PROJECT_V2 ? projectV2 : projectV1;
const settings = STORE_SETTINGS_V2 ? settingsV2 : settingsV1;
const discover = STORE_DISCOVER_V2 ? discoverV2 : discoverV1;
const caseStudies = CASE_STUDIES_V2 ? caseStudiesV2 : caseStudiesV1;

export const store = createStoreon([
  auth,
  onboarding,
  ui,
  discover,
  profile,
  project,
  myFindr,
  settings,
  dashboard,
  plans,
  enterprise,
  caseStudies,
  blog,
  podcast,
  series,
  favorite,
  myFindrMatches,
  privacy,
  terms,
  faqs,
  home,
  category,
  image,
  firebase,
  persistState(["auth", "image"]),
  process.env.NODE_ENV !== "production" && storeonDevtools,
]);
// TODO: remove Redux devtools
