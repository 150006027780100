import { TOAST, TOAST_ERROR, TOAST_INFO } from "./ui";
import { DeleteRequest, GetRequest, PostRequest } from "../api/api-request";
import {
  ENTERPRISE_MEMBER_ENDPOINT,
  PAYMENT_LICENSE_INFO_ENDPOINT,
  PROFILE_ENDPOINT,
} from "../../config/endpoints";
import { SETTINGS_LOAD_UPCOMING_INFO } from "./settings";

export const ENTERPRISE_GET_MEMBERS = "ENTERPRISE_GET_MEMBERS";
export const ENTERPRISE_GET_MEMBERS_LOADING = "ENTERPRISE_GET_MEMBERS_LOADING";
export const ENTERPRISE_SET_MEMBERS = "ENTERPRISE_SET_MEMBERS";
export const ENTERPRISE_CREATE_LICENSES = "ENTERPRISE_CREATE_LICENSES";
export const ENTERPRISE_SET_LOADING = "ENTERPRISE_SET_LOADING";
export const ENTERPRISE_RE_INVITE_MEMBER = "ENTERPRISE_RE_INVITE_MEMBER";
export const ENTERPRISE_DELETE_MEMBER = "ENTERPRISE_DELETE_MEMBER";
export const ENTERPRISE_LOAD_LICENSES_INFO = "ENTERPRISE_LOAD_LICENSES_INFO";
export const ENTERPRISE_LOAD_LICENSES_INFO_LOADING =
  "ENTERPRISE_LOAD_LICENSES_INFO_LOADING";
export const ENTERPRISE_SET_LICENSE_INFO = "ENTERPRISE_SET_LICENSE_INFO";
export const SET_MANAGE_TEAM_MODAL = "SET_MANAGE_TEAM_MODAL";

export function enterprise(store) {
  store.on("@init", () => ({
    enterprise: {
      members: [],
      invitations: [],
      isLoading: false,
      licenseInfo: {},
    },
  }));

  store.on(ENTERPRISE_GET_MEMBERS, async ({ auth: { accessToken } }) => {
    store.dispatch(ENTERPRISE_GET_MEMBERS_LOADING, true);
    try {
      const response = await GetRequest(
        `${ENTERPRISE_MEMBER_ENDPOINT}/company`,
        accessToken
      );

      store.dispatch(ENTERPRISE_SET_MEMBERS, {
        members: response.data.members.data,
        invitations: response.data.invitations.items,
      });
    } catch (e) {
      store.dispatch(TOAST, {
        type: TOAST_ERROR,
        message: e,
        error: e,
      });
    } finally {
      store.dispatch(ENTERPRISE_GET_MEMBERS_LOADING, false);
    }
  });

  store.on(
    ENTERPRISE_GET_MEMBERS_LOADING,
    ({ enterprise }, isGetMembersLoading) => {
      return {
        enterprise: {
          ...enterprise,
          isGetMembersLoading,
        },
      };
    }
  );

  store.on(
    ENTERPRISE_SET_MEMBERS,
    ({ enterprise }, { members, invitations }) => {
      return {
        enterprise: {
          ...enterprise,
          members,
          invitations,
        },
      };
    }
  );

  store.on(
    ENTERPRISE_CREATE_LICENSES,
    async ({ auth: { profile, accessToken } }, { users }) => {
      store.dispatch(ENTERPRISE_SET_LOADING, true);

      try {
        for (const user of users) {
          if (user.name && user.email) {
            await PostRequest(
              ENTERPRISE_MEMBER_ENDPOINT,
              {
                memberName: user.name,
                memberEmail: user.email,
                insert: "false",
              },
              accessToken
            );
          }
        }

        for (const user of users) {
          if (user.name && user.email) {
            await PostRequest(
              ENTERPRISE_MEMBER_ENDPOINT,
              {
                memberName: user.name,
                memberEmail: user.email,
                insert: "true",
              },
              accessToken
            );
          }
        }

        store.dispatch(TOAST, {
          type: TOAST_INFO,
          message: "Invitation(s) sent",
        });

        const usersKey = `${profile.id}-users`;
        const usersExtraKey = `${profile.id}-users-extra`;

        localStorage.getItem(usersKey) && localStorage.removeItem(usersKey);
        localStorage.getItem(usersExtraKey) &&
          localStorage.removeItem(usersExtraKey);
      } catch (e) {
        store.dispatch(TOAST, {
          type: TOAST_ERROR,
          message: e,
          error: e,
        });
      } finally {
        setTimeout(() => {
          store.dispatch(ENTERPRISE_SET_LOADING, false);
          store.dispatch(ENTERPRISE_GET_MEMBERS);
          store.dispatch(ENTERPRISE_LOAD_LICENSES_INFO);
        }, 2000);
      }
    }
  );

  store.on(ENTERPRISE_SET_LOADING, ({ enterprise }, isLoading) => {
    return {
      enterprise: {
        ...enterprise,
        isLoading,
      },
    };
  });

  store.on(
    ENTERPRISE_RE_INVITE_MEMBER,
    async ({ auth: { accessToken } }, { memberId, memberEmail }) => {
      store.dispatch(TOAST, {
        type: TOAST_INFO,
        message: "Re-sending invite",
      });

      try {
        await PostRequest(
          `${ENTERPRISE_MEMBER_ENDPOINT}/reinvite`,
          { memberId, memberEmail },
          accessToken
        );
      } catch (e) {
        store.dispatch(TOAST, {
          type: TOAST_ERROR,
          message: e,
          error: e,
        });
        store.dispatch(ENTERPRISE_GET_MEMBERS);
      }
    }
  );

  store.on(
    ENTERPRISE_DELETE_MEMBER,
    async (
      { auth: { accessToken, profile }, enterprise: { members, invitations } },
      memberId
    ) => {
      store.dispatch(TOAST, {
        type: TOAST_INFO,
        message: "Removing member",
      });

      try {
        await DeleteRequest(
          `${PROFILE_ENDPOINT}/member/${memberId.replace("|", "%7C")}`,
          accessToken
        );
      } catch (e) {
        console.log(e);
      } finally {
        setTimeout(() => {
          store.dispatch(ENTERPRISE_GET_MEMBERS);
        }, 2000);

        try {
          const sortedMembers = [...members, ...invitations]
            .sort((x) => x.createdAt)
            .reverse();

          const pos = sortedMembers.findIndex((x) => x.id === memberId);

          if (pos > 4) {
            if (profile?.stripe?.planName === "ENTERPRISE_MONTHLY") {
              store.dispatch(SET_MANAGE_TEAM_MODAL, "Monthly");
            } else if (profile?.stripe?.planName === "ENTERPRISE_YEARLY") {
              store.dispatch(SETTINGS_LOAD_UPCOMING_INFO);
              store.dispatch(SET_MANAGE_TEAM_MODAL, "Yearly");
            }
          }
        } catch (e) {
          console.log(e);
        }
      }
    }
  );

  store.on(SET_MANAGE_TEAM_MODAL, ({ enterprise }, currentModal) => {
    return {
      enterprise: {
        ...enterprise,
        currentModal,
      },
    };
  });

  store.on(ENTERPRISE_LOAD_LICENSES_INFO, async ({ auth: { accessToken } }) => {
    store.dispatch(ENTERPRISE_LOAD_LICENSES_INFO_LOADING, true);
    try {
      const response = await GetRequest(
        `${PAYMENT_LICENSE_INFO_ENDPOINT}`,
        accessToken
      );

      store.dispatch(ENTERPRISE_SET_LICENSE_INFO, response.data);
    } catch (e) {
      store.dispatch(TOAST, {
        type: TOAST_ERROR,
        message: e,
        error: e,
      });
    } finally {
      store.dispatch(ENTERPRISE_LOAD_LICENSES_INFO_LOADING, false);
    }
  });

  store.on(
    ENTERPRISE_LOAD_LICENSES_INFO_LOADING,
    ({ enterprise }, isLoadLicensesLoading) => {
      return {
        enterprise: {
          ...enterprise,
          isLoadLicensesLoading,
        },
      };
    }
  );

  store.on(ENTERPRISE_SET_LICENSE_INFO, ({ enterprise }, licenseInfo) => {
    return {
      enterprise: {
        ...enterprise,
        licenseInfo,
      },
    };
  });
}
