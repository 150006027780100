import {
  PLANS_ROUTE,
  ENTERPRISE_BOARDING_ROUTE,
  ENTERPRISE_ADD_LICENSE_ROUTE,
  ENTERPRISE_INVITE_USERS_ROUTE,
  SETTINGS_ROUTE_DASHBOARD,
  SETTINGS_ROUTE_MANAGE_TEAM,
  SETTINGS_ROUTE_PAYMENT,
  SETTINGS_ROUTE_ACCOUNT,
} from "../../config/routes";

const isMember = (profile) => profile?.role === "MEMBER";
const isEnterprise = (profile) =>
  profile?.stripe?.planName?.indexOf("ENTERPRISE") > -1 && !isMember(profile);

export const routePerms = {
  [PLANS_ROUTE]: (profile) => !isMember(profile),
  [SETTINGS_ROUTE_PAYMENT]: (profile) =>
    !isMember(profile) && profile?.hasInvoices,
  [SETTINGS_ROUTE_ACCOUNT]: (profile) => !isMember(profile),
  [ENTERPRISE_BOARDING_ROUTE]: isEnterprise,
  [ENTERPRISE_ADD_LICENSE_ROUTE]: isEnterprise,
  [ENTERPRISE_INVITE_USERS_ROUTE]: isEnterprise,
  [SETTINGS_ROUTE_DASHBOARD]: isEnterprise,
  [SETTINGS_ROUTE_MANAGE_TEAM]: isEnterprise,
};
