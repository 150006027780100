import axios from "axios";
import { TOAST, TOAST_ERROR } from "./ui";
import { PODCAST_ENDPOINT } from "../../config/endpoints";

export const PODCAST_CLEAR_EPISODES = "PODCAST_CLEAR_EPISODES";
export const PODCAST_GET_EPISODES = "PODCAST_GET_EPISODES";
export const PODCAST_GET_INITIAL_EPISODES = "PODCAST_GET_INITIAL_EPISODES";
export const PODCAST_SET_IS_LOADING = "PODCAST_SET_IS_LOADING";
export const PODCAST_SET_IS_PLAYING = "PODCAST_SET_IS_PLAYING";
export const PODCAST_SET_IS_COMPLETED = "PODCAST_SET_IS_COMPLETED";
export const PODCAST_SET_EPISODES = "PODCAST_SET_EPISODES";
export const PODCAST_SET_SELECTED_EPISODE = "PODCAST_SET_SELECTED_EPISODE";
export const PODCAST_SET_PLAYER_VISIBILITY = "PODCAST_SET_PLAYER_VISIBILITY";
export const PODCAST_SET_SERIE = "PODCAST_SET_SERIE";
export const PODCAST_SET_CONFIG = "PODCAST_SET_CONFIG";
export const PODCAST_SET_EPISODE_LOADED = "PODCAST_SET_EPISODE_LOADED";

const INITIAL_STATE = {
  isVisible: false,
  isPlaying: false,
  isLoading: true,
  isCompleted: false,
  isEpisodeLoaded: false,
  serie: null,
  page: 1,
  episodes: [],
  selectedEpisode: null,
};

export function podcast(store) {
  store.on("@init", () => {
    return {
      podcast: INITIAL_STATE,
    };
  });

  store.on(PODCAST_SET_IS_LOADING, ({ podcast }, isLoading) => {
    return {
      podcast: {
        ...podcast,
        isLoading,
      },
    };
  });

  store.on(PODCAST_SET_IS_PLAYING, ({ podcast }, isPlaying) => ({
    podcast: {
      ...podcast,
      isPlaying,
    },
  }));

  store.on(PODCAST_SET_EPISODES, ({ podcast }, { episodes, page }) => {
    return {
      podcast: {
        ...podcast,
        page,
        episodes,
      },
    };
  });

  store.on(PODCAST_SET_SERIE, ({ podcast }, serie) => ({
    podcast: {
      ...podcast,
      serie,
    },
  }));

  store.on(PODCAST_SET_CONFIG, ({ podcast }, config) => ({
    podcast: {
      ...podcast,
      config,
    },
  }));

  store.on(PODCAST_SET_SELECTED_EPISODE, ({ podcast }, selectedEpisode) => ({
    podcast: {
      ...podcast,
      selectedEpisode,
    },
  }));

  store.on(PODCAST_SET_IS_COMPLETED, ({ podcast }, isCompleted) => ({
    podcast: {
      ...podcast,
      isCompleted,
    },
  }));

  store.on(PODCAST_SET_EPISODE_LOADED, ({ podcast }, isEpisodeLoaded) => ({
    podcast: {
      ...podcast,
      isEpisodeLoaded,
    },
  }));

  store.on(
    PODCAST_GET_INITIAL_EPISODES,
    async (_, { selectedSeries, config }) => {
      const getSourceMap = (response) => {
        const { episodes, ...detail } = response;
        return {
          config: { ...selectedSeries, ...config },
          serie: detail,
          episodes,
        };
      };

      try {
        store.dispatch(PODCAST_SET_IS_LOADING, true);
        let newData = [];

        const { data, headers } = await axios(
          `${PODCAST_ENDPOINT}/${
            selectedSeries.id
          }?_episodeLimit=${12}&_episodeStart=${1}`
        );
        const count = headers["x-total-count"];

        if (Array.isArray(data)) {
          newData = data;
        } else {
          const { config, serie, episodes: episodelist } = getSourceMap(data);
          newData = episodelist;
          store.dispatch(PODCAST_SET_CONFIG, config);
          store.dispatch(PODCAST_SET_SERIE, serie);
        }

        store.dispatch(PODCAST_SET_EPISODES, { episodes: newData, page: 1 });
        store.dispatch(PODCAST_SET_IS_COMPLETED, newData.length === +count);
      } catch (err) {
        store.dispatch(TOAST, {
          type: TOAST_ERROR,
          message: "Failed to get podcast episodes",
        });
      } finally {
        store.dispatch(PODCAST_SET_IS_LOADING, false);
      }
    }
  );

  store.on(
    PODCAST_GET_EPISODES,
    async ({ podcast }, { selectedSeries, config }) => {
      const getSourceMap = (response) => {
        const { episodes, ...detail } = response;
        return {
          config: { ...selectedSeries, ...config },
          serie: detail,
          episodes,
        };
      };

      try {
        store.dispatch(PODCAST_SET_IS_LOADING, true);
        let newData = [];
        let { page, episodes } = podcast;
        let nextPage = page + 1;

        const { data, headers } = await axios(
          `${PODCAST_ENDPOINT}/${
            selectedSeries.id
          }?_episodeLimit=${12}&_episodeStart=${nextPage}`
        );
        const count = headers["x-total-count"];

        if (Array.isArray(data)) {
          newData = episodes.concat(data);
        } else {
          const { config, serie, episodes: episodelist } = getSourceMap(data);
          newData = episodes.concat(episodelist);
          store.dispatch(PODCAST_SET_CONFIG, config);
          store.dispatch(PODCAST_SET_SERIE, serie);
        }

        store.dispatch(PODCAST_SET_EPISODES, {
          episodes: newData,
          page: nextPage,
        });
        store.dispatch(PODCAST_SET_IS_COMPLETED, newData.length === +count);
      } catch (err) {
        store.dispatch(TOAST, {
          type: TOAST_ERROR,
          message: "Failed to get podcast episodes",
        });
      } finally {
        store.dispatch(PODCAST_SET_IS_LOADING, false);
      }
    }
  );
}
