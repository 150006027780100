import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useStoreon } from "storeon/react";
import { NOT_FOUND } from "../../config/routes";
import { DISABLE_LAYOUT, ENABLE_LAYOUT } from "../../core/store/settings";

export default function Payment3dsReturn() {
  const { dispatch } = useStoreon();
  const history = useHistory();
  useEffect(() => {
    dispatch(DISABLE_LAYOUT);
    const query = new URLSearchParams(location.search);
    const clientSecret = query.get("payment_intent_client_secret");
    if (clientSecret) {
      window.top.postMessage({
        tag: "3DS-authentication-complete",
        params: { clientSecret },
      });
    } else {
      history.push(NOT_FOUND);
    }
    return () => dispatch(ENABLE_LAYOUT);
  }, []);
  return "";
}
