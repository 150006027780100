import axios from "axios";
import { BLOG_ENDPOINT, POST_ENDPOINT } from "../../config/endpoints";
import { TOAST, TOAST_ERROR } from "./ui";
import { jsonToQueryParams } from "../utils/functions";
import { NOT_FOUND } from "../../config/routes";
export const BLOG_GET_BY_ID = "BLOG_GET_BY_ID";
export const BLOG_GET_POSTS = "GET_POSTS";
export const BLOG_SET_POSTS = "SET_POSTS";
export const BLOG_SET_IS_LOADING = "SET_IS_LOADING";
export const BLOG_SET_IS_COMPLETED = "SET_IS_COMPLETED";
export const BLOG_SET_CONFIG = "SET_CONFIG";
export const BLOG_SET_TOTAL_COUNT = "SET_TOTAL_COUNT";
export const BLOG_ADD_PAGE = "BLOG_ADD_PAGE";
export const BLOG_RESET_PAGE = "BLOG_RESET_PAGE";
export const BLOG_SET_SEARCH_KEYWORD = "BLOG_SET_SEARCH_KEYWORD";

const INITIAL_STATE = {
  config: null,
  posts: [],
  totalCount: 0,
  page: 1,
  isLoading: true,
  isCompleted: true,
  searchKeyword: "",
};

export const blog = (store) => {
  store.on("@init", () => ({
    blog: INITIAL_STATE,
  }));

  store.on(BLOG_SET_IS_LOADING, ({ blog }, isLoading) => {
    return {
      blog: {
        ...blog,
        isLoading,
      },
    };
  });

  store.on(BLOG_GET_POSTS, async ({ blog }, params) => {
    store.dispatch(BLOG_SET_IS_LOADING, true);
    try {
      let newData = [];
      let { page, posts } = blog;
      const { data, headers } = await axios.get(
        `${BLOG_ENDPOINT}?_articleLimit=${12}&_articleStart=${page}&${jsonToQueryParams(
          params
        )}`
      );

      const count = headers["x-total-count"];

      if (Array.isArray(data)) {
        newData = page > 1 ? posts.concat(data) : data;
      } else {
        const { config, articles } = getApiResponseMap(data);
        newData = [...articles];
        store.dispatch(BLOG_SET_CONFIG, config);
      }
      store.dispatch(BLOG_SET_TOTAL_COUNT, +count);
      store.dispatch(BLOG_SET_POSTS, newData);
      store.dispatch(BLOG_SET_IS_COMPLETED, newData.length === +count);
    } catch (err) {
      console.log(err.message);
      store.dispatch(BLOG_SET_IS_COMPLETED, true);
      store.dispatch(TOAST, {
        type: TOAST_ERROR,
        message: "Failed to get posts",
      });
    } finally {
      store.dispatch(BLOG_SET_IS_LOADING, false);
    }
  });

  store.on(BLOG_SET_CONFIG, ({ blog }, config) => {
    return {
      blog: {
        ...blog,
        config,
      },
    };
  });

  store.on(BLOG_SET_POSTS, ({ blog }, posts) => {
    return {
      blog: {
        ...blog,
        posts,
      },
    };
  });

  store.on(BLOG_SET_IS_COMPLETED, ({ blog }, isCompleted) => {
    return {
      blog: {
        ...blog,
        isCompleted,
      },
    };
  });

  store.on(BLOG_SET_TOTAL_COUNT, ({ blog }, totalCount) => ({
    blog: {
      ...blog,
      totalCount,
    },
  }));

  store.on(BLOG_ADD_PAGE, ({ blog }) => ({
    blog: {
      ...blog,
      page: ++blog.page,
    },
  }));

  store.on(BLOG_RESET_PAGE, ({ blog }) => ({
    blog: {
      ...blog,
      page: 1,
    },
  }));

  store.on(BLOG_SET_SEARCH_KEYWORD, ({ blog }, keyword) => {
    return {
      blog: {
        ...blog,
        searchKeyword: keyword,
      },
    };
  });

  store.on(BLOG_GET_BY_ID, async (_, { postId, history }) => {
    try {
      const { data } = await axios.get(`${POST_ENDPOINT}${postId}`);

      if (data) {
        let newData = [];
        const { data: posts, headers } = await axios.get(
          `${BLOG_ENDPOINT}?_articleStart=1&_articleSection=${data.category.label}`
        );

        const count = headers["x-total-count"];

        if (Array.isArray(posts)) {
          newData = [...posts];
        } else {
          const { config, articles } = getApiResponseMap(posts);
          newData = [...articles];
          store.dispatch(BLOG_SET_CONFIG, config);
        }

        store.dispatch(BLOG_SET_TOTAL_COUNT, +count);
        store.dispatch(BLOG_SET_POSTS, newData);
        store.dispatch(BLOG_SET_IS_COMPLETED, newData.length === +count);
      } else {
        history.push(NOT_FOUND);
      }
    } catch (e) {
      console.log(e);
      history.push(NOT_FOUND);
    }
  });
};

const getApiResponseMap = (response) => {
  const { article_section: articleConfig, articles, ...mainConfig } = response;

  return {
    config: { ...mainConfig, ...articleConfig },
    articles,
  };
};
