import axios from "axios";
import { CATEGORY_ENDPOINT } from "../../config/endpoints";
import { TOAST, TOAST_ERROR } from "./ui";

export const CATEGORIES_GET = "CATEGORIES_GET";
export const CATEGORIES_SET = "CATEGORIES_SET";

const INITIAL_STATE = {
  categories: [],
};

export const category = (store) => {
  store.on("@init", () => ({
    category: INITIAL_STATE,
  }));

  store.on(CATEGORIES_GET, async () => {
    try {
      const { data } = await axios.get(CATEGORY_ENDPOINT);
      store.dispatch(CATEGORIES_SET, data);
    } catch (err) {
      store.dispatch(TOAST, {
        type: TOAST_ERROR,
        message: "Failed to get categories",
      });
    }
  });

  store.on(CATEGORIES_SET, (_, categories) => {
    return {
      category: {
        categories,
      },
    };
  });
};
