import { useEffect, useState } from "react";
import { useStoreon } from "storeon/react";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, isSupported } from "firebase/messaging";
import {
  ADD_NOTIFICATIONS,
  SAVE_NOTIFICATIONS_TOKEN,
  GET_NOTIFICATIONS,
} from "../store/my-findr";
import { FIREBASE_SET_INITIALIZED, USER_SET_ACTION } from "../store/firebase";
import { TOAST, TOAST_ERROR, TOAST_INFO } from "../store/ui";
import textData from "../../content/my-findr.yml";

export let initializedFirebaseApp = initializeApp({
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: `${process.env.FIREBASE_PROJECT_ID}.firebaseapp.com`,
  databaseURL: `https://${process.env.FIREBASE_PROJECT_ID}.firebaseio.com`,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: `${process.env.FIREBASE_PROJECT_ID}.appspot.com`,
  messagingSenderId: process.env.FIREBASE_MESSAGE_ID,
  appId: process.env.FIREBASE_APP_ID,
});
export let messaging = null;

const PushNotification = () => {
  const [isBrowserSupport, setIsBrowserSupport] = useState(false);
  const {
    dispatch,
    auth: { isAuthenticated },
    firebase: { hasUserAction },
  } = useStoreon("myFindr", "auth", "firebase");

  useEffect(() => {
    isSupported()
      .then((flag) => setIsBrowserSupport(flag))
      .catch(() => setIsBrowserSupport(false));
  }, []);

  useEffect(() => {
    if (isBrowserSupport) {
      messaging = getMessaging(initializedFirebaseApp);

      if (Boolean(messaging) && Boolean(initializedFirebaseApp)) {
        dispatch(FIREBASE_SET_INITIALIZED, true);
        document.addEventListener("click", () =>
          dispatch(USER_SET_ACTION, true)
        );
      }
    }
  }, [isBrowserSupport]);

  useEffect(() => {
    if (hasUserAction) {
      // request notifications permission
      Notification.requestPermission()
        .then((permission) => {
          if (permission === "granted") {
            getToken(messaging)
              .then((currentToken) => {
                if (currentToken) {
                  dispatch(SAVE_NOTIFICATIONS_TOKEN, currentToken);
                } else {
                  // Show permission request.
                  console.log(
                    "No Instance ID token available. Request permission to generate one."
                  );
                  // Show permission UI.
                }
              })
              .catch((err) => {
                console.log("An error occurred while retrieving token. ", err);
              });
            // In many cases once an app has been granted notification permission,
            // it should update its UI reflecting this.
          } else {
            const alreadyShowedInfo =
              window.localStorage.getItem("FindrNotificationDenied") === "1";
            if (!alreadyShowedInfo) {
              dispatch(TOAST, {
                type: TOAST_INFO,
                message: textData.notifications.permissionBlocked,
              });
              return window.localStorage.setItem(
                "FindrNotificationDenied",
                "1"
              );
            }
          }
        })
        .catch(() => {
          return;
        });

      // listener when new messages arrives
      navigator.serviceWorker.addEventListener("message", async (message) => {
        try {
          message.data.notification &&
            (await Promise.all([
              dispatch(GET_NOTIFICATIONS),
              dispatch(ADD_NOTIFICATIONS, {
                data: [message.data.notification.data],
              }),
            ]));
        } catch (e) {
          dispatch(TOAST, {
            type: TOAST_ERROR,
            message: e,
            error: e,
          });
        }
      });
    }
  }, [isAuthenticated, hasUserAction]);

  return null;
};

export default PushNotification;
