import axios from "axios";

const getRequestConfig = (accessToken) => ({
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
});

const GetRequest = (url, accessToken = "") => {
  return axios.get(url, getRequestConfig(accessToken));
};

const GetRequestUnauthenticated = (url) => {
  return axios.get(url);
};

const PostRequest = (url, data, accessToken = "") => {
  return axios.post(url, data, getRequestConfig(accessToken));
};

const PostUploadRequest = (url, data, config) => {
  return axios.post(url, data, config);
};

const PatchRequest = (url, data, accessToken = "") => {
  return axios.patch(url, data, getRequestConfig(accessToken));
};

const DeleteRequest = (url, accessToken = "") => {
  return axios({
    method: "DELETE",
    url,
    headers: getRequestConfig(accessToken).headers,
  });
};

const PutRequest = (url, data, config) => {
  return axios.put(url, data, { ...config });
};

const GetFetchRequest = (url, accessToken) => {
  const request = new Request(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    mode: "cors",
    cache: "default",
  });
  return fetch(request);
};

export {
  GetRequest,
  GetRequestUnauthenticated,
  PostRequest,
  PostUploadRequest,
  PatchRequest,
  PutRequest,
  DeleteRequest,
  GetFetchRequest,
};
